<script>
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import {mapState} from 'pinia';
import {useEnvStore} from '@dutypay/store-modules'
import screenSize from './../../mixins/screenSize'

export default {
  name: "DpFormDeletionConfirmation",
  inheritAttrs: true,
  mixins: [screenSize],
  props: {
    action: {required: true},
    notificationSuccess: {type: Object},
    notificationAsyncError: {type: Object},
    disableNotification: [Boolean, Array],
    deleteButtonLabel: {type: String},
    keepButtonLabel: {type: String},
    deleteButtonParameter: {type: Object},
    keepButtonParameter: {type: Object},
    triggerAction: {type: Boolean}
  },
  data() {
    return {
      isFormActive: false,
      isLoading: false
    };
  },
  computed: {
    ...mapState(useEnvStore, ['debug']),
    notification() {
      return {
        success: (this.notificationSuccess !== undefined) ? this.notificationSuccess : {
          title: this.$t('notification.success.title'),
          message: this.$t('notification.success.message')
        },
        asyncError: (this.notificationAsyncError !== undefined) ? this.notificationAsyncError : {
          title: this.$t('notification.asyncError.title'),
          message: this.$t('notification.asyncError.message')
        }
      }
    },
    computedButtonLabel() {
      return {
        deleteButtonLabel: (isEmpty(this.deleteButtonLabel)) ? this.$t('delete.buttonLabel') : this.deleteButtonLabel,
        keepButtonLabel: (isEmpty(this.keepButtonLabel)) ? this.$t('keep.buttonLabel') : this.keepButtonLabel,
      };
    }
  },
  watch: {
    triggerAction: function (val) {
      if (val) this.submitForm();
    }
  },
  methods: {
    cancelForm() {
      this.deactivateForm();
      this.$emit('cancel-form');
    },
    submitForm() {
      if (this.isFormActive) {
        this.executeDeletion();
      } else {
        this.activateForm();
      }
    },
    activateForm() {
      this.isFormActive = true;
    },
    deactivateForm() {
      this.isFormActive = false;
    },
    async executeDeletion() {
      try {
        this.isLoading = true;
        let res = await this.action();
        this.deactivateForm();
        this.isLoading = false;
        if (res.status >= 400 && res.status <= 600) {
          this.notifyAsyncError(this.notification.asyncError);
          this.$emit('asyncError');
          return;
        }
        this.notifySuccess(this.notification.success);
        this.$emit('success');
      } catch (e) {
        this.notifyAsyncError(this.notification.asyncError);
        this.$emit('asyncError');
        if (this.debug)
          return e;
      }
    },
    containsKey(key) {
      return find(this.disableNotification, (iteratee) => {return (iteratee === key)}) !== undefined;
    },
    notifyAsyncError(message) {
      if (this.disableNotification === true || this.containsKey('asyncError'))
        return;
      this.$notify.error(message);
    },
    notifySuccess(message) {
      if (this.disableNotification === true || this.containsKey('success'))
        return;
      this.$notify.success(message);
    }
  }
}
</script>

<template>
  <el-form
      v-bind="$attrs"
      v-loading="isLoading"
      @submit.prevent
      @submit.native.prevent
      @keydown.enter.prevent
      @keydown.enter.native.prevent>

    <el-form-item>
      <dp-button purpose="secondaryAction" v-if="isFormActive" v-bind="keepButtonParameter" @click="cancelForm">
        {{computedButtonLabel.keepButtonLabel}}
      </dp-button>

      <dp-button purpose="destructiveAction" v-bind="deleteButtonParameter" @click="submitForm">
        {{computedButtonLabel.deleteButtonLabel}}
      </dp-button>
    </el-form-item>

  </el-form>
</template>

<style scoped lang="scss">
</style>

<i18n>
{
  "en-US": {
  }
}
</i18n>
