<script setup>
import { inject, defineProps, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const composables = inject('composables');
const { isRTL } = composables.useJurisdictionSwitch()

const props = defineProps({
  modelValue: {required: true},
  required: {type: Boolean, default: false},
  tooltip: {type: String, default: undefined},
  description: {type: String, default: undefined},
  label: {type: String, required: true},
  inputParameters: {type: Array},
})
const emit = defineEmits(['update', 'input', 'load'])
const input = composables.useTwoWayBinding(props, emit, 'modelValue')
</script>
<script>
// TODO: Needs to be translated into composition API.
import isEmpty from 'lodash/isEmpty';
import dropRight from 'lodash/dropRight';
import each from 'lodash/each'
import {mapState} from 'pinia';
import {useEnvStore} from '@dutypay/store-modules'

export default {
  name: "DpDatePickerRanges",
  data() {
    return {
      defaultRow: [null, null],
      translationReportAlias: {
        label: this.$t('input.label'),
        tooltip: this.$t('input.tooltip'),
        description: this.$t('input.description')
      },
      isEmpty: isEmpty
    };
  },
  computed: {
    ...mapState(useEnvStore, ['dateFormat']),
    format() {
      return [
        (this.inputParameters && this.inputParameters[0] && this.inputParameters[0].format) ? this.inputParameters[0].format : this.dateFormat[this.$i18n.locale],
        (this.inputParameters && this.inputParameters[1] && this.inputParameters[1].format) ? this.inputParameters[1].format : this.dateFormat[this.$i18n.locale]
      ];
    },
    style() {
      return [
        (this.inputParameters && this.inputParameters[0] && this.inputParameters[0].style) ? this.inputParameters[0].style : 'width: 100%',
        (this.inputParameters && this.inputParameters[1] && this.inputParameters[1].style) ? this.inputParameters[1].style : 'width: 100%'
      ];
    },
    placeholder() {
      return [
        (this.inputParameters && this.inputParameters[0] && this.inputParameters[0].placeholder) ? this.inputParameters[0].placeholder : this.$t('input.validFrom.placeholder'),
        (this.inputParameters && this.inputParameters[1] && this.inputParameters[1].placeholder) ? this.inputParameters[1].placeholder : this.$t('input.validThrough.placeholder')
      ];
    }
  },
  methods: {
    validate(rule, value, callback) {
      if (this.required !== true) return callback();
      if (value[0][0] === null || value[0][0] === '') {
        callback(new Error(this.$t('inputValidation.emptyPeriod.message')));
      } else {
        callback();
      }
    },
    emitValue: function () {
      this.$emit("input", this.input);
    },
    removeEntry() {
      this.input = dropRight(this.input);
      this.emitValue();
    },
    addEntry() {
      this.input.push([null, null]);
      this.emitValue();
    },
    disabledDateValidFrom(date, index) { // Whoever debugs this, please forgive me.
      // You need to understand that element-UI will iterate through all dates and checks with this function whether the date is displayed as disabled in the interface or not. Thus, the return of the function is a boolean.
      // One row (index) has two dates (input[index][0] and input[index][1]).
      if (!!(this.input[index - 1]) && !!(this.input[index]) && // Row before and current row exist?
          this.input[index - 1][1] !== null && this.input[index - 1][1] !== '' && // The date before the current is filled?
          (this.input[index][1] === null || this.input[index][1] === '')) { // The date after the current is not filled?
        return date < new Date(new Date(this.input[index - 1][1]).setDate(new Date(this.input[index - 1][1]).getDate() - 1)); // This line compares the (iterative) date with the last input from the row before/above, minus one day. It's a mess, but moment.js was too slow.
      }
      if (!!(this.input[index - 1]) && !!(this.input[index]) &&
          this.input[index - 1][1] !== null && this.input[index - 1][1] !== '' &&
          this.input[index][1] !== null && this.input[index][1] !== '') { // The date before and after exists. The comparision has to happen between both dates.
        return (date < new Date(new Date(this.input[index - 1][1]).setDate(new Date(this.input[index - 1][1]).getDate() - 1)) ||
            date > new Date(new Date(this.input[index][1]).setDate(new Date(this.input[index][1]).getDate() - 1)));
      }
      if (!!(this.input[index]) && this.input[index][1] !== null) { // The first two cases failed. If the user wants to change the very first date, this comparision makes sure it can't be selected beyond input[index][1].
        return date > new Date(new Date(this.input[index][1]).setDate(new Date(this.input[index][1]).getDate() - 1));
      }
      return false; // default case
    },
    disabledDateValidThrough(date, index) {
      if (!!(this.input[index + 1]) && !!(this.input[index]) &&
          this.input[index + 1][0] !== null && this.input[index + 1][0] !== '' &&
          this.input[index][0] !== null && this.input[index][0] !== '') { // Analog to the function above, this is disabling the dates before and after the input[index][1].
        return (date > new Date(new Date(this.input[index + 1][0]).setDate(new Date(this.input[index + 1][0]).getDate() - 1)) ||
            date < new Date(new Date(this.input[index][0]).setDate(new Date(this.input[index][0]).getDate() - 1)));
      }
      if (!!(this.input[index]) && this.input[index][0] !== null) { // Compare with the input before input[index][1].
        return date < new Date(new Date(this.input[index][0]).setDate(new Date(this.input[index][0]).getDate() - 1));
      }
      return false; // default case
    },
    inputParameter(index, key, standardValue) {
      return (this.inputParameters && this.inputParameters[index] && this.inputParameters[index][key]) ? this.inputParameters[index][key] : standardValue;
    }
  },
  watch: {
    input: {
      deep: true,
      handler() {
        // If a date field within this.input is emptied by hand,
        // the UI has to react on this change and empty all date fields that come after.
        let self = this;
        let lastIndex = this.input.length - 1;
        each(this.input, function (item, index) {
          if (index < lastIndex && (item[1] === null || item[1] === ''))
            self.input[index + 1][0] = null;
          if (item[0] === null || item[0] === '') {
            self.input[index][1] = null;
            if (index < lastIndex) self.input[index + 1][0] = null;
          }
        })
        this.emitValue();
      }
    }
  },
  created() {
    this.$emit('load', {label: this.label});
  },
  mounted() {
    this.input = (isEmpty(this.value)) ? this.defaultRow : this.modelValue;
    this.emitValue();
  }
}
</script>

<template>
  <div>

    <el-form-item
        :class="{'is-required': required, 'right-to-left': isRTL}"
        v-bind="$attrs"
        :label="this.label"
        :rules="[
        { validator: validate, trigger: ['blur', 'change'] }
      ]">

      <template #label>
        <span>{{label}}</span>
        <span v-if="!isEmpty(tooltip)" class="form-item__description"><i class="el-icon-info"/> {{tooltip}}</span>
        <span v-if="!isEmpty(description)" class="form-item__description"><i class="el-icon-info"/> {{description}}</span>
      </template>

      <div v-for="(item, index) in input" :key="index">

        <el-row>
          <el-col :span="12">
            <span style="color: #515151;">{{ t('input.validFrom.label') }}</span>
            <el-date-picker
                v-model="input[index][0]"
                v-bind="(inputParameters) ? inputParameters[0] : ''"
                type="date"
                :format="format[0]"
                class="dp-input__grouped__left"
                :style="style[0]"
                :clearable="inputParameter(0, 'clearable', !(item[1]))"
                :default-value="inputParameter(0, 'defaultValue', (!!(input[index-1]) && $dp.dayjs(input[index-1][1]).isValid()) ? new Date(input[index-1][1]) : new Date())"
                :placeholder="placeholder[0]"
                :picker-options="inputParameter(0, 'pickerOptions', {disabledDate: (date) => {return disabledDateValidFrom(date, index)}})"
                :disabled="inputParameter(0, 'disabled', (index > 0 && !$dp.dayjs(input[index -1][1]).isValid()))">
            </el-date-picker>
          </el-col>
          <el-col :span="12">
            <span style="color: #515151;">{{ t('input.validThrough.label') }}</span>
            <el-date-picker
                v-model="input[index][1]"
                v-bind="(inputParameters) ? inputParameters[1] : ''"
                type="date"
                :format="format[1]"
                class="dp-input__grouped__right"
                :style="style[1]"
                :clearable="inputParameter(1, 'clearable', !(input[index + 1] !== undefined && $dp.dayjs(input[index + 1][0]).isValid()))"
                :default-value="inputParameter(1, 'defaultValue', ($dp.dayjs(input[index][0]).isValid()) ? new Date(input[index][0]) : new Date())"
                :placeholder="placeholder[1]"
                :picker-options="inputParameter(1, 'pickerOptions', {disabledDate: (date) => {return disabledDateValidThrough(date, index)}})"
                :disabled="inputParameter(1, 'disabled', !$dp.dayjs(item[0]).isValid())">
            </el-date-picker>
          </el-col>
        </el-row>

      </div>

    </el-form-item>

    <div class="dp-input__period__button-container">
      <dp-button v-if="input.length > 1"
                 size="tiny"
                 purpose="destructive"
                 @click="removeEntry">
        <i class="el-icon-minus"></i>&nbsp;{{ t('removeLastTaxId.buttonLabel') }}
      </dp-button>
      <dp-button size="tiny"
                 @click="addEntry"
                 :disabled="input[input.length-1][1] === '' || input[input.length-1][1] === null">
        <i class="el-icon-plus"></i>&nbsp;{{ t('addTaxId.buttonLabel') }}
      </dp-button>
    </div>

  </div>

</template>

<i18n>
{
  "de-DE": {
    "addTaxId": {
      "buttonLabel": "Steuernummer hinzufügen"
    },
    "input": {
      "validFrom": {
        "placeholder": "Gültig ab",
        "label": "Gültig ab"
      },
      "validThrough": {
        "placeholder": "Gültig bis",
        "label": "Gültig bis"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Gültigen Eintrag eingeben."
      }
    }
  },
  "de-AT": {
    "addTaxId": {
      "buttonLabel": "Steuernummer hinzufügen"
    },
    "input": {
      "validFrom": {
        "placeholder": "Gültig ab",
        "label": "Gültig ab"
      },
      "validThrough": {
        "placeholder": "Gültig bis",
        "label": "Gültig bis"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Gültigen Eintrag eingeben."
      }
    }
  },
  "de-CH": {
    "addTaxId": {
      "buttonLabel": "Steuernummer hinzufügen"
    },
    "input": {
      "validFrom": {
        "placeholder": "Gültig ab",
        "label": "Gültig ab"
      },
      "validThrough": {
        "placeholder": "Gültig bis",
        "label": "Gültig bis"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Gültigen Eintrag eingeben."
      }
    }
  },
  "en-US": {
    "addTaxId": {
      "buttonLabel": "Add tax number"
    },
    "input": {
      "validFrom": {
        "placeholder": "Valid from",
        "label": "Valid from"
      },
      "validThrough": {
        "placeholder": "Valid until",
        "label": "Valid until"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Enter a valid entry."
      }
    }
  },
  "en-GB": {
    "addTaxId": {
      "buttonLabel": "Add tax number"
    },
    "input": {
      "validFrom": {
        "placeholder": "Valid from",
        "label": "Valid from"
      },
      "validThrough": {
        "placeholder": "Valid until",
        "label": "Valid until"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Enter a valid entry."
      }
    }
  },
  "it-IT": {
    "addTaxId": {
      "buttonLabel": "Aggiungi codice fiscale"
    },
    "input": {
      "validFrom": {
        "placeholder": "Valido dal",
        "label": "Valido dal"
      },
      "validThrough": {
        "placeholder": "Valido fino a",
        "label": "Valido fino a"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Si prega di inserire una voce valida."
      }
    }
  },
  "fr-FR": {
    "addTaxId": {
      "buttonLabel": "Ajouter un numéro de taxe"
    },
    "input": {
      "validFrom": {
        "placeholder": "Valide à partir de",
        "label": "Valide à partir de"
      },
      "validThrough": {
        "placeholder": "Valable jusque",
        "label": "Valable jusque"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Saisissez une entrée valide."
      }
    }
  },
  "es-ES": {
    "addTaxId": {
      "buttonLabel": "Agregar número de impuestos"
    },
    "input": {
      "validFrom": {
        "placeholder": "Válida desde",
        "label": "Válida desde"
      },
      "validThrough": {
        "placeholder": "Válido hasta",
        "label": "Válido hasta"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Ingrese una entrada válida."
      }
    }
  },
  "pl-PL": {
    "addTaxId": {
      "buttonLabel": "Dodaj numer podatkowy"
    },
    "input": {
      "validFrom": {
        "placeholder": "Ważne od",
        "label": "Ważne od"
      },
      "validThrough": {
        "placeholder": "Ważne do",
        "label": "Ważne do"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Wprowadź prawidłowy wpis."
      }
    }
  },
  "nl-NL": {
    "addTaxId": {
      "buttonLabel": "Voeg btw-nummer toe"
    },
    "input": {
      "validFrom": {
        "placeholder": "Geldig vanaf",
        "label": "Geldig vanaf"
      },
      "validThrough": {
        "placeholder": "Geldig tot",
        "label": "Geldig tot"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Voer een geldige invoer in."
      }
    }
  },
  "bg-BG": {
    "addTaxId": {
      "buttonLabel": "Добавете данъчен номер"
    },
    "input": {
      "validFrom": {
        "placeholder": "Валиден от",
        "label": "Валиден от"
      },
      "validThrough": {
        "placeholder": "Bалидно до",
        "label": "Bалидно до"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Въведете валиден запис."
      }
    }
  },
  "bs-BA": {
    "addTaxId": {
      "buttonLabel": "Dodajte porezni broj"
    },
    "input": {
      "validFrom": {
        "placeholder": "Vrijedi od",
        "label": "Vrijedi od"
      },
      "validThrough": {
        "placeholder": "Vrijedi do",
        "label": "Vrijedi do"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Unesite valjani unos."
      }
    }
  },
  "zh-CN": {
    "addTaxId": {
      "buttonLabel": "添加税号"
    },
    "input": {
      "validFrom": {
        "placeholder": "有效期自",
        "label": "有效期自"
      },
      "validThrough": {
        "placeholder": "有效期至",
        "label": "有效期至"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "输入有效的条目。"
      }
    }
  },
  "da-DK": {
    "addTaxId": {
      "buttonLabel": "Tilføj skattenummer"
    },
    "input": {
      "validFrom": {
        "placeholder": "Gældende fra",
        "label": "Gældende fra"
      },
      "validThrough": {
        "placeholder": "Gyldig indtil",
        "label": "Gyldig indtil"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Indtast en gyldig post."
      }
    }
  },
  "et-EE": {
    "addTaxId": {
      "buttonLabel": "Lisage maksunumber"
    },
    "input": {
      "validFrom": {
        "placeholder": "Kehtib alates",
        "label": "Kehtib alates"
      },
      "validThrough": {
        "placeholder": "Kehtiv kuni",
        "label": "Kehtiv kuni"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Sisestage kehtiv kirje."
      }
    }
  },
  "fi-FI": {
    "addTaxId": {
      "buttonLabel": "Lisää veronumero"
    },
    "input": {
      "validFrom": {
        "placeholder": "Voimassa",
        "label": "Voimassa"
      },
      "validThrough": {
        "placeholder": "Voimassa asti",
        "label": "Voimassa asti"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Anna kelvollinen merkintä."
      }
    }
  },
  "el-GR": {
    "addTaxId": {
      "buttonLabel": "Προσθήκη αριθμού φόρου"
    },
    "input": {
      "validFrom": {
        "placeholder": "Ισχύει από",
        "label": "Ισχύει από"
      },
      "validThrough": {
        "placeholder": "Εγκυρο μέχρι",
        "label": "Εγκυρο μέχρι"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Εισαγάγετε μια έγκυρη καταχώριση."
      }
    }
  },
  "he-IL": {
    "addTaxId": {
      "buttonLabel": "הוסף מספר מס"
    },
    "input": {
      "validFrom": {
        "placeholder": "בתוקף מ",
        "label": "בתוקף מ"
      },
      "validThrough": {
        "placeholder": "בתוקף עד",
        "label": "בתוקף עד"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "אנא הזן ערך חוקי."
      }
    }
  },
  "ga-IE": {
    "addTaxId": {
      "buttonLabel": "Cuir uimhir chánach leis"
    },
    "input": {
      "validFrom": {
        "placeholder": "Bailí ó",
        "label": "Bailí ó"
      },
      "validThrough": {
        "placeholder": "Bailí go dtí",
        "label": "Bailí go dtí"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Iontráil iontráil bhailí."
      }
    }
  },
  "ja-JP": {
    "addTaxId": {
      "buttonLabel": "税番号を追加"
    },
    "input": {
      "validFrom": {
        "placeholder": "から有効",
        "label": "から有効"
      },
      "validThrough": {
        "placeholder": "まで有効",
        "label": "まで有効"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "有効なエントリを入力してください。"
      }
    }
  },
  "hr-HR": {
    "addTaxId": {
      "buttonLabel": "Dodajte porezni broj"
    },
    "input": {
      "validFrom": {
        "placeholder": "Vrijedi od",
        "label": "Vrijedi od"
      },
      "validThrough": {
        "placeholder": "Vrijedi do",
        "label": "Vrijedi do"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Unesite valjani unos."
      }
    }
  },
  "lv-LV": {
    "addTaxId": {
      "buttonLabel": "Pievienojiet nodokļu numuru"
    },
    "input": {
      "validFrom": {
        "placeholder": "Derīgs no",
        "label": "Derīgs no"
      },
      "validThrough": {
        "placeholder": "Derīgs līdz",
        "label": "Derīgs līdz"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Ievadiet derīgu ierakstu."
      }
    }
  },
  "lt-LT": {
    "addTaxId": {
      "buttonLabel": "Pridėkite mokesčių numerį"
    },
    "input": {
      "validFrom": {
        "placeholder": "Galioja nuo",
        "label": "Galioja nuo"
      },
      "validThrough": {
        "placeholder": "Galioja iki",
        "label": "Galioja iki"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Įveskite tinkamą įrašą."
      }
    }
  },
  "pt-PT": {
    "addTaxId": {
      "buttonLabel": "Adicionar número fiscal"
    },
    "input": {
      "validFrom": {
        "placeholder": "Válido de",
        "label": "Válido de"
      },
      "validThrough": {
        "placeholder": "Válido até",
        "label": "Válido até"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Insira uma entrada válida."
      }
    }
  },
  "ro-RO": {
    "addTaxId": {
      "buttonLabel": "Adăugați un număr de impozitare"
    },
    "input": {
      "validFrom": {
        "placeholder": "Valabil din",
        "label": "Valabil din"
      },
      "validThrough": {
        "placeholder": "Valabil până la",
        "label": "Valabil până la"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Vă rugăm să introduceți o intrare validă."
      }
    }
  },
  "ru-RU": {
    "addTaxId": {
      "buttonLabel": "Добавить налоговый номер"
    },
    "input": {
      "validFrom": {
        "placeholder": "Действует с",
        "label": "Действует с"
      },
      "validThrough": {
        "placeholder": "действительна до",
        "label": "действительна до"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Введите действительную запись."
      }
    }
  },
  "sv-SE": {
    "addTaxId": {
      "buttonLabel": "Lägg till skattenummer"
    },
    "input": {
      "validFrom": {
        "placeholder": "Giltig från",
        "label": "Giltig från"
      },
      "validThrough": {
        "placeholder": "Giltig till",
        "label": "Giltig till"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Ange en giltig post."
      }
    }
  },
  "sk-SK": {
    "addTaxId": {
      "buttonLabel": "Pridajte daňové číslo"
    },
    "input": {
      "validFrom": {
        "placeholder": "Platnosť od",
        "label": "Platnosť od"
      },
      "validThrough": {
        "placeholder": "Platné do",
        "label": "Platné do"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Zadajte platný údaj."
      }
    }
  },
  "cs-CZ": {
    "addTaxId": {
      "buttonLabel": "Přidejte daňové číslo"
    },
    "input": {
      "validFrom": {
        "placeholder": "Platnost od",
        "label": "Platnost od"
      },
      "validThrough": {
        "placeholder": "V platnosti, dokud",
        "label": "V platnosti, dokud"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Zadejte prosím platnou položku."
      }
    }
  },
  "tr-TR": {
    "addTaxId": {
      "buttonLabel": "Vergi numarası ekleyin"
    },
    "input": {
      "validFrom": {
        "placeholder": "Kadar geçerli",
        "label": "Kadar geçerli"
      },
      "validThrough": {
        "placeholder": "Son kullanma tarihi",
        "label": "Son kullanma tarihi"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Geçerli bir giriş girin."
      }
    }
  },
  "hu-HU": {
    "addTaxId": {
      "buttonLabel": "Adja meg az adószámot"
    },
    "input": {
      "validFrom": {
        "placeholder": "Től érvényes",
        "label": "Től érvényes"
      },
      "validThrough": {
        "placeholder": "Ig érvényes",
        "label": "Ig érvényes"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Adjon meg egy érvényes bejegyzést."
      }
    }
  },
  "ar-AE": {
    "addTaxId": {
      "buttonLabel": "أضف الرقم الضريبي"
    },
    "input": {
      "validFrom": {
        "placeholder": "صالح من تاريخ",
        "label": "صالح من تاريخ"
      },
      "validThrough": {
        "placeholder": "صالح حتى",
        "label": "صالح حتى"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "أدخل إدخالاً صالحًا."
      }
    }
  },
  "fr-BE": {
    "addTaxId": {
      "buttonLabel": "Ajouter un numéro de taxe"
    },
    "input": {
      "validFrom": {
        "placeholder": "Valide à partir de",
        "label": "Valide à partir de"
      },
      "validThrough": {
        "placeholder": "Valable jusque",
        "label": "Valable jusque"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Saisissez une entrée valide."
      }
    }
  },
  "fr-LU": {
    "addTaxId": {
      "buttonLabel": "Ajouter un numéro de taxe"
    },
    "input": {
      "validFrom": {
        "placeholder": "Valide à partir de",
        "label": "Valide à partir de"
      },
      "validThrough": {
        "placeholder": "Valable jusque",
        "label": "Valable jusque"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Saisissez une entrée valide."
      }
    }
  },
  "mt-MT": {
    "addTaxId": {
      "buttonLabel": "Żid in-numru tat-taxxa"
    },
    "input": {
      "validFrom": {
        "placeholder": "Validu minn",
        "label": "Validu minn"
      },
      "validThrough": {
        "placeholder": "Validu permezz",
        "label": "Validu permezz"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Daħħal dħul validu."
      }
    }
  },
  "sl-SI": {
    "addTaxId": {
      "buttonLabel": "Dodajte davčno številko"
    },
    "input": {
      "validFrom": {
        "placeholder": "Velja od",
        "label": "Velja od"
      },
      "validThrough": {
        "placeholder": "Veljavno do",
        "label": "Veljavno do"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Vnesite veljaven vnos."
      }
    }
  },
  "el-CY": {
    "addTaxId": {
      "buttonLabel": "Προσθήκη αριθμού φόρου"
    },
    "input": {
      "validFrom": {
        "placeholder": "Ισχύει από",
        "label": "Ισχύει από"
      },
      "validThrough": {
        "placeholder": "Εγκυρο μέχρι",
        "label": "Εγκυρο μέχρι"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Εισαγάγετε μια έγκυρη καταχώριση."
      }
    }
  },
  "tr-CY": {
    "addTaxId": {
      "buttonLabel": "Vergi numarası ekleyin"
    },
    "input": {
      "validFrom": {
        "placeholder": "Kadar geçerli",
        "label": "Kadar geçerli"
      },
      "validThrough": {
        "placeholder": "Son kullanma tarihi",
        "label": "Son kullanma tarihi"
      }
    },
    "inputValidation": {
      "emptyPeriod": {
        "message": "Geçerli bir giriş girin."
      }
    }
  }
}
</i18n>

