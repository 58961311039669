<script setup>
import { inject } from 'vue';
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const composables = inject('composables');
const { isRTL } = composables.useJurisdictionSwitch()
</script>
<script>
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import {mapState} from 'pinia';
  import {useEnvStore} from '@dutypay/store-modules'

export default {
  name: "DpFormDeletionConfirmationUserInput",
  inheritAttrs: true,
  props: {
    reference: {type: String, required: true},
    action: {required: true},
    confirmationPhrase: {required: true, type: String},
    notificationSuccess: {type: Object},
    notificationError: {type: Object},
    notificationAsyncError: {type: Object},
    disableNotification: [Boolean, Array],
    deleteButtonLabel: {type: String},
    keepButtonLabel: {type: String},
    deleteButtonParameter: {type: Object},
    keepButtonParameter: {type: Object},
    inputParameter: {type: Object},
    triggerAction: {type: Boolean},
    labelPosition: {type: String, default: 'top'},
    isFormActive: {type: Boolean, default: false},
    fullWidth: {type: Boolean, default: false}
  },
  data() {
    return {
      confirmationForm: {
        confirmationPhrase: ''
      },
      isLoading: false,
      isFormActiveLocal: this.isFormActive
    };
  },
  computed: {
    ...mapState(useEnvStore, ['debug']),
    notification() {
      return {
        success: (this.notificationSuccess !== undefined) ? this.notificationSuccess : {
          title: this.$t('notification.success.title'),
          message: this.$t('notification.success.message')
        },
        error: (this.notificationError !== undefined) ? this.notificationError : {
          title: this.$t('notification.error.title'),
          message: this.$t('notification.error.message')
        },
        asyncError: (this.notificationAsyncError !== undefined) ? this.notificationAsyncError : {
          title: this.$t('notification.asyncError.title'),
          message: this.$t('notification.asyncError.message')
        }
      }
    },
    computedButtonLabel() {
      return {
        deleteButtonLabel: (isEmpty(this.deleteButtonLabel)) ? this.$t('delete.buttonLabel') : this.deleteButtonLabel,
        keepButtonLabel: (isEmpty(this.keepButtonLabel)) ? this.$t('keep.buttonLabel') : this.keepButtonLabel,
      };
    }
  },
  watch: {
    triggerAction: function (val) {
      if (val) this.validateAndExecuteDeletion();
    },
    isFormActive() {
      this.isFormActiveLocal = this.isFormActive;
    }
  },
  methods: {
    validate(rule, value, callback) {
      if (value !== this.confirmationPhrase) {
        callback(new Error(this.$t('inputValidation.incorrectPhrase.message')));
      } else {
        callback();
      }
    },
    cancelForm() {
      this.deactivateForm();
      this.$emit('cancel-form');
    },
    submitForm() {
      if (this.isFormActiveLocal) {
        this.validateAndExecuteDeletion();
      } else {
        this.activateForm();
      }
    },
    activateForm() {
      this.isFormActiveLocal = true;
      this.$nextTick(() => {
        this.$refs[`${this.reference}__keepButton`].focus();
      });
      this.$emit('activate-form');
    },
    deactivateForm() {
      this.isFormActiveLocal = false;
      this.$nextTick(() => {
        this.$refs[this.reference].clearValidate();
      })
      this.$emit('deactivate-form');
    },
    async validateAndExecuteDeletion() {
      let self = this;
      this.$refs[this.reference].validate(
          async (valid) => {
            if (valid) {
              try {
                self.isLoading = true;
                let res = await self.action();
                self.deactivateForm();
                self.isLoading = false;
                if (res.status >= 400 && res.status <= 600) {
                  self.notifyAsyncError(self.notification.asyncError);
                  self.$emit('asyncError');
                  return;
                }
                self.notifySuccess(self.notification.success);
                self.$emit('success');
              } catch (e) {
                self.notifyAsyncError(self.notification.asyncError);
                self.$emit('asyncError');
                if (this.debug)
                  return e;
              }
            } else {
              self.notifyError(self.notification.error);
              self.$emit('error');
              return false;
            }
          }
      );
    },
    containsKey(key) {
      return find(this.disableNotification, (iteratee) => {return (iteratee === key)}) !== undefined;
    },
    notifyError(message) {
      if (this.disableNotification === true || this.containsKey('error'))
        return;
      this.$notify.error(message);
    },
    notifyAsyncError(message) {
      if (this.disableNotification === true || this.containsKey('asyncError'))
        return;
      this.$notify.error(message);
    },
    notifySuccess(message) {
      if (this.disableNotification === true || this.containsKey('success'))
        return;
      this.$notify.success(message);
    }
  }

}
</script>

<template>
  <el-form
      v-bind="$attrs"
      v-loading="isLoading"
      :model="confirmationForm"
      :ref="reference"
      :label-position="labelPosition"
      :class="{'dp-form__deletion': isFormActiveLocal, 'dp-form__deletion__user-input': !fullWidth && isFormActiveLocal}"
      @submit.prevent
      @submit.native.prevent
      @keydown.enter.prevent
      @keydown.enter.native.prevent>

    <slot></slot>

    <div v-if="isFormActiveLocal">
      <slot name="keepButton">
        <dp-button v-bind="keepButtonParameter"
                   purpose="secondaryAction"
                   :ref="`${reference}__keepButton`"
                   @click="cancelForm">
          {{computedButtonLabel.keepButtonLabel}}
        </dp-button>
      </slot>

      <p><slot name="instructions">{{ t('instructions.label') }}</slot></p>
      <p><span class="dp-form__deletion__confirmation-phrase">{{ confirmationPhrase }}</span></p>

      <el-form-item :rules="[{ validator: validate, trigger: ['blur'] }]"
                    prop="confirmationPhrase" :class="{'right-to-left': isRTL}">
        <el-input v-bind="inputParameter" v-model="confirmationForm.confirmationPhrase" :placeholder="confirmationPhrase"></el-input>
      </el-form-item>
    </div>

    <el-form-item>
      <dp-button purpose="destructiveAction" v-bind="deleteButtonParameter" @click="submitForm">
        {{computedButtonLabel.deleteButtonLabel}}
      </dp-button>
    </el-form-item>

  </el-form>
</template>

<style scoped lang="scss">
</style>

<i18n>
{
  "de-DE": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Gültigen Eintrag eingeben."
      }
    }
  },
  "de-AT": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Gültigen Eintrag eingeben."
      }
    }
  },
  "de-CH": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Gültigen Eintrag eingeben."
      }
    }
  },
  "en-US": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Enter a valid entry."
      }
    }
  },
  "en-GB": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Enter a valid entry."
      }
    }
  },
  "it-IT": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Si prega di inserire una voce valida."
      }
    }
  },
  "fr-FR": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Saisissez une entrée valide."
      }
    }
  },
  "es-ES": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Ingrese una entrada válida."
      }
    }
  },
  "pl-PL": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Wprowadź prawidłowy wpis."
      }
    }
  },
  "nl-NL": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Voer een geldige invoer in."
      }
    }
  },
  "bg-BG": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Въведете валиден запис."
      }
    }
  },
  "bs-BA": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Unesite valjani unos."
      }
    }
  },
  "zh-CN": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "输入有效的条目。"
      }
    }
  },
  "da-DK": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Indtast en gyldig post."
      }
    }
  },
  "et-EE": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Sisestage kehtiv kirje."
      }
    }
  },
  "fi-FI": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Anna kelvollinen merkintä."
      }
    }
  },
  "el-GR": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Εισαγάγετε μια έγκυρη καταχώριση."
      }
    }
  },
  "he-IL": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "אנא הזן ערך חוקי."
      }
    }
  },
  "ga-IE": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Iontráil iontráil bhailí."
      }
    }
  },
  "ja-JP": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "有効なエントリを入力してください。"
      }
    }
  },
  "hr-HR": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Unesite valjani unos."
      }
    }
  },
  "lv-LV": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Ievadiet derīgu ierakstu."
      }
    }
  },
  "lt-LT": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Įveskite tinkamą įrašą."
      }
    }
  },
  "pt-PT": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Insira uma entrada válida."
      }
    }
  },
  "ro-RO": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Vă rugăm să introduceți o intrare validă."
      }
    }
  },
  "ru-RU": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Введите действительную запись."
      }
    }
  },
  "sv-SE": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Ange en giltig post."
      }
    }
  },
  "sk-SK": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Zadajte platný údaj."
      }
    }
  },
  "cs-CZ": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Zadejte prosím platnou položku."
      }
    }
  },
  "tr-TR": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Geçerli bir giriş girin."
      }
    }
  },
  "hu-HU": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Adjon meg egy érvényes bejegyzést."
      }
    }
  },
  "ar-AE": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "أدخل إدخالاً صالحًا."
      }
    }
  },
  "fr-BE": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Saisissez une entrée valide."
      }
    }
  },
  "fr-LU": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Saisissez une entrée valide."
      }
    }
  },
  "mt-MT": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Daħħal dħul validu."
      }
    }
  },
  "sl-SI": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Vnesite veljaven vnos."
      }
    }
  },
  "el-CY": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Εισαγάγετε μια έγκυρη καταχώριση."
      }
    }
  },
  "tr-CY": {
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Geçerli bir giriş girin."
      }
    }
  }
}
</i18n>
