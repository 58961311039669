export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de-DE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib mindestens 12 Zeichen ein."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib mindestens 12 Zeichen ein."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Einträge sind nicht identisch."])}
          }
        }
      },
      "de-AT": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib mindestens 12 Zeichen ein."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib mindestens 12 Zeichen ein."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Einträge sind nicht identisch."])}
          }
        }
      },
      "de-CH": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib mindestens 12 Zeichen ein."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib mindestens 12 Zeichen ein."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Einträge sind nicht identisch."])}
          }
        }
      },
      "en-US": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter at least 12 characters."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter at least 12 characters."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The entries are not identical."])}
          }
        }
      },
      "en-GB": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter at least 12 characters."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter at least 12 characters."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The entries are not identical."])}
          }
        }
      },
      "it-IT": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti la password"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti la password"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti la password"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci almeno 12 caratteri."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci almeno 12 caratteri."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le voci non sono identiche."])}
          }
        }
      },
      "fr-FR": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez à nouveau votre mot de passe"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez à nouveau votre mot de passe"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez à nouveau votre mot de passe"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir au moins 12 caractères."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir au moins 12 caractères."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les entrées ne sont pas identiques."])}
          }
        }
      },
      "es-ES": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repite la contraseña"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repite la contraseña"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repite la contraseña"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca al menos 12 caracteres."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca al menos 12 caracteres."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las contraseñas no son iguales."])}
          }
        }
      },
      "pl-PL": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz hasło"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz hasło"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz hasło"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpisz co najmniej 12 znaków."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpisz co najmniej 12 znaków."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpisy nie są identyczne."])}
          }
        }
      },
      "nl-NL": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhaal wachtwoord"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhaal wachtwoord"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhaal wachtwoord"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer ten minste 12 tekens in."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer ten minste 12 tekens in."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De invoer is niet identiek."])}
          }
        }
      },
      "bg-BG": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парола"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парола"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парола"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторете паролата"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторете паролата"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторете паролата"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моля, въведете поне 12 символа."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моля, въведете поне 12 символа."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведеното не съвпада."])}
          }
        }
      },
      "bs-BA": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lozinka"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lozinka"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lozinka"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponovite lozinku"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponovite lozinku"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponovite lozinku"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite najmanje 12 znakova."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite najmanje 12 znakova."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unosi nisu identični."])}
          }
        }
      },
      "zh-CN": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再次输入密码"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再次输入密码"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再次输入密码"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入至少12个字符。"])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入至少12个字符。"])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码不同。"])}
          }
        }
      },
      "da-DK": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskode"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskode"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskode"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gentag adgangskode"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gentag adgangskode"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gentag adgangskode"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast mindst 12 tegn."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast mindst 12 tegn."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posterne er ikke identiske."])}
          }
        }
      },
      "et-EE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parool"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parool"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parool"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korda parooli"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korda parooli"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korda parooli"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage vähemalt 12 tähemärki."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage vähemalt 12 tähemärki."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjed pole identsed."])}
          }
        }
      },
      "fi-FI": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasana"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasana"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasana"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toista salasana"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toista salasana"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toista salasana"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita vähintään 12 merkkiä."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita vähintään 12 merkkiä."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkinnät eivät ole identtisiä."])}
          }
        }
      },
      "el-GR": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός πρόσβασης"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός πρόσβασης"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός πρόσβασης"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαναλάβετε τον κωδικό πρόσβασης"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαναλάβετε τον κωδικό πρόσβασης"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαναλάβετε τον κωδικό πρόσβασης"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε τουλάχιστον 12 χαρακτήρες."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε τουλάχιστον 12 χαρακτήρες."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι καταχωρήσεις δεν είναι πανομοιότυπες."])}
          }
        }
      },
      "he-IL": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סיסמה"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סיסמה"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סיסמה"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["חזור על הסיסמה"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["חזור על הסיסמה"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["חזור על הסיסמה"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אנא הזן לפחות 12 תווים."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אנא הזן לפחות 12 תווים."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הערכים אינם זהים."])}
          }
        }
      },
      "ga-IE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasfhocal"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasfhocal"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasfhocal"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Athscríobh an pasfhocal"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Athscríobh an pasfhocal"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Athscríobh an pasfhocal"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iontráil 12 charachtar ar a laghad."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iontráil 12 charachtar ar a laghad."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Níl na hiontrálacha comhionann."])}
          }
        }
      },
      "ja-JP": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを再度入力してください"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを再度入力してください"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを再度入力してください"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12文字以上入力してください。"])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12文字以上入力してください。"])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エントリは同一ではありません。"])}
          }
        }
      },
      "hr-HR": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lozinka"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lozinka"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lozinka"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponovite lozinku"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponovite lozinku"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponovite lozinku"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite najmanje 12 znakova."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite najmanje 12 znakova."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unosi nisu identični."])}
          }
        }
      },
      "lv-LV": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parole"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parole"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parole"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atkārtot paroli"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atkārtot paroli"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atkārtot paroli"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lūdzu, ievadiet vismaz 12 rakstzīmes."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lūdzu, ievadiet vismaz 12 rakstzīmes."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ieraksti nav identiski."])}
          }
        }
      },
      "lt-LT": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slaptažodis"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slaptažodis"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slaptažodis"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakartokite slaptažodį"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakartokite slaptažodį"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakartokite slaptažodį"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite mažiausiai 12 simbolių."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite mažiausiai 12 simbolių."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašai nėra tapatūs."])}
          }
        }
      },
      "pt-PT": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palavra-passe"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palavra-passe"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palavra-passe"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repita a palavra-passe"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repita a palavra-passe"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repita a palavra-passe"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduza pelo menos 12 caracteres."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduza pelo menos 12 caracteres."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As entradas não são idênticas."])}
          }
        }
      },
      "ro-RO": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetați parola"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetați parola"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetați parola"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă rugăm să introduceți cel puțin 12 caractere."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă rugăm să introduceți cel puțin 12 caractere."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valorile introduse nu sunt identice."])}
          }
        }
      },
      "ru-RU": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите пароль"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите пароль"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите пароль"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите не менее 12 символов."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите не менее 12 символов."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введенные данные не совпадают."])}
          }
        }
      },
      "sv-SE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upprepa lösenord"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upprepa lösenord"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upprepa lösenord"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange minst 12 tecken."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange minst 12 tecken."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inmatningarna är inte identiska"])}
          }
        }
      },
      "sk-SK": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zopakujte heslo"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zopakujte heslo"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zopakujte heslo"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte prosím aspoň 12 znakov."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte prosím aspoň 12 znakov."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Položky nie sú totožné."])}
          }
        }
      },
      "cs-CZ": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zopakovat heslo"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zopakovat heslo"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zopakovat heslo"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte nejméně 12 znaků."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte nejméně 12 znaků."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Položky nejsou totožné."])}
          }
        }
      },
      "tr-TR": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreyi tekrar girin"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreyi tekrar girin"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreyi tekrar girin"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen en az 12 karakter girin."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen en az 12 karakter girin."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Girişler aynı değil."])}
          }
        }
      },
      "hu-HU": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó újra"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó újra"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó újra"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, adjon meg legalább 12 karaktert."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, adjon meg legalább 12 karaktert."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megadott adatok nem egyeznek."])}
          }
        }
      },
      "ar-AE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة السر"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة السر"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة السر"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعد كلمة السر"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعد كلمة السر"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعد كلمة السر"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال 12 حرفًا على الأقل."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال 12 حرفًا على الأقل."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإدخالات ليست متطابقة."])}
          }
        }
      },
      "fr-BE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez à nouveau votre mot de passe"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez à nouveau votre mot de passe"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez à nouveau votre mot de passe"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir au moins 12 caractères."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir au moins 12 caractères."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les entrées ne sont pas identiques."])}
          }
        }
      },
      "fr-LU": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter le mot de passe"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter le mot de passe"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter le mot de passe"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir au moins 12 caractères."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir au moins 12 caractères."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les entrées ne sont pas identiques."])}
          }
        }
      },
      "mt-MT": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irrepeti l-password"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irrepeti l-password"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irrepeti l-password"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jekk jogħġbok daħħal mill-inqas 12-il karattru."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jekk jogħġbok daħħal mill-inqas 12-il karattru."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L-entrati mhumiex identiċi."])}
          }
        }
      },
      "sl-SI": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslo"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslo"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslo"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponovite geslo"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponovite geslo"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponovite geslo"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnesite vsaj 12 znakov."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnesite vsaj 12 znakov."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnosa nista enaka."])}
          }
        }
      },
      "el-CY": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός πρόσβασης"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός πρόσβασης"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός πρόσβασης"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαναλάβετε τον κωδικό πρόσβασης"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαναλάβετε τον κωδικό πρόσβασης"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαναλάβετε τον κωδικό πρόσβασης"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε τουλάχιστον 12 χαρακτήρες."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε τουλάχιστον 12 χαρακτήρες."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι καταχωρήσεις δεν είναι πανομοιότυπες."])}
          }
        }
      },
      "tr-CY": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola"])}
        },
        "inputRepeat": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreyi tekrar girin"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreyi tekrar girin"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreyi tekrar girin"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen en az 12 karakter girin."])}
          },
          "passwordTooShort": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen en az 12 karakter girin."])}
          },
          "notIdentical": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Girişler aynı değil."])}
          }
        }
      }
    }
  })
}
