export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de-DE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilnummer"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilnummer"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib deine Mobilnummer im internationalen Format ein."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib eine gültige Mobilnummer ein."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib eine gültige Mobilnummer ein."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib eine gültige Mobilnummer ein."])}
          }
        }
      },
      "de-AT": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilnummer"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilnummer"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib deine Mobilnummer im internationalen Format ein."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib eine gültige Mobilnummer ein."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib eine gültige Mobilnummer ein."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib eine gültige Mobilnummer ein."])}
          }
        }
      },
      "de-CH": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilnummer"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilnummer"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib deine Mobilnummer im internationalen Format ein."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib eine gültige Mobilnummer ein."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib eine gültige Mobilnummer ein."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib eine gültige Mobilnummer ein."])}
          }
        }
      },
      "en-US": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your mobile number in international format."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid mobile number."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid mobile number."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid mobile number."])}
          }
        }
      },
      "en-GB": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your mobile number in international format."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid mobile number."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid mobile number."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid mobile number."])}
          }
        }
      },
      "it-IT": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di telefono"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di telefono"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci il tuo numero di cellulare in formato internazionale."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci un numero di telefono valido."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci un numero di cellulare valido."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci un numero di telefono valido."])}
          }
        }
      },
      "fr-FR": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone mobile"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone mobile"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre numéro de portable au format international."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un numéro de téléphone portable valide."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un numéro de mobile valide."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un numéro de téléphone portable valide."])}
          }
        }
      },
      "es-ES": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese su número de teléfono móvil en formato internacional."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor introduzca un número de teléfono válido."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca un número de móvil válido."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor introduzca un número de teléfono válido."])}
          }
        }
      },
      "pl-PL": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer telefonu komórkowego"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer telefonu komórkowego"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpisz swój numer telefonu komórkowego w formacie międzynarodowym."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź prawidłowy numer telefonu komórkowego."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź prawidłowy numer telefonu komórkowego."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź prawidłowy numer telefonu komórkowego."])}
          }
        }
      },
      "nl-NL": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiel nummer"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiel nummer"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer uw mobiele nummer in internationaal formaat in."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer een geldig mobiel nummer in."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer een geldig mobiel nummer in."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer een geldig mobiel nummer in."])}
          }
        }
      },
      "bg-BG": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мобилен номер"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мобилен номер"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете мобилния си номер в международен формат."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моля, въведете валиден мобилен номер."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моля, въведете валиден мобилен номер."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моля, въведете валиден мобилен номер."])}
          }
        }
      },
      "bs-BA": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broj mobitela"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broj mobitela"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite svoj broj mobitela u međunarodnom formatu."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite važeći broj mobitela."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite važeći broj mobitela."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite važeći broj mobitela."])}
          }
        }
      },
      "zh-CN": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手机号码"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手机号码"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以国际格式输入您的手机号码。"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入有效的手机号码。"])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入有效的手机号码。"])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入有效的手机号码。"])}
          }
        }
      },
      "da-DK": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilnummer"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilnummer"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast dit mobilnummer i internationalt format."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast venligst et gyldigt mobilnummer."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast venligst et gyldigt mobilnummer."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast venligst et gyldigt mobilnummer."])}
          }
        }
      },
      "et-EE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiili number"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiili number"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage oma mobiiltelefoni number rahvusvahelises vormingus."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage kehtiv mobiilinumber."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage kehtiv mobiilinumber."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage kehtiv mobiilinumber."])}
          }
        }
      },
      "fi-FI": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matkapuhelinnumero"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matkapuhelinnumero"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna matkapuhelinnumerosi kansainvälisessä muodossa."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna kelvollinen matkapuhelinnumero."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna kelvollinen matkapuhelinnumero."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna kelvollinen matkapuhelinnumero."])}
          }
        }
      },
      "el-GR": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός κινητού"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός κινητού"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε τον αριθμό του κινητού σας σε διεθνή μορφή."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε έναν έγκυρο αριθμό κινητού."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε έναν έγκυρο αριθμό κινητού."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε έναν έγκυρο αριθμό κινητού."])}
          }
        }
      },
      "he-IL": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מספר טלפון נייד"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מספר טלפון נייד"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הזן את מספר הנייד שלך בפורמט בינלאומי."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אנא הזן מספר נייד תקף."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אנא הזן מספר נייד תקף."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אנא הזן מספר נייד תקף."])}
          }
        }
      },
      "ga-IE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uimhir fóin póca"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uimhir fóin póca"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iontráil d’uimhir soghluaiste i bhformáid idirnáisiúnta."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iontráil uimhir soghluaiste bhailí."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iontráil uimhir soghluaiste bhailí."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iontráil uimhir soghluaiste bhailí."])}
          }
        }
      },
      "ja-JP": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯電話番号"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯電話番号"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯電話番号を国際形式で入力してください。"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効な携帯電話番号を入力してください。"])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効な携帯電話番号を入力してください。"])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効な携帯電話番号を入力してください。"])}
          }
        }
      },
      "hr-HR": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broj mobitela"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broj mobitela"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite svoj broj mobitela u međunarodnom formatu."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite važeći broj mobitela."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite važeći broj mobitela."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite važeći broj mobitela."])}
          }
        }
      },
      "lv-LV": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilā tālruņa numurs"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilā tālruņa numurs"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ievadiet savu mobilā tālruņa numuru starptautiskā formātā."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lūdzu, ievadiet derīgu mobilā tālruņa numuru."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lūdzu, ievadiet derīgu mobilā tālruņa numuru."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lūdzu, ievadiet derīgu mobilā tālruņa numuru."])}
          }
        }
      },
      "lt-LT": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiliojo telefono numeris"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiliojo telefono numeris"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite savo mobiliojo telefono numerį tarptautiniu formatu."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite galiojantį mobiliojo telefono numerį."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite galiojantį mobiliojo telefono numerį."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite galiojantį mobiliojo telefono numerį."])}
          }
        }
      },
      "pt-PT": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de telemóvel"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de telemóvel"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite seu número de celular no formato internacional."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduza um número de telemóvel válido."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor insira um número de telemóvel válido."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduza um número de telemóvel válido."])}
          }
        }
      },
      "ro-RO": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Număr de telefon mobil"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Număr de telefon mobil"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți numărul dvs. de mobil în format internațional."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți un număr de telefon mobil corect."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă rugăm să introduceți un număr de telefon mobil valid."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți un număr de telefon mobil corect."])}
          }
        }
      },
      "ru-RU": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер мобильного телефона"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер мобильного телефона"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите номер своего мобильного телефона в международном формате."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите действительный номер мобильного телефона."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите действующий номер мобильного телефона."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите действительный номер мобильного телефона."])}
          }
        }
      },
      "sv-SE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilnummer"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilnummer"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange ditt mobilnummer i internationellt format."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange giltigt telefonnummer."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange ett giltigt mobilnummer."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange giltigt telefonnummer."])}
          }
        }
      },
      "sk-SK": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilné číslo"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilné číslo"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte svoje mobilné číslo v medzinárodnom formáte."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte prosím platné mobilné číslo."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte platné číslo mobilného telefónu."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte prosím platné mobilné číslo."])}
          }
        }
      },
      "cs-CZ": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo mobilního telefonu"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo mobilního telefonu"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte své mobilní číslo v mezinárodním formátu."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte platné číslo mobilního telefonu."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte platné číslo mobilního telefonu."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte platné číslo mobilního telefonu."])}
          }
        }
      },
      "tr-TR": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cep numarası"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cep numarası"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cep telefonu numaranızı uluslararası formatta girin."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir telefon numarası giriniz."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir telefon numarası giriniz."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir telefon numarası giriniz."])}
          }
        }
      },
      "hu-HU": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil szám"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil szám"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írja be mobilszámát nemzetközi formátumban."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, érvényes mobilszámot adjon meg."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, adjon meg érvényes mobilszámot."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, érvényes mobilszámot adjon meg."])}
          }
        }
      },
      "ar-AE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف المحمول"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف المحمول"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل رقم هاتفك المحمول بصيغة دولية."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال رقم جوال صحيح."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال رقم جوال صحيح."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال رقم جوال صحيح."])}
          }
        }
      },
      "fr-BE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone mobile"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone mobile"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre numéro de portable au format international."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un numéro de téléphone portable valide."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un numéro de mobile valide."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un numéro de téléphone portable valide."])}
          }
        }
      },
      "fr-LU": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone mobile"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone mobile"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre numéro de portable au format international."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un numéro de téléphone portable valide."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un numéro de mobile valide."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un numéro de téléphone portable valide."])}
          }
        }
      },
      "mt-MT": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In-numru tal-Mowbajl"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In-numru tal-Mowbajl"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daħħal in-numru tal-mowbajl tiegħek f'format internazzjonali."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jekk jogħġbok daħħal numru tal-mowbajl validu."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jekk jogħġbok daħħal numru mobbli validu."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jekk jogħġbok daħħal numru tal-mowbajl validu."])}
          }
        }
      },
      "sl-SI": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonska številka"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonska številka"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnesite svojo številko mobilnega telefona v mednarodni obliki."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnesite veljavno številko mobilnega telefona."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnesite veljavno številko mobilnega telefona."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnesite veljavno številko mobilnega telefona."])}
          }
        }
      },
      "el-CY": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός κινητού"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός κινητού"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε τον αριθμό του κινητού σας σε διεθνή μορφή."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε έναν έγκυρο αριθμό κινητού."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε έναν έγκυρο αριθμό κινητού."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε έναν έγκυρο αριθμό κινητού."])}
          }
        }
      },
      "tr-CY": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cep numarası"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cep numarası"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cep telefonu numaranızı uluslararası formatta girin."])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir telefon numarası giriniz."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir telefon numarası giriniz."])}
          },
          "invalidNumber": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir telefon numarası giriniz."])}
          }
        }
      }
    }
  })
}
