import * as components from './components';
import plugins from './plugins';
import composables from "./composables";

export default {
  install: (app) => {
    for (const componentName in components) {
      const component = components[componentName];

      app.component(component.name, component);
    }
    app.config.globalProperties.$dp = {...plugins}; //TODO: Legacy support
    app.config.globalProperties.$composables = {...composables};
    app.provide('composables', composables);
  }
}