export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de-DE": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuernummer hinzufügen"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig ab"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig ab"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig bis"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig bis"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigen Eintrag eingeben."])}
          }
        }
      },
      "de-AT": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuernummer hinzufügen"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig ab"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig ab"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig bis"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig bis"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigen Eintrag eingeben."])}
          }
        }
      },
      "de-CH": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuernummer hinzufügen"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig ab"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig ab"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig bis"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig bis"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigen Eintrag eingeben."])}
          }
        }
      },
      "en-US": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add tax number"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid from"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid from"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid until"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid until"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid entry."])}
          }
        }
      },
      "en-GB": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add tax number"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid from"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid from"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid until"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid until"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid entry."])}
          }
        }
      },
      "it-IT": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi codice fiscale"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valido dal"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valido dal"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valido fino a"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valido fino a"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di inserire una voce valida."])}
          }
        }
      },
      "fr-FR": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un numéro de taxe"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valide à partir de"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valide à partir de"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valable jusque"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valable jusque"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez une entrée valide."])}
          }
        }
      },
      "es-ES": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar número de impuestos"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válida desde"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válida desde"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válido hasta"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válido hasta"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese una entrada válida."])}
          }
        }
      },
      "pl-PL": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj numer podatkowy"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ważne od"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ważne od"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ważne do"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ważne do"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź prawidłowy wpis."])}
          }
        }
      },
      "nl-NL": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg btw-nummer toe"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldig vanaf"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldig vanaf"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldig tot"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldig tot"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer een geldige invoer in."])}
          }
        }
      },
      "bg-BG": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете данъчен номер"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валиден от"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валиден от"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bалидно до"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bалидно до"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете валиден запис."])}
          }
        }
      },
      "bs-BA": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodajte porezni broj"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijedi od"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijedi od"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijedi do"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijedi do"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite valjani unos."])}
          }
        }
      },
      "zh-CN": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加税号"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效期自"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效期自"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效期至"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效期至"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入有效的条目。"])}
          }
        }
      },
      "da-DK": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj skattenummer"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gældende fra"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gældende fra"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyldig indtil"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyldig indtil"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast en gyldig post."])}
          }
        }
      },
      "et-EE": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisage maksunumber"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehtib alates"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehtib alates"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehtiv kuni"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehtiv kuni"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage kehtiv kirje."])}
          }
        }
      },
      "fi-FI": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää veronumero"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voimassa"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voimassa"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voimassa asti"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voimassa asti"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna kelvollinen merkintä."])}
          }
        }
      },
      "el-GR": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη αριθμού φόρου"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ισχύει από"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ισχύει από"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγκυρο μέχρι"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγκυρο μέχρι"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε μια έγκυρη καταχώριση."])}
          }
        }
      },
      "he-IL": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הוסף מספר מס"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בתוקף מ"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בתוקף מ"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בתוקף עד"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בתוקף עד"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אנא הזן ערך חוקי."])}
          }
        }
      },
      "ga-IE": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuir uimhir chánach leis"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bailí ó"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bailí ó"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bailí go dtí"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bailí go dtí"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iontráil iontráil bhailí."])}
          }
        }
      },
      "ja-JP": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税番号を追加"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["から有効"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["から有効"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まで有効"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まで有効"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なエントリを入力してください。"])}
          }
        }
      },
      "hr-HR": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodajte porezni broj"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijedi od"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijedi od"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijedi do"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijedi do"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite valjani unos."])}
          }
        }
      },
      "lv-LV": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pievienojiet nodokļu numuru"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derīgs no"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derīgs no"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derīgs līdz"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derīgs līdz"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ievadiet derīgu ierakstu."])}
          }
        }
      },
      "lt-LT": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridėkite mokesčių numerį"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galioja nuo"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galioja nuo"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galioja iki"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galioja iki"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite tinkamą įrašą."])}
          }
        }
      },
      "pt-PT": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar número fiscal"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válido de"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válido de"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válido até"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válido até"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira uma entrada válida."])}
          }
        }
      },
      "ro-RO": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați un număr de impozitare"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valabil din"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valabil din"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valabil până la"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valabil până la"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă rugăm să introduceți o intrare validă."])}
          }
        }
      },
      "ru-RU": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить налоговый номер"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действует с"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действует с"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["действительна до"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["действительна до"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите действительную запись."])}
          }
        }
      },
      "sv-SE": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till skattenummer"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giltig från"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giltig från"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giltig till"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giltig till"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange en giltig post."])}
          }
        }
      },
      "sk-SK": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridajte daňové číslo"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platnosť od"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platnosť od"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platné do"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platné do"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte platný údaj."])}
          }
        }
      },
      "cs-CZ": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte daňové číslo"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platnost od"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platnost od"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V platnosti, dokud"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V platnosti, dokud"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte prosím platnou položku."])}
          }
        }
      },
      "tr-TR": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi numarası ekleyin"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kadar geçerli"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kadar geçerli"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son kullanma tarihi"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son kullanma tarihi"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli bir giriş girin."])}
          }
        }
      },
      "hu-HU": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg az adószámot"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Től érvényes"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Től érvényes"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ig érvényes"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ig érvényes"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon meg egy érvényes bejegyzést."])}
          }
        }
      },
      "ar-AE": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف الرقم الضريبي"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صالح من تاريخ"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صالح من تاريخ"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صالح حتى"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صالح حتى"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل إدخالاً صالحًا."])}
          }
        }
      },
      "fr-BE": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un numéro de taxe"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valide à partir de"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valide à partir de"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valable jusque"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valable jusque"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez une entrée valide."])}
          }
        }
      },
      "fr-LU": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un numéro de taxe"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valide à partir de"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valide à partir de"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valable jusque"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valable jusque"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez une entrée valide."])}
          }
        }
      },
      "mt-MT": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żid in-numru tat-taxxa"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validu minn"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validu minn"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validu permezz"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validu permezz"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daħħal dħul validu."])}
          }
        }
      },
      "sl-SI": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodajte davčno številko"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velja od"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velja od"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veljavno do"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veljavno do"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnesite veljaven vnos."])}
          }
        }
      },
      "el-CY": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη αριθμού φόρου"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ισχύει από"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ισχύει από"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγκυρο μέχρι"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγκυρο μέχρι"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε μια έγκυρη καταχώριση."])}
          }
        }
      },
      "tr-CY": {
        "addTaxId": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi numarası ekleyin"])}
        },
        "input": {
          "validFrom": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kadar geçerli"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kadar geçerli"])}
          },
          "validThrough": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son kullanma tarihi"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son kullanma tarihi"])}
          }
        },
        "inputValidation": {
          "emptyPeriod": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli bir giriş girin."])}
          }
        }
      }
    }
  })
}
