import dayjs from "./dayjs";
import mapDayJSLocale from "./mapDayJSLocale";
import mapNumeralLocale from "./mapNumeralLocale";
import mapSpCountryToFlag from "./mapSpCountryToFlag";
import formatCurrency from "./formatCurrency";
import formatPercentage from "./formatPercentage";

import hasValidCountryCallingCode  from './utilities/mobileNumber/hasValidCountryCallingCode';
import isValidNumber  from './utilities/mobileNumber/isValidNumber';
import trimNumber  from './utilities/mobileNumber/trimNumber';

import log from './log';

const utilities = {
  mobileNumber: {
      hasValidCountryCallingCode,
      isValidNumber,
      trimNumber
  }
}

export default {
    dayjs,
    mapDayJSLocale,
    mapNumeralLocale,
    mapSpCountryToFlag,
    formatCurrency,
    formatPercentage,
    utilities,
    log
}