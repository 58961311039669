export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de-DE": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum auswählen"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum auswählen"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cl.components.Inputs.DpDatePickerCustomOp.input.label"])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib ein gültiges Datum ein."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["am"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["am oder vor"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["am oder nach"])}
          }
        }
      },
      "de-AT": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum auswählen"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum auswählen"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib ein gültiges Datum ein."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["am"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["am oder vor"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["am oder nach"])}
          }
        }
      },
      "de-CH": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum auswählen"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum auswählen"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib ein gültiges Datum ein."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["am"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["am oder vor"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["am oder nach"])}
          }
        }
      },
      "en-US": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a date"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a date"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid date."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on or before"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on or after"])}
          }
        }
      },
      "en-GB": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a date"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a date"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid date."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on or before"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on or after"])}
          }
        }
      },
      "it-IT": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli una data"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli una data"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci una data valida."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sopra"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prima o poi"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["su o dopo"])}
          }
        }
      },
      "fr-FR": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la date"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la date"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer une date valide."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le ou avant"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le ou après"])}
          }
        }
      },
      "es-ES": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige una fecha"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige una fecha"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor introduzca una fecha valida."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en o antes"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sobre o después"])}
          }
        }
      },
      "pl-PL": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz datę"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz datę"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proszę wprowadź poprawną datę."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["w dniu lub wcześniej"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["w dniu lub po"])}
          }
        }
      },
      "nl-NL": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een datum"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een datum"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul alstublieft een geldige datum in."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aan"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["op of voor"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["op of na"])}
          }
        }
      },
      "bg-BG": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете дата"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете дата"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моля, въведете валидна дата."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hа"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на или преди"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на или след"])}
          }
        }
      },
      "bs-BA": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite datum"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite datum"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite valjani datum."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na ili pre"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na ili posle"])}
          }
        }
      },
      "zh-CN": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择日期"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择日期"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入一个有效的日期。"])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["или раньше"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或之后"])}
          }
        }
      },
      "da-DK": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg dato"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg dato"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast venligst en gyldig dato."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["på"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["på eller før"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["på eller efter"])}
          }
        }
      },
      "et-EE": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valige kuupäev"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valige kuupäev"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage kehtiv kuupäev."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["peal"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enne või enne"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sisse või pärast"])}
          }
        }
      },
      "fi-FI": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse päivämäärä"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse päivämäärä"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna kelvollinen päivämäärä."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["päällä"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ennen tai ennen"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["päällä tai sen jälkeen"])}
          }
        }
      },
      "el-GR": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε μια ημερομηνία"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε μια ημερομηνία"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλώ εισάγετε μία έγκυρη ημερομηνία."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["επί"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["στις ή πριν από"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["μετά ή μετά"])}
          }
        }
      },
      "he-IL": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בחר תאריך"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בחר תאריך"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["נא להזין תאריך חוקי."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["עַל"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בזמן או לפני"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["על או אחרי"])}
          }
        }
      },
      "ga-IE": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roghnaigh dáta"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roghnaigh dáta"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iontráil dáta bailí le do thoil."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ar"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ar nó roimhe"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ar nó ina dhiaidh"])}
          }
        }
      },
      "ja-JP": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付を選択してください"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付を選択してください"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効な日付を入力してください。"])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オン"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期日またはそれ以前"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以降"])}
          }
        }
      },
      "hr-HR": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite datum"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite datum"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite valjani datum."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na ili prije"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na ili poslije"])}
          }
        }
      },
      "lv-LV": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties datumu"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties datumu"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lūdzu, ievadiet derīgu datumu."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ieslēgts"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uz vai pirms tam"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uz vai pēc"])}
          }
        }
      },
      "lt-LT": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkite datą"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkite datą"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite galiojančią datą."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["įjungta"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prieš arba prieš"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["įjungtas arba po jo"])}
          }
        }
      },
      "pt-PT": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha uma data"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha uma data"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor insira uma data válida."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["em"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["em ou antes"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["em ou depois"])}
          }
        }
      },
      "ro-RO": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alegeți o dată"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alegeți o dată"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă rugăm să introduceți o dată validă."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe sau înainte"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe sau după"])}
          }
        }
      },
      "ru-RU": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите дату"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите дату"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите правильную дату."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["или раньше"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на или после"])}
          }
        }
      },
      "sv-SE": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj ett datum"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj ett datum"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vänligen ange ett giltigt datum."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["på"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["på eller innan"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["på eller efter"])}
          }
        }
      },
      "sk-SK": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte dátum"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte dátum"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte platný dátum."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na alebo skôr"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["po alebo po"])}
          }
        }
      },
      "cs-CZ": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte datum"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte datum"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím vložte správné datum."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na nebo před"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na nebo po"])}
          }
        }
      },
      "tr-TR": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarih seçin"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarih seçin"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir tarih giriniz."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["üzerinde"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["önce veya önce"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzerinde veya sonrasında"])}
          }
        }
      },
      "hu-HU": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassza ki a dátumot"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassza ki a dátumot"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, adjon meg egy érvényes dátumot."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tovább"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rajta vagy előtte"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["be vagy után"])}
          }
        }
      },
      "ar-AE": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر التاريخ"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر التاريخ"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارجوك ادخل تاريخ صحيح."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["على"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في أو قبل"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في أو بعد"])}
          }
        }
      },
      "fr-BE": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la date"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la date"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer une date valide."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le ou avant"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le ou après"])}
          }
        }
      },
      "fr-LU": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la date"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la date"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer une date valide."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le ou avant"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le ou après"])}
          }
        }
      },
      "mt-MT": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agħżel id-data"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["operator - Agħżel id-data"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jekk jogħġbok daħħal data valida."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fil -"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fuq jew qabel"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fuq jew wara"])}
          }
        }
      },
      "sl-SI": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izberite datum"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izberite datum"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnesite veljaven datum."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["naprej"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na ali pred"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ob ali pozneje"])}
          }
        }
      },
      "el-CY": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε μια ημερομηνία"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε μια ημερομηνία"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλώ εισάγετε μία έγκυρη ημερομηνία."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["επί"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["στις ή πριν από"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["μετά ή μετά"])}
          }
        }
      },
      "tr-CY": {
        "input": {
          "date": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarih seçin"])}
          },
          "operator": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarih seçin"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir tarih giriniz."])}
          }
        },
        "operators": {
          "on": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["üzerinde"])}
          },
          "onOrBefore": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["önce veya önce"])}
          },
          "onOrAfter": {
            "selectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzerinde veya sonrasında"])}
          }
        }
      }
    }
  })
}
