<script setup>
import { inject, computed, watch, defineProps, defineEmits, onBeforeMount, onMounted } from 'vue';
const composables = inject('composables');
const { isRTL } = composables.useJurisdictionSwitch()
import dayjs from 'dayjs'

const props = defineProps({
  modelValue: {required: true},
  required: {type: Boolean, default: false},
  disableEndDate: {type: Boolean, default: true},
  tooltip: {type: String, default: undefined},
  description: {type: String, default: undefined},
  label: {type: String},
  inputParameters: {type: Array}
})
const emit = defineEmits(['update', 'input', 'load'])
const input = composables.useTwoWayBinding(props, emit, 'modelValue')

import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const { locale } = useI18n({useScope: 'global'})
// eslint-disable-next-line no-unused-vars
const translationReportAlias = {
  tooltip: t('input.tooltip'),
  description: t('input.description')
};

function validate(rule, value, callback) {
  if (props.required !== true) return callback();
  if (value === [null, null] || value === ['', '']) {
    callback(new Error(t('inputValidation.emptyField.message')));
  } else {
    callback();
  }
}
function emitValue() {
  emit("input", input.value);
}

import { isEmpty, get } from 'lodash';
import {useEnvStore} from "@dutypay/store-modules";
const envStore = useEnvStore();
const hasTooltip = computed(() => {
  return !isEmpty(props.tooltip)
})
const hasDescription = computed(() => {
  return !isEmpty(props.description)
})
const formInputLabel = computed(() => {
  return (props.label) ? props.label : t('input.label');
});
const clearable = computed(() => {
  return [
    get(props.inputParameters, '0.clearable', (dayjs(input.value[0]).isValid() && !(props.disableEndDate && input.value[1]))),
    get(props.inputParameters, '1.clearable', dayjs(input.value[1]).isValid())
  ];
})
const defaultValue = computed(() => {
  return [
    get(props.inputParameters, '0.defaultValue', new Date()),
    get(props.inputParameters, '1.defaultValue',dayjs(input.value[0]).isValid() ? new Date(input.value[0]) : new Date())
  ];
})
const pickerOptions = computed(() => {
  return [
    get(props.inputParameters, '0.pickerOptions',{
      disabledDate: date => {
        return (input.value[1] !== null && input.value[1] !== '') ? (dayjs.utc(date) > dayjs.utc(input.value[1])) : false
      }
    }),
    get(props.inputParameters, '1.pickerOptions',{
      disabledDate: date => {
        return (input.value[0] !== null && input.value[0] !== '') ? (dayjs.utc(date) < dayjs.utc(input.value[0])) : false
      }
    })
  ];
})
const disabled = computed(() => {
  return [
    get(props.inputParameters, '0.disabled', false),
    get(props.inputParameters, '1.disabled', (props.disableEndDate && !dayjs(input.value[0]).isValid()))
  ];
})
const format = computed(() => {
  return [
    get(props.inputParameters, '0.format', envStore.dateFormat[locale.value]),
    get(props.inputParameters, '1.format', envStore.dateFormat[locale.value])
  ];
})
const placeholder = computed(() => {
  return [
    get(props.inputParameters, '0.placeholder', t('input.startDate.placeholder')),
    get(props.inputParameters, '1.placeholder', t('input.endDate.placeholder'))
  ];
})

watch(input, () => {
  if (!props.disableEndDate) return;
  if (isEmpty(input.value[0])) {
    input.value[1] = null;
  }
});

onBeforeMount(() => {
  emit('load', formInputLabel.value)
});
onMounted(() => {
  input.vaule = isEmpty(props.modelValue) ? [null, null] : props.modelValue;
  emitValue();
});
</script>
<script>
export default {
  name: "DpDatePickerRange"
}
</script>

<template>

  <el-form-item
      :class="{'is-required': required, 'right-to-left': isRTL}"
      v-bind="$attrs"
      :label="formInputLabel"
      :rules="[
        { validator: validate, trigger: ['blur', 'change'] }
      ]">

    <template #label>
      <span>{{formInputLabel}}</span>
      <span v-if="hasTooltip" class="form-item__description"><i class="el-icon-info"/> {{tooltip}}</span>
      <span v-if="hasDescription" class="form-item__description"><i class="el-icon-info"/> {{description}}</span>
    </template>

    <el-row>
      <el-col :span="12">
        <span>{{ t('input.startDate.label') }}</span>
        <el-date-picker
            v-model="input[0]"
            v-bind="get(inputParameters,'0')"
            type="date"
            :format="format[0]"
            class="dp-input__grouped__left"
            style="width: 100%"
            :clearable="clearable[0]"
            :default-value="defaultValue[0]"
            :picker-options="pickerOptions[0]"
            :placeholder="placeholder[0]"
            :disabled="disabled[0]">
        </el-date-picker>
      </el-col>
      <el-col :span="12">
        <span>{{ t('input.endDate.label') }}</span>
        <el-date-picker
            v-model="input[1]"
            v-bind="get(inputParameters,'1')"
            type="date"
            :format="format[1]"
            class="dp-input__grouped__right"
            style="width: 100%"
            :clearable="clearable[1]"
            :default-value="defaultValue[1]"
            :placeholder="placeholder[1]"
            :picker-options="pickerOptions[1]"
            :disabled="disabled[1]">
        </el-date-picker>
      </el-col>
    </el-row>

  </el-form-item>

</template>

<i18n>
{
  "de-DE": {
    "input": {
      "endDate": {
        "placeholder": "Enddatum auswählen",
        "label": "Enddatum"
      },
      "startDate": {
        "placeholder": "Anfangsdatum auswählen",
        "label": "Anfangsdatum"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Gültigen Eintrag eingeben."
      }
    }
  },
  "de-AT": {
    "input": {
      "endDate": {
        "placeholder": "Enddatum auswählen",
        "label": "Enddatum"
      },
      "startDate": {
        "placeholder": "Anfangsdatum auswählen",
        "label": "Anfangsdatum"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Gültigen Eintrag eingeben."
      }
    }
  },
  "de-CH": {
    "input": {
      "endDate": {
        "placeholder": "Enddatum auswählen",
        "label": "Enddatum"
      },
      "startDate": {
        "placeholder": "Anfangsdatum auswählen",
        "label": "Anfangsdatum"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Gültigen Eintrag eingeben."
      }
    }
  },
  "en-US": {
    "input": {
      "endDate": {
        "placeholder": "Choose end date",
        "label": "End date"
      },
      "startDate": {
        "placeholder": "Choose start date",
        "label": "Start date"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Enter a valid entry."
      }
    }
  },
  "en-GB": {
    "input": {
      "endDate": {
        "placeholder": "Choose end date",
        "label": "End date"
      },
      "startDate": {
        "placeholder": "Choose start date",
        "label": "Start date"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Enter a valid entry."
      }
    }
  },
  "it-IT": {
    "input": {
      "endDate": {
        "placeholder": "Scegli la data di fine",
        "label": "Data di fine"
      },
      "startDate": {
        "placeholder": "Scegli la data di inizio",
        "label": "Data d'inizio"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Si prega di inserire una voce valida."
      }
    }
  },
  "fr-FR": {
    "input": {
      "endDate": {
        "placeholder": "Choisissez la date de fin",
        "label": "Date de fin"
      },
      "startDate": {
        "placeholder": "Choisissez la date de début",
        "label": "Date de début"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Saisissez une entrée valide."
      }
    }
  },
  "es-ES": {
    "input": {
      "endDate": {
        "placeholder": "Elija la fecha de finalización",
        "label": "Fecha final"
      },
      "startDate": {
        "placeholder": "Elija la fecha de inicio",
        "label": "Fecha de inicio"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Ingrese una entrada válida."
      }
    }
  },
  "pl-PL": {
    "input": {
      "endDate": {
        "placeholder": "Wybierz datę zakończenia",
        "label": "Data końcowa"
      },
      "startDate": {
        "placeholder": "Wybierz datę rozpoczęcia",
        "label": "Data rozpoczęcia"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Wprowadź prawidłowy wpis."
      }
    }
  },
  "nl-NL": {
    "input": {
      "endDate": {
        "placeholder": "Kies een einddatum",
        "label": "Einddatum"
      },
      "startDate": {
        "placeholder": "Kies startdatum",
        "label": "Startdatum"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Voer een geldige invoer in."
      }
    }
  },
  "bg-BG": {
    "input": {
      "endDate": {
        "placeholder": "Изберете крайна дата",
        "label": "Крайна дата"
      },
      "startDate": {
        "placeholder": "Изберете начална дата",
        "label": "Начална дата"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Въведете валиден запис."
      }
    }
  },
  "bs-BA": {
    "input": {
      "endDate": {
        "placeholder": "Odaberite datum završetka",
        "label": "Datum završetka"
      },
      "startDate": {
        "placeholder": "Odaberite datum početka",
        "label": "Datum početka"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Unesite valjani unos."
      }
    }
  },
  "zh-CN": {
    "input": {
      "endDate": {
        "placeholder": "选择结束日期",
        "label": "结束日期"
      },
      "startDate": {
        "placeholder": "选择开始日期",
        "label": "开始日期"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "输入有效的条目。"
      }
    }
  },
  "da-DK": {
    "input": {
      "endDate": {
        "placeholder": "Vælg slutdato",
        "label": "Slutdato"
      },
      "startDate": {
        "placeholder": "Vælg startdato",
        "label": "Start dato"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Indtast en gyldig post."
      }
    }
  },
  "et-EE": {
    "input": {
      "endDate": {
        "placeholder": "Valige lõppkuupäev",
        "label": "Lõppkuupäev"
      },
      "startDate": {
        "placeholder": "Valige alguskuupäev",
        "label": "Algus kuupäev"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Sisestage kehtiv kirje."
      }
    }
  },
  "fi-FI": {
    "input": {
      "endDate": {
        "placeholder": "Valitse lopetuspäivä",
        "label": "Päättymispäivä"
      },
      "startDate": {
        "placeholder": "Valitse aloituspäivä",
        "label": "Aloituspäivämäärä"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Anna kelvollinen merkintä."
      }
    }
  },
  "el-GR": {
    "input": {
      "endDate": {
        "placeholder": "Επιλέξτε ημερομηνία λήξης",
        "label": "Ημερομηνία λήξης"
      },
      "startDate": {
        "placeholder": "Επιλέξτε ημερομηνία έναρξης",
        "label": "Ημερομηνία έναρξης"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Εισαγάγετε μια έγκυρη καταχώριση."
      }
    }
  },
  "he-IL": {
    "input": {
      "endDate": {
        "placeholder": "בחר תאריך סיום",
        "label": "תאריך סיום"
      },
      "startDate": {
        "placeholder": "בחר תאריך התחלה",
        "label": "תאריך התחלה"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "אנא הזן ערך חוקי."
      }
    }
  },
  "ga-IE": {
    "input": {
      "endDate": {
        "placeholder": "Roghnaigh an dáta deiridh",
        "label": "Dáta deiridh"
      },
      "startDate": {
        "placeholder": "Roghnaigh an dáta tosaigh",
        "label": "Dáta tosaigh"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Iontráil iontráil bhailí."
      }
    }
  },
  "ja-JP": {
    "input": {
      "endDate": {
        "placeholder": "終了日を選択してください",
        "label": "終了日"
      },
      "startDate": {
        "placeholder": "開始日を選択してください",
        "label": "開始日"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "有効なエントリを入力してください。"
      }
    }
  },
  "hr-HR": {
    "input": {
      "endDate": {
        "placeholder": "Odaberite datum završetka",
        "label": "Datum završetka"
      },
      "startDate": {
        "placeholder": "Odaberite datum početka",
        "label": "Početni datum"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Unesite valjani unos."
      }
    }
  },
  "lv-LV": {
    "input": {
      "endDate": {
        "placeholder": "Izvēlieties beigu datumu",
        "label": "Beigu datums"
      },
      "startDate": {
        "placeholder": "Izvēlieties sākuma datumu",
        "label": "Sākuma datums"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Ievadiet derīgu ierakstu."
      }
    }
  },
  "lt-LT": {
    "input": {
      "endDate": {
        "placeholder": "Pasirinkite pabaigos datą",
        "label": "Pabaigos data"
      },
      "startDate": {
        "placeholder": "Pasirinkite pradžios datą",
        "label": "Pradžios data"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Įveskite tinkamą įrašą."
      }
    }
  },
  "pt-PT": {
    "input": {
      "endDate": {
        "placeholder": "Escolha a data de término",
        "label": "Data final"
      },
      "startDate": {
        "placeholder": "Escolha a data de início",
        "label": "Data de início"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Insira uma entrada válida."
      }
    }
  },
  "ro-RO": {
    "input": {
      "endDate": {
        "placeholder": "Alegeți data de încheiere",
        "label": "Data de încheiere"
      },
      "startDate": {
        "placeholder": "Alegeți data de începere",
        "label": "Data de început"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Vă rugăm să introduceți o intrare validă."
      }
    }
  },
  "ru-RU": {
    "input": {
      "endDate": {
        "placeholder": "Выберите дату окончания",
        "label": "Дата окончания"
      },
      "startDate": {
        "placeholder": "Выберите дату начала",
        "label": "Дата начала"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Введите действительную запись."
      }
    }
  },
  "sv-SE": {
    "input": {
      "endDate": {
        "placeholder": "Välj slutdatum",
        "label": "Slutdatum"
      },
      "startDate": {
        "placeholder": "Välj startdatum",
        "label": "Start datum"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Ange en giltig post."
      }
    }
  },
  "sk-SK": {
    "input": {
      "endDate": {
        "placeholder": "Vyberte dátum ukončenia",
        "label": "Dátum ukončenia"
      },
      "startDate": {
        "placeholder": "Vyberte dátum začatia",
        "label": "Dátum začiatku"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Zadajte platný údaj."
      }
    }
  },
  "cs-CZ": {
    "input": {
      "endDate": {
        "placeholder": "Vyberte datum ukončení",
        "label": "Datum ukončení"
      },
      "startDate": {
        "placeholder": "Vyberte datum zahájení",
        "label": "Počáteční datum"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Zadejte prosím platnou položku."
      }
    }
  },
  "tr-TR": {
    "input": {
      "endDate": {
        "placeholder": "Bitiş tarihini seçin",
        "label": "Bitiş tarihi"
      },
      "startDate": {
        "placeholder": "Başlangıç tarihini seçin",
        "label": "Başlangıç tarihi"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Geçerli bir giriş girin."
      }
    }
  },
  "hu-HU": {
    "input": {
      "endDate": {
        "placeholder": "Válassza ki a befejezés dátumát",
        "label": "Befejezés dátuma"
      },
      "startDate": {
        "placeholder": "Válassza ki a kezdő dátumot",
        "label": "Kezdő dátum"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Adjon meg egy érvényes bejegyzést."
      }
    }
  },
  "ar-AE": {
    "input": {
      "endDate": {
        "placeholder": "اختر تاريخ الانتهاء",
        "label": "تاريخ الانتهاء"
      },
      "startDate": {
        "placeholder": "اختر تاريخ البدء",
        "label": "تاريخ البدء"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "أدخل إدخالاً صالحًا."
      }
    }
  },
  "fr-BE": {
    "input": {
      "endDate": {
        "placeholder": "Choisissez la date de fin",
        "label": "Date de fin"
      },
      "startDate": {
        "placeholder": "Choisissez la date de début",
        "label": "Date de début"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Saisissez une entrée valide."
      }
    }
  },
  "fr-LU": {
    "input": {
      "endDate": {
        "placeholder": "Choisissez la date de fin",
        "label": "Date de fin"
      },
      "startDate": {
        "placeholder": "Choisissez la date de début",
        "label": "Date de début"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Saisissez une entrée valide."
      }
    }
  },
  "mt-MT": {
    "input": {
      "endDate": {
        "placeholder": "Agħżel id-data tat-tmiem",
        "label": "Data tat-tmiem"
      },
      "startDate": {
        "placeholder": "Agħżel id-data tal-bidu",
        "label": "Id-data tal-bidu"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Daħħal dħul validu."
      }
    }
  },
  "sl-SI": {
    "input": {
      "endDate": {
        "placeholder": "Izberite končni datum",
        "label": "Končni datum"
      },
      "startDate": {
        "placeholder": "Izberite datum začetka",
        "label": "Začetni datum"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Vnesite veljaven vnos."
      }
    }
  },
  "el-CY": {
    "input": {
      "endDate": {
        "placeholder": "Επιλέξτε ημερομηνία λήξης",
        "label": "Ημερομηνία λήξης"
      },
      "startDate": {
        "placeholder": "Επιλέξτε ημερομηνία έναρξης",
        "label": "Ημερομηνία έναρξης"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Εισαγάγετε μια έγκυρη καταχώριση."
      }
    }
  },
  "tr-CY": {
    "input": {
      "endDate": {
        "placeholder": "Bitiş tarihini seçin",
        "label": "Bitiş tarihi"
      },
      "startDate": {
        "placeholder": "Başlangıç tarihini seçin",
        "label": "Başlangıç tarihi"
      }
    },
    "inputValidation": {
      "emptyField": {
        "message": "Geçerli bir giriş girin."
      }
    }
  }
}
</i18n>

