import {useTrimNumber} from "./trimNumber";

export function useIsValidNumber (number) {
  let trimmedNumber = useTrimNumber(number).replace('+', '');
  if (trimmedNumber[0] === '0') {
    return false;
  }
  return /\d/g.test(trimmedNumber)
}

