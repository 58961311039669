<script setup>
import startsWith from 'lodash/startsWith';
import isEmpty from 'lodash/isEmpty';
import each from 'lodash/each';
import { inject, ref, watch, defineProps, defineEmits, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n'
const composables = inject('composables');
const { t, locale } = useI18n()
// eslint-disable-next-line no-unused-vars
const fallbackTranslation = {
  label: t('input.label'),
  placeholder: t('input.placeholder'),
  tooltip: t('input.tooltip')
}

const props = defineProps({
  modelValue: {required: true},
  required: {type: Boolean, default: false},
  tooltip: {type: String, default: undefined},
  description: {type: String, default: undefined},
  label: {type: String},
  placeholder: {type: String},
  inputParameters: {type: Object}
})
const inputLabel = (props.label) ? props.label : fallbackTranslation.label
const placeholder = (props.placeholder) ? props.placeholder : fallbackTranslation.placeholder

const emit = defineEmits(['update', 'load'])
const input = composables.useTwoWayBinding(props, emit, 'modelValue')
const { jurisdictionList, getJurisdictionFromLocalStorage} = composables.useJurisdictionSwitch();

import {useIsValidNumber} from "../../composables/mobileNumber/isValidNumber";
const isValidNumber = useIsValidNumber
import {useHasValidCountryCallingCode} from "../../composables/mobileNumber/hasValidCountryCallingCode";
const hasValidCountryCallingCode = useHasValidCountryCallingCode
function validate(rule, value, callback) {
  if (props.required !== true) return callback();
  if (isEmpty(value)) {
    callback(new Error(t('inputValidation.emptyField.message')));
  } else if (!isValidNumber(value)
      || !hasValidCountryCallingCode(jurisdictionList, value)) {
    callback(new Error(t('inputValidation.invalidNumber.message')));
  } else {
    callback();
  }
}

function setFlagFromInput() {
  if (input.value.length > 5)
    return;
  each(jurisdictionList, (jurisdiction) => {
    if (startsWith(input.value, jurisdiction.callingCode)) {
      flagISOCode.value = jurisdiction.flagISOCode;
    }
  });
}
const flagISOCode = ref('')

function setJurisdictionFromStore() {
  flagISOCode.value = getJurisdictionFromLocalStorage.flagISOCode;
  input.value = getJurisdictionFromLocalStorage.callingCode;
}

onBeforeMount(() => {
  setJurisdictionFromStore()
})

watch(locale, () => {
  setJurisdictionFromStore()
})
</script>
<script>
export default {
  name: "DpInputMobileNumber"
}
</script>

<template>
  <div>
    <el-form-item
        :class="{'is-required': required}"
        v-bind="$attrs"
        :label="inputLabel"
        :rules="[
        { validator: validate, trigger: ['blur', 'change'] }
      ]">

      <template #label>
        <span>{{inputLabel}}</span>
        <span v-if="!isEmpty(tooltip)" class="form-item__description"><i class="el-icon-info"/> {{tooltip}}</span>
        <span v-if="!isEmpty(description)" class="form-item__description"><i class="el-icon-info"/> {{description}}</span>
      </template>

      <el-input
          v-model="input"
          v-bind="inputParameters"
          autocorrect="off"
          v-on:input="setFlagFromInput"
          autocapitalize="none"
          :placeholder="placeholder">
        <template #prefix>
          <DpFlag class="dp-flag-icon" :squared="false" :flagISOCode="flagISOCode"/>
        </template>
      </el-input>
    </el-form-item>
  </div>
</template>

<i18n>
{
  "de-DE": {
    "input": {
      "placeholder": "Mobilnummer",
      "label": "Mobilnummer",
      "tooltip": "Gib deine Mobilnummer im internationalen Format ein."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Bitte gib eine gültige Mobilnummer ein."
      },
      "regEx": {
        "message": "Bitte gib eine gültige Mobilnummer ein."
      },
      "invalidNumber": {
        "message": "Bitte gib eine gültige Mobilnummer ein."
      }
    }
  },
  "de-AT": {
    "input": {
      "placeholder": "Mobilnummer",
      "label": "Mobilnummer",
      "tooltip": "Gib deine Mobilnummer im internationalen Format ein."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Bitte gib eine gültige Mobilnummer ein."
      },
      "regEx": {
        "message": "Bitte gib eine gültige Mobilnummer ein."
      },
      "invalidNumber": {
        "message": "Bitte gib eine gültige Mobilnummer ein."
      }
    }
  },
  "de-CH": {
    "input": {
      "placeholder": "Mobilnummer",
      "label": "Mobilnummer",
      "tooltip": "Gib deine Mobilnummer im internationalen Format ein."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Bitte gib eine gültige Mobilnummer ein."
      },
      "regEx": {
        "message": "Bitte gib eine gültige Mobilnummer ein."
      },
      "invalidNumber": {
        "message": "Bitte gib eine gültige Mobilnummer ein."
      }
    }
  },
  "en-US": {
    "input": {
      "placeholder": "Mobile Number",
      "label": "Mobile Number",
      "tooltip": "Enter your mobile number in international format."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Please enter a valid mobile number."
      },
      "regEx": {
        "message": "Please enter a valid mobile number."
      },
      "invalidNumber": {
        "message": "Please enter a valid mobile number."
      }
    }
  },
  "en-GB": {
    "input": {
      "placeholder": "Mobile Number",
      "label": "Mobile Number",
      "tooltip": "Enter your mobile number in international format."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Please enter a valid mobile number."
      },
      "regEx": {
        "message": "Please enter a valid mobile number."
      },
      "invalidNumber": {
        "message": "Please enter a valid mobile number."
      }
    }
  },
  "it-IT": {
    "input": {
      "placeholder": "Numero di telefono",
      "label": "Numero di telefono",
      "tooltip": "Inserisci il tuo numero di cellulare in formato internazionale."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Inserisci un numero di telefono valido."
      },
      "regEx": {
        "message": "Inserisci un numero di cellulare valido."
      },
      "invalidNumber": {
        "message": "Inserisci un numero di telefono valido."
      }
    }
  },
  "fr-FR": {
    "input": {
      "placeholder": "Numéro de téléphone mobile",
      "label": "Numéro de téléphone mobile",
      "tooltip": "Entrez votre numéro de portable au format international."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Veuillez saisir un numéro de téléphone portable valide."
      },
      "regEx": {
        "message": "Veuillez saisir un numéro de mobile valide."
      },
      "invalidNumber": {
        "message": "Veuillez saisir un numéro de téléphone portable valide."
      }
    }
  },
  "es-ES": {
    "input": {
      "placeholder": "Número de teléfono",
      "label": "Número de teléfono",
      "tooltip": "Ingrese su número de teléfono móvil en formato internacional."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Por favor introduzca un número de teléfono válido."
      },
      "regEx": {
        "message": "Por favor, introduzca un número de móvil válido."
      },
      "invalidNumber": {
        "message": "Por favor introduzca un número de teléfono válido."
      }
    }
  },
  "pl-PL": {
    "input": {
      "placeholder": "Numer telefonu komórkowego",
      "label": "Numer telefonu komórkowego",
      "tooltip": "Wpisz swój numer telefonu komórkowego w formacie międzynarodowym."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Wprowadź prawidłowy numer telefonu komórkowego."
      },
      "regEx": {
        "message": "Wprowadź prawidłowy numer telefonu komórkowego."
      },
      "invalidNumber": {
        "message": "Wprowadź prawidłowy numer telefonu komórkowego."
      }
    }
  },
  "nl-NL": {
    "input": {
      "placeholder": "Mobiel nummer",
      "label": "Mobiel nummer",
      "tooltip": "Voer uw mobiele nummer in internationaal formaat in."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Voer een geldig mobiel nummer in."
      },
      "regEx": {
        "message": "Voer een geldig mobiel nummer in."
      },
      "invalidNumber": {
        "message": "Voer een geldig mobiel nummer in."
      }
    }
  },
  "bg-BG": {
    "input": {
      "placeholder": "Мобилен номер",
      "label": "Мобилен номер",
      "tooltip": "Въведете мобилния си номер в международен формат."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Моля, въведете валиден мобилен номер."
      },
      "regEx": {
        "message": "Моля, въведете валиден мобилен номер."
      },
      "invalidNumber": {
        "message": "Моля, въведете валиден мобилен номер."
      }
    }
  },
  "bs-BA": {
    "input": {
      "placeholder": "Broj mobitela",
      "label": "Broj mobitela",
      "tooltip": "Unesite svoj broj mobitela u međunarodnom formatu."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Unesite važeći broj mobitela."
      },
      "regEx": {
        "message": "Unesite važeći broj mobitela."
      },
      "invalidNumber": {
        "message": "Unesite važeći broj mobitela."
      }
    }
  },
  "zh-CN": {
    "input": {
      "placeholder": "手机号码",
      "label": "手机号码",
      "tooltip": "以国际格式输入您的手机号码。"
    },
    "inputValidation": {
      "emptyField": {
        "message": "请输入有效的手机号码。"
      },
      "regEx": {
        "message": "请输入有效的手机号码。"
      },
      "invalidNumber": {
        "message": "请输入有效的手机号码。"
      }
    }
  },
  "da-DK": {
    "input": {
      "placeholder": "Mobilnummer",
      "label": "Mobilnummer",
      "tooltip": "Indtast dit mobilnummer i internationalt format."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Indtast venligst et gyldigt mobilnummer."
      },
      "regEx": {
        "message": "Indtast venligst et gyldigt mobilnummer."
      },
      "invalidNumber": {
        "message": "Indtast venligst et gyldigt mobilnummer."
      }
    }
  },
  "et-EE": {
    "input": {
      "placeholder": "Mobiili number",
      "label": "Mobiili number",
      "tooltip": "Sisestage oma mobiiltelefoni number rahvusvahelises vormingus."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Sisestage kehtiv mobiilinumber."
      },
      "regEx": {
        "message": "Sisestage kehtiv mobiilinumber."
      },
      "invalidNumber": {
        "message": "Sisestage kehtiv mobiilinumber."
      }
    }
  },
  "fi-FI": {
    "input": {
      "placeholder": "Matkapuhelinnumero",
      "label": "Matkapuhelinnumero",
      "tooltip": "Anna matkapuhelinnumerosi kansainvälisessä muodossa."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Anna kelvollinen matkapuhelinnumero."
      },
      "regEx": {
        "message": "Anna kelvollinen matkapuhelinnumero."
      },
      "invalidNumber": {
        "message": "Anna kelvollinen matkapuhelinnumero."
      }
    }
  },
  "el-GR": {
    "input": {
      "placeholder": "Αριθμός κινητού",
      "label": "Αριθμός κινητού",
      "tooltip": "Εισαγάγετε τον αριθμό του κινητού σας σε διεθνή μορφή."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Εισαγάγετε έναν έγκυρο αριθμό κινητού."
      },
      "regEx": {
        "message": "Εισαγάγετε έναν έγκυρο αριθμό κινητού."
      },
      "invalidNumber": {
        "message": "Εισαγάγετε έναν έγκυρο αριθμό κινητού."
      }
    }
  },
  "he-IL": {
    "input": {
      "placeholder": "מספר טלפון נייד",
      "label": "מספר טלפון נייד",
      "tooltip": "הזן את מספר הנייד שלך בפורמט בינלאומי."
    },
    "inputValidation": {
      "emptyField": {
        "message": "אנא הזן מספר נייד תקף."
      },
      "regEx": {
        "message": "אנא הזן מספר נייד תקף."
      },
      "invalidNumber": {
        "message": "אנא הזן מספר נייד תקף."
      }
    }
  },
  "ga-IE": {
    "input": {
      "placeholder": "Uimhir fóin póca",
      "label": "Uimhir fóin póca",
      "tooltip": "Iontráil d’uimhir soghluaiste i bhformáid idirnáisiúnta."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Iontráil uimhir soghluaiste bhailí."
      },
      "regEx": {
        "message": "Iontráil uimhir soghluaiste bhailí."
      },
      "invalidNumber": {
        "message": "Iontráil uimhir soghluaiste bhailí."
      }
    }
  },
  "ja-JP": {
    "input": {
      "placeholder": "携帯電話番号",
      "label": "携帯電話番号",
      "tooltip": "携帯電話番号を国際形式で入力してください。"
    },
    "inputValidation": {
      "emptyField": {
        "message": "有効な携帯電話番号を入力してください。"
      },
      "regEx": {
        "message": "有効な携帯電話番号を入力してください。"
      },
      "invalidNumber": {
        "message": "有効な携帯電話番号を入力してください。"
      }
    }
  },
  "hr-HR": {
    "input": {
      "placeholder": "Broj mobitela",
      "label": "Broj mobitela",
      "tooltip": "Unesite svoj broj mobitela u međunarodnom formatu."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Unesite važeći broj mobitela."
      },
      "regEx": {
        "message": "Unesite važeći broj mobitela."
      },
      "invalidNumber": {
        "message": "Unesite važeći broj mobitela."
      }
    }
  },
  "lv-LV": {
    "input": {
      "placeholder": "Mobilā tālruņa numurs",
      "label": "Mobilā tālruņa numurs",
      "tooltip": "Ievadiet savu mobilā tālruņa numuru starptautiskā formātā."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Lūdzu, ievadiet derīgu mobilā tālruņa numuru."
      },
      "regEx": {
        "message": "Lūdzu, ievadiet derīgu mobilā tālruņa numuru."
      },
      "invalidNumber": {
        "message": "Lūdzu, ievadiet derīgu mobilā tālruņa numuru."
      }
    }
  },
  "lt-LT": {
    "input": {
      "placeholder": "Mobiliojo telefono numeris",
      "label": "Mobiliojo telefono numeris",
      "tooltip": "Įveskite savo mobiliojo telefono numerį tarptautiniu formatu."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Įveskite galiojantį mobiliojo telefono numerį."
      },
      "regEx": {
        "message": "Įveskite galiojantį mobiliojo telefono numerį."
      },
      "invalidNumber": {
        "message": "Įveskite galiojantį mobiliojo telefono numerį."
      }
    }
  },
  "pt-PT": {
    "input": {
      "placeholder": "Número de telemóvel",
      "label": "Número de telemóvel",
      "tooltip": "Digite seu número de celular no formato internacional."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Introduza um número de telemóvel válido."
      },
      "regEx": {
        "message": "Por favor insira um número de telemóvel válido."
      },
      "invalidNumber": {
        "message": "Introduza um número de telemóvel válido."
      }
    }
  },
  "ro-RO": {
    "input": {
      "placeholder": "Număr de telefon mobil",
      "label": "Număr de telefon mobil",
      "tooltip": "Introduceți numărul dvs. de mobil în format internațional."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Introduceți un număr de telefon mobil corect."
      },
      "regEx": {
        "message": "Vă rugăm să introduceți un număr de telefon mobil valid."
      },
      "invalidNumber": {
        "message": "Introduceți un număr de telefon mobil corect."
      }
    }
  },
  "ru-RU": {
    "input": {
      "placeholder": "Номер мобильного телефона",
      "label": "Номер мобильного телефона",
      "tooltip": "Введите номер своего мобильного телефона в международном формате."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Пожалуйста, введите действительный номер мобильного телефона."
      },
      "regEx": {
        "message": "Пожалуйста, введите действующий номер мобильного телефона."
      },
      "invalidNumber": {
        "message": "Пожалуйста, введите действительный номер мобильного телефона."
      }
    }
  },
  "sv-SE": {
    "input": {
      "placeholder": "Mobilnummer",
      "label": "Mobilnummer",
      "tooltip": "Ange ditt mobilnummer i internationellt format."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Ange giltigt telefonnummer."
      },
      "regEx": {
        "message": "Ange ett giltigt mobilnummer."
      },
      "invalidNumber": {
        "message": "Ange giltigt telefonnummer."
      }
    }
  },
  "sk-SK": {
    "input": {
      "placeholder": "Mobilné číslo",
      "label": "Mobilné číslo",
      "tooltip": "Zadajte svoje mobilné číslo v medzinárodnom formáte."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Zadajte prosím platné mobilné číslo."
      },
      "regEx": {
        "message": "Zadajte platné číslo mobilného telefónu."
      },
      "invalidNumber": {
        "message": "Zadajte prosím platné mobilné číslo."
      }
    }
  },
  "cs-CZ": {
    "input": {
      "placeholder": "Číslo mobilního telefonu",
      "label": "Číslo mobilního telefonu",
      "tooltip": "Zadejte své mobilní číslo v mezinárodním formátu."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Zadejte platné číslo mobilního telefonu."
      },
      "regEx": {
        "message": "Zadejte platné číslo mobilního telefonu."
      },
      "invalidNumber": {
        "message": "Zadejte platné číslo mobilního telefonu."
      }
    }
  },
  "tr-TR": {
    "input": {
      "placeholder": "Cep numarası",
      "label": "Cep numarası",
      "tooltip": "Cep telefonu numaranızı uluslararası formatta girin."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Lütfen geçerli bir telefon numarası giriniz."
      },
      "regEx": {
        "message": "Lütfen geçerli bir telefon numarası giriniz."
      },
      "invalidNumber": {
        "message": "Lütfen geçerli bir telefon numarası giriniz."
      }
    }
  },
  "hu-HU": {
    "input": {
      "placeholder": "Mobil szám",
      "label": "Mobil szám",
      "tooltip": "Írja be mobilszámát nemzetközi formátumban."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Kérjük, érvényes mobilszámot adjon meg."
      },
      "regEx": {
        "message": "Kérjük, adjon meg érvényes mobilszámot."
      },
      "invalidNumber": {
        "message": "Kérjük, érvényes mobilszámot adjon meg."
      }
    }
  },
  "ar-AE": {
    "input": {
      "placeholder": "رقم الهاتف المحمول",
      "label": "رقم الهاتف المحمول",
      "tooltip": "أدخل رقم هاتفك المحمول بصيغة دولية."
    },
    "inputValidation": {
      "emptyField": {
        "message": "الرجاء إدخال رقم جوال صحيح."
      },
      "regEx": {
        "message": "الرجاء إدخال رقم جوال صحيح."
      },
      "invalidNumber": {
        "message": "الرجاء إدخال رقم جوال صحيح."
      }
    }
  },
  "fr-BE": {
    "input": {
      "placeholder": "Numéro de téléphone mobile",
      "label": "Numéro de téléphone mobile",
      "tooltip": "Entrez votre numéro de portable au format international."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Veuillez saisir un numéro de téléphone portable valide."
      },
      "regEx": {
        "message": "Veuillez saisir un numéro de mobile valide."
      },
      "invalidNumber": {
        "message": "Veuillez saisir un numéro de téléphone portable valide."
      }
    }
  },
  "fr-LU": {
    "input": {
      "placeholder": "Numéro de téléphone mobile",
      "label": "Numéro de téléphone mobile",
      "tooltip": "Entrez votre numéro de portable au format international."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Veuillez saisir un numéro de téléphone portable valide."
      },
      "regEx": {
        "message": "Veuillez saisir un numéro de mobile valide."
      },
      "invalidNumber": {
        "message": "Veuillez saisir un numéro de téléphone portable valide."
      }
    }
  },
  "mt-MT": {
    "input": {
      "placeholder": "In-numru tal-Mowbajl",
      "label": "In-numru tal-Mowbajl",
      "tooltip": "Daħħal in-numru tal-mowbajl tiegħek f'format internazzjonali."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Jekk jogħġbok daħħal numru tal-mowbajl validu."
      },
      "regEx": {
        "message": "Jekk jogħġbok daħħal numru mobbli validu."
      },
      "invalidNumber": {
        "message": "Jekk jogħġbok daħħal numru tal-mowbajl validu."
      }
    }
  },
  "sl-SI": {
    "input": {
      "placeholder": "Telefonska številka",
      "label": "Telefonska številka",
      "tooltip": "Vnesite svojo številko mobilnega telefona v mednarodni obliki."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Vnesite veljavno številko mobilnega telefona."
      },
      "regEx": {
        "message": "Vnesite veljavno številko mobilnega telefona."
      },
      "invalidNumber": {
        "message": "Vnesite veljavno številko mobilnega telefona."
      }
    }
  },
  "el-CY": {
    "input": {
      "placeholder": "Αριθμός κινητού",
      "label": "Αριθμός κινητού",
      "tooltip": "Εισαγάγετε τον αριθμό του κινητού σας σε διεθνή μορφή."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Εισαγάγετε έναν έγκυρο αριθμό κινητού."
      },
      "regEx": {
        "message": "Εισαγάγετε έναν έγκυρο αριθμό κινητού."
      },
      "invalidNumber": {
        "message": "Εισαγάγετε έναν έγκυρο αριθμό κινητού."
      }
    }
  },
  "tr-CY": {
    "input": {
      "placeholder": "Cep numarası",
      "label": "Cep numarası",
      "tooltip": "Cep telefonu numaranızı uluslararası formatta girin."
    },
    "inputValidation": {
      "emptyField": {
        "message": "Lütfen geçerli bir telefon numarası giriniz."
      },
      "regEx": {
        "message": "Lütfen geçerli bir telefon numarası giriniz."
      },
      "invalidNumber": {
        "message": "Lütfen geçerli bir telefon numarası giriniz."
      }
    }
  }
}
</i18n>
