<script setup>
import { inject, computed, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n'
const composables = inject('composables');
const { isRTL } = composables.useJurisdictionSwitch()

const props = defineProps({
  modelValue: {required: true},
  required: {type: Boolean, default: false},
  tooltip: {type: String, default: undefined},
  description: {type: String, default: undefined},
  label: {type: String},
  inputParameters: {type: Object},
  rules: {type: Array, default: undefined}
})
const emit = defineEmits(['update', 'load'])
const input = composables.useTwoWayBinding(props, emit, 'modelValue')

const { t } = useI18n()
function validate(rule, value, callback) {
  if (props.required !== true) return callback();
  if (value === '') {
    callback(new Error(t('inputValidation.emptyField.message')));
  } else {
    callback();
  }
}

import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

const hasTooltip = computed(() => {
  return !isEmpty(props.tooltip)
})
const hasDescription = computed(() => {
  return !isEmpty(props.description)
})

const inputLabel = computed(() => {
  return props.label ? props.label : t('input.label')
})
const placeholder = computed(() => {
  return get(props.inputParameters, 'placeholder', false) ? props.inputParameters.placeholder : t('input.placeholder')
})

onBeforeMount(() => {
  emit('load', {label: inputLabel})
})
</script>

<template>
  <el-form-item
      :class="{'is-required': required, 'right-to-left': isRTL}"
      v-bind="$attrs"
      :label="inputLabel"
      :rules="(rules) ? rules : [
        { validator: validate, trigger: ['blur', 'change'] }
      ]">

    <template #label>
      <span>{{inputLabel}}</span>
      <span v-if="hasTooltip" class="form-item__description"><i class="el-icon-info"/> {{tooltip}}</span>
      <span v-if="hasDescription" class="form-item__description"><i class="el-icon-info"/> {{description}}</span>
    </template>

    <el-input
        v-model="input"
        v-bind="inputParameters"
        autocorrect="off"
        autocapitalize="none"
        :placeholder="placeholder"
        minlength="2"
    ></el-input>
  </el-form-item>
</template>
