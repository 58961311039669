<script setup>
import { inject, defineProps, defineEmits } from 'vue';
const composables = inject('composables');
const { isRTL } = composables.useJurisdictionSwitch()

const props = defineProps({
  modelValue: {required: true},
  required: {type: Boolean, default: false},
  repeat: {type: Boolean, default: false},
  label: {type: String},
  labelRepeat: {type: String},
  tooltip: {type: String, default: undefined},
  description: {type: String, default: undefined},
  tooltipRepeat: {type: String, default: undefined},
  descriptionRepeat: {type: String, default: undefined},
  inputParameters: {type: Object},
  inputParametersRepeat: {type: Object}
})
const emit = defineEmits(['update', 'load'])
const input = composables.useTwoWayBinding(props, emit, 'modelValue')
</script>
<script>
  import isEmpty from 'lodash/isEmpty';

  export default {
    name: "DpInputEmailAddress",
    data() {
      return {
        inputRepeat: '',
        isEmpty: isEmpty,
        translationReportAlias: {
          tooltip: this.$t('input.tooltip'),
          description: this.$t('input.description'),
          tooltipRepeat: this.$t('inputRepeat.tooltip'),
          descriptionRepeat: this.$t('inputRepeat.description')
        },
      };
    },
    computed: {
      inputLabel() {
        return (this.label) ? this.label : this.$t('input.label');
      },
      placeholder() {
        return (this.inputParameters && this.inputParameters.placeholder) ? this.inputParameters.placeholder : this.$t('input.placeholder');
      },
      inputLabelRepeat() {
        return (this.labelRepeat) ? this.labelRepeat : this.$t('inputRepeat.label');
      },
      placeholderRepeat() {
        return (this.inputParametersRepeat && this.inputParametersRepeat.placeholder) ? this.inputParametersRepeat.placeholder : this.$t('inputRepeat.placeholder');
      }
    },
    methods: {
      validateEmail(rule, value, callback) {
        if (this.required !== true) return callback();
        // eslint-disable-next-line
        var reqEx = /^(?:[a-zA-Z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
        if (value === '') {
          callback(new Error(this.$t('inputValidation.emptyField.message')));
        } else if (!reqEx.test(value)) {
          callback(new Error(this.$t('inputValidation.invalidEmail.message')));
        } else {
          if (this.repeat === true && this.input !== this.inputRepeat) {
            callback(new Error(this.$t('inputValidation.notIdentical.message')));
          } else {
            callback();
          }
        }
      }
    },
    created() {
      this.$emit('load', {label: this.inputLabel});
    }
  }
</script>

<template>
  <div>
    <el-form-item
        :class="{'is-required': required, 'right-to-left': isRTL}"
        v-bind="$attrs"
        :label="inputLabel"
        :rules="[
        { validator: validateEmail, trigger: ['blur', 'change'] }
      ]">

      <template #label>
        <span>{{inputLabel}}</span>
        <span v-if="!isEmpty(tooltip)" class="form-item__description"><i class="el-icon-info"/> {{tooltip}}</span>
        <span v-if="!isEmpty(description)" class="form-item__description"><i class="el-icon-info"/> {{description}}</span>
      </template>

      <el-input
          v-model="input"
          v-bind="inputParameters"
          autocorrect="off"
          autocapitalize="none"
          type="email"
          :placeholder="placeholder"
          minlength="2"
      ></el-input>
    </el-form-item>

    <el-form-item
        :class="{'is-required': required}"
        v-bind="$attrs"
        v-if="repeat===true"
        :label="inputLabelRepeat"
        :rules="[
        { validator: validateEmail, trigger: ['blur', 'change'] }
      ]">

      <template #labelRepeat>
        <span>{{inputLabelRepeat}}</span>
        <span v-if="!isEmpty(tooltipRepeat)" class="form-item__description"><i class="el-icon-info"/> {{tooltipRepeat}}</span>
        <span v-if="!isEmpty(descriptionRepeat)" class="form-item__description"><i class="el-icon-info"/> {{descriptionRepeat}}</span>
      </template>

      <el-input
          v-model="inputRepeat"
          v-bind="inputParametersRepeat"
          autocorrect="off"
          autocapitalize="none"
          type="email"
          :placeholder="placeholderRepeat"
          minlength="2"
      ></el-input>
    </el-form-item>
  </div>
</template>

<i18n>
{
  "de-DE": {
    "input": {
      "tooltip": "E-Mail",
      "placeholder": "E-Mail-Adresse",
      "label": "E-Mail-Adresse"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Bitte gib eine gültige E-Mail-Adresse ein."
      },
      "emptyField": {
        "message": "Bitte gib eine gültige E-Mail-Adresse ein."
      }
    }
  },
  "de-AT": {
    "input": {
      "tooltip": "cl.components.Inputs.DpInputEmailAddress.input.tooltip",
      "placeholder": "E-Mail-Adresse",
      "label": "E-Mail-Adresse"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Bitte gib eine gültige E-Mail-Adresse ein."
      },
      "emptyField": {
        "message": "Bitte gib eine gültige E-Mail-Adresse ein."
      }
    }
  },
  "de-CH": {
    "input": {
      "tooltip": "cl.components.Inputs.DpInputEmailAddress.input.tooltip",
      "placeholder": "E-Mail-Adresse",
      "label": "E-Mail-Adresse"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Bitte gib eine gültige E-Mail-Adresse ein."
      },
      "emptyField": {
        "message": "Bitte gib eine gültige E-Mail-Adresse ein."
      }
    }
  },
  "en-US": {
    "input": {
      "tooltip": "E-Mail",
      "placeholder": "E-mail address",
      "label": "E-mail address"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Please enter a valid e-mail address."
      },
      "emptyField": {
        "message": "Please enter a valid e-mail address."
      }
    }
  },
  "en-GB": {
    "input": {
      "tooltip": "E-Mail",
      "placeholder": "E-mail address",
      "label": "E-mail address"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Please enter a valid e-mail address."
      },
      "emptyField": {
        "message": "Please enter a valid e-mail address."
      }
    }
  },
  "it-IT": {
    "input": {
      "tooltip": "E-mail",
      "placeholder": "Indirizzo e-mail",
      "label": "Indirizzo e-mail"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Inserisci un indirizzo e-mail valido."
      },
      "emptyField": {
        "message": "Inserisci un indirizzo e-mail valido."
      }
    }
  },
  "fr-FR": {
    "input": {
      "tooltip": "Email",
      "placeholder": "Adresse e-mail",
      "label": "Adresse e-mail"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Veuillez saisir une adresse mail valide."
      },
      "emptyField": {
        "message": "Veuillez saisir une adresse mail valide."
      }
    }
  },
  "es-ES": {
    "input": {
      "tooltip": "Correo electrónico",
      "placeholder": "Dirección de correo electrónico",
      "label": "Dirección de correo electrónico"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Introduzca una dirección de correo electrónico válida."
      },
      "emptyField": {
        "message": "Introduzca una dirección de correo electrónico válida."
      }
    }
  },
  "pl-PL": {
    "input": {
      "tooltip": "E-mail",
      "placeholder": "Adres e-mail",
      "label": "Adres e-mail"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Wpisz prawidłowy adres e-mail."
      },
      "emptyField": {
        "message": "Wpisz prawidłowy adres e-mail."
      }
    }
  },
  "nl-NL": {
    "input": {
      "tooltip": "E-mail",
      "placeholder": "E-mailadres",
      "label": "E-mailadres"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Voer een geldig e-mailadres in."
      },
      "emptyField": {
        "message": "Voer een geldig e-mailadres in."
      }
    }
  },
  "bg-BG": {
    "input": {
      "tooltip": "Електронна поща",
      "placeholder": "Имейл адрес",
      "label": "Имейл адрес"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Моля, въведете валиден имейл адрес."
      },
      "emptyField": {
        "message": "Моля, въведете валиден имейл адрес."
      }
    }
  },
  "bs-BA": {
    "input": {
      "tooltip": "E-mail",
      "placeholder": "Adresa e-pošte",
      "label": "Adresa e-pošte"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Molimo Vas da unesete važeću adresu e-pošte."
      },
      "emptyField": {
        "message": "Molimo Vas da unesete važeću adresu e-pošte."
      }
    }
  },
  "zh-CN": {
    "input": {
      "tooltip": "电子邮件",
      "placeholder": "电子邮件地址",
      "label": "电子邮件地址"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "请输入有效的电子邮件地址。"
      },
      "emptyField": {
        "message": "请输入有效的电子邮件地址。"
      }
    }
  },
  "da-DK": {
    "input": {
      "tooltip": "E-mail",
      "placeholder": "E-mailadresse",
      "label": "E-mailadresse"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Indtast en gyldig e-mailadresse."
      },
      "emptyField": {
        "message": "Indtast en gyldig e-mailadresse."
      }
    }
  },
  "et-EE": {
    "input": {
      "tooltip": "E-post",
      "placeholder": "E-posti aadress",
      "label": "E-posti aadress"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Palun sisesta kehtiv e-posti aadress."
      },
      "emptyField": {
        "message": "Palun sisesta kehtiv e-posti aadress."
      }
    }
  },
  "fi-FI": {
    "input": {
      "tooltip": "Sähköposti",
      "placeholder": "Sähköpostiosoite",
      "label": "Sähköpostiosoite"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Anna voimassa oleva sähköpostiosoite."
      },
      "emptyField": {
        "message": "Anna voimassa oleva sähköpostiosoite."
      }
    }
  },
  "el-GR": {
    "input": {
      "tooltip": "ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ",
      "placeholder": "Διεύθυνση ηλεκτρονικού ταχυδρομείου",
      "label": "Διεύθυνση ηλεκτρονικού ταχυδρομείου"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Παρακαλώ εισαγάγετε μια έγκυρη διεύθυνση ηλεκτρονικού ταχυδρομείου."
      },
      "emptyField": {
        "message": "Παρακαλώ εισαγάγετε μια έγκυρη διεύθυνση ηλεκτρονικού ταχυδρομείου."
      }
    }
  },
  "he-IL": {
    "input": {
      "tooltip": "אימייל",
      "placeholder": "כתובת דואל",
      "label": "כתובת דואל"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "אנא הכנס כתובת דואר אלקטרוני תקינה."
      },
      "emptyField": {
        "message": "אנא הכנס כתובת דואר אלקטרוני תקינה."
      }
    }
  },
  "ga-IE": {
    "input": {
      "tooltip": "R-phost",
      "placeholder": "Seoladh ríomhphoist",
      "label": "Seoladh ríomhphoist"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Cuir isteach seoladh ríomhphoist bailí."
      },
      "emptyField": {
        "message": "Cuir isteach seoladh ríomhphoist bailí."
      }
    }
  },
  "ja-JP": {
    "input": {
      "tooltip": "Eメール",
      "placeholder": "電子メールアドレス",
      "label": "電子メールアドレス"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "有効なメールアドレスを入力してください。"
      },
      "emptyField": {
        "message": "有効なメールアドレスを入力してください。"
      }
    }
  },
  "hr-HR": {
    "input": {
      "tooltip": "E-pošta",
      "placeholder": "Adresa e-pošte",
      "label": "Adresa e-pošte"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Unesite valjanu adresu e-pošte."
      },
      "emptyField": {
        "message": "Unesite valjanu adresu e-pošte."
      }
    }
  },
  "lv-LV": {
    "input": {
      "tooltip": "E-pasts",
      "placeholder": "E-pasta adrese",
      "label": "E-pasta adrese"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Lūdzu, ievadiet derīgu e-pasta adresi."
      },
      "emptyField": {
        "message": "Lūdzu, ievadiet derīgu e-pasta adresi."
      }
    }
  },
  "lt-LT": {
    "input": {
      "tooltip": "El. Paštas",
      "placeholder": "Elektroninio pašto adresas",
      "label": "Elektroninio pašto adresas"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Prašom įvesti galiojantį elektroninio pašto adresą."
      },
      "emptyField": {
        "message": "Prašom įvesti galiojantį elektroninio pašto adresą."
      }
    }
  },
  "pt-PT": {
    "input": {
      "tooltip": "Email",
      "placeholder": "Endereço de e-mail",
      "label": "Endereço de e-mail"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Introduza um endereço de e-mail válido."
      },
      "emptyField": {
        "message": "Introduza um endereço de e-mail válido."
      }
    }
  },
  "ro-RO": {
    "input": {
      "tooltip": "E-mail",
      "placeholder": "Adresa de e-mail",
      "label": "Adresa de e-mail"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Introduceți o adresă de e-mail validă."
      },
      "emptyField": {
        "message": "Introduceți o adresă de e-mail validă."
      }
    }
  },
  "ru-RU": {
    "input": {
      "tooltip": "Эл. почта",
      "placeholder": "Адрес электронной почты",
      "label": "Адрес электронной почты"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Пожалуйста, введите действительный адрес электронной почты."
      },
      "emptyField": {
        "message": "Пожалуйста, введите действительный адрес электронной почты."
      }
    }
  },
  "sv-SE": {
    "input": {
      "tooltip": "E-post",
      "placeholder": "E-postadress",
      "label": "E-postadress"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Ange en giltig e-postadress."
      },
      "emptyField": {
        "message": "Ange en giltig e-postadress."
      }
    }
  },
  "sk-SK": {
    "input": {
      "tooltip": "E-mail",
      "placeholder": "E-mailová adresa",
      "label": "E-mailová adresa"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Zadajte prosím platnú e-mailovú adresu."
      },
      "emptyField": {
        "message": "Zadajte prosím platnú e-mailovú adresu."
      }
    }
  },
  "cs-CZ": {
    "input": {
      "tooltip": "E-mailem",
      "placeholder": "E-mailová adresa",
      "label": "E-mailová adresa"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Prosím zadejte platnou -emailovou adresu."
      },
      "emptyField": {
        "message": "Prosím zadejte platnou -emailovou adresu."
      }
    }
  },
  "tr-TR": {
    "input": {
      "tooltip": "E-posta",
      "placeholder": "E-posta adresi",
      "label": "E-posta adresi"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Lütfen geçerli bir e-posta adresi girin."
      },
      "emptyField": {
        "message": "Lütfen geçerli bir e-posta adresi girin."
      }
    }
  },
  "hu-HU": {
    "input": {
      "tooltip": "Email",
      "placeholder": "E-mail-cím",
      "label": "E-mail-cím"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Érvényes e-mail-címet adjon meg."
      },
      "emptyField": {
        "message": "Érvényes e-mail-címet adjon meg."
      }
    }
  },
  "ar-AE": {
    "input": {
      "tooltip": "البريد الإلكتروني",
      "placeholder": "عنوان البريد الإلكتروني",
      "label": "عنوان البريد الإلكتروني"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "الرجاء إدخال بريد إلكتروني صحيح."
      },
      "emptyField": {
        "message": "الرجاء إدخال بريد إلكتروني صحيح."
      }
    }
  },
  "fr-BE": {
    "input": {
      "tooltip": "Email",
      "placeholder": "Adresse e-mail",
      "label": "Adresse e-mail"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Veuillez saisir une adresse mail valide."
      },
      "emptyField": {
        "message": "Veuillez saisir une adresse mail valide."
      }
    }
  },
  "fr-LU": {
    "input": {
      "tooltip": "Email",
      "placeholder": "Adresse e-mail",
      "label": "Adresse e-mail"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Veuillez saisir une adresse mail valide."
      },
      "emptyField": {
        "message": "Veuillez saisir une adresse mail valide."
      }
    }
  },
  "mt-MT": {
    "input": {
      "tooltip": "E-Mail",
      "placeholder": "Indirizz tal-posta elettronika",
      "label": "Indirizz tal-posta elettronika"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Jekk jogħġbok ikteb indirizz elettroniku validu."
      },
      "emptyField": {
        "message": "Jekk jogħġbok ikteb indirizz elettroniku validu."
      }
    }
  },
  "sl-SI": {
    "input": {
      "tooltip": "E-naslov",
      "placeholder": "E-poštni naslov",
      "label": "E-poštni naslov"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Vnesite veljaven e-poštni naslov."
      },
      "emptyField": {
        "message": "Vnesite veljaven e-poštni naslov."
      }
    }
  },
  "el-CY": {
    "input": {
      "tooltip": "ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ",
      "placeholder": "Διεύθυνση ηλεκτρονικού ταχυδρομείου",
      "label": "Διεύθυνση ηλεκτρονικού ταχυδρομείου"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Παρακαλώ εισαγάγετε μια έγκυρη διεύθυνση ηλεκτρονικού ταχυδρομείου."
      },
      "emptyField": {
        "message": "Παρακαλώ εισαγάγετε μια έγκυρη διεύθυνση ηλεκτρονικού ταχυδρομείου. "
      }
    }
  },
  "tr-CY": {
    "input": {
      "tooltip": "E-posta",
      "placeholder": "E-posta adresi",
      "label": "E-posta adresi"
    },
    "inputValidation": {
      "invalidEmail": {
        "message": "Lütfen geçerli bir e-posta adresi girin."
      },
      "emptyField": {
        "message": "Lütfen geçerli bir e-posta adresi girin."
      }
    }
  }
}
</i18n>
