<script setup>
import { inject, computed, defineProps, defineEmits, onBeforeMount } from 'vue';
const composables = inject('composables');
const { isRTL } = composables.useJurisdictionSwitch()
import dayjs from 'dayjs'

const props = defineProps({
  modelValue: {required: true},
  required: {type: Boolean, default: false},
  tooltip: {type: String, default: undefined},
  description: {type: String, default: undefined},
  label: {type: String},
  inputParameters: {type: Object}
})
const emit = defineEmits(['update', 'input', 'load'])
const input = composables.useTwoWayBinding(props, emit, 'modelValue')

import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const { locale } = useI18n({useScope: 'global'})
// eslint-disable-next-line no-unused-vars
const translationReportAlias = {
  tooltip: t('input.tooltip'),
  description: t('input.description')
};

import { isEmpty, get } from 'lodash';
function validate(rule, value, callback) {
  if (props.required !== true) return callback();
  if (isEmpty(value)) {
    callback(new Error(t('inputValidation.emptyField.message')));
  } else if (!dayjs(value).isValid()) {
    callback(new Error(t('inputValidation.invalidDate.message')));
  } else {
    callback();
  }
}
function emitValue() {
  emit('input', input.value)
}

import {useEnvStore} from "@dutypay/store-modules";
const envStore = useEnvStore();
const hasTooltip = computed(() => {
  return !isEmpty(props.tooltip)
})
const hasDescription = computed(() => {
  return !isEmpty(props.description)
})
const clearable = computed(() => {
  return get(props.inputParameters, 'clearable', dayjs(input.value).isValid())
})
const formInputLabel = computed(() => {
  return (props.label) ? props.label : t('input.label');
});
const format = computed(() => {
  return get(props.inputParameters, 'format', envStore.dateFormat[locale.value])
})
const style = computed(() => {
  return get(props.inputParameters, 'style', 'width: 100%')
})
const placeholder = computed(() => {
  return get(props.inputParameters, 'placeholder', t('input.placeholder'))
})

onBeforeMount(() => {
  emit('load', formInputLabel.value)
});
</script>
<script>
  export default {
    name: "DpDatePickerCustom"
  }
</script>

<template>

  <el-form-item
      :class="{'is-required': required, 'right-to-left': isRTL}"
      v-bind="$attrs"
      :label="formInputLabel"
      :rules="[
        { validator: validate, trigger: ['blur', 'change'] }
      ]">

    <template #label>
      <span>{{formInputLabel}}</span>
      <span v-if="hasTooltip" class="form-item__description"><i class="el-icon-info"/> {{tooltip}}</span>
      <span v-if="hasDescription" class="form-item__description"><i class="el-icon-info"/> {{description}}</span>
    </template>

    <el-date-picker
        v-model="input"
        v-bind="inputParameters"
        type="date"
        :format="format"
        :style="style"
        :clearable="clearable"
        v-on:change="emitValue"
        :placeholder="placeholder">
    </el-date-picker>

  </el-form-item>

</template>

<i18n>
{
  "de-DE": {
    "input": {
      "placeholder": "cl.components.Inputs.DpDatePickerCustom.input.placeholder",
      "label": "cl.components.Inputs.DpDatePickerCustom.input.label"
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Bitte gib ein gültiges Datum ein."
      },
      "emptyField": {
        "message": "Gültigen Eintrag eingeben."
      }
    }
  },
  "de-AT": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Bitte gib ein gültiges Datum ein."
      },
      "emptyField": {
        "message": "Gültigen Eintrag eingeben."
      }
    }
  },
  "de-CH": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Bitte gib ein gültiges Datum ein."
      },
      "emptyField": {
        "message": "Gültigen Eintrag eingeben."
      }
    }
  },
  "en-US": {
    "input": {
      "placeholder": "cl.components.Inputs.DpDatePickerCustom.input.placeholder",
      "label": "cl.components.Inputs.DpDatePickerCustom.input.label"
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Please enter a valid date."
      },
      "emptyField": {
        "message": "Enter a valid entry."
      }
    }
  },
  "en-GB": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Please enter a valid date."
      },
      "emptyField": {
        "message": "Enter a valid entry."
      }
    }
  },
  "it-IT": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Per favore, inserisci una data valida."
      },
      "emptyField": {
        "message": "Si prega di inserire una voce valida."
      }
    }
  },
  "fr-FR": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Veuillez entrer une date valide."
      },
      "emptyField": {
        "message": "Saisissez une entrée valide."
      }
    }
  },
  "es-ES": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Por favor introduzca una fecha valida."
      },
      "emptyField": {
        "message": "Ingrese una entrada válida."
      }
    }
  },
  "pl-PL": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Proszę wprowadź poprawną datę."
      },
      "emptyField": {
        "message": "Wprowadź prawidłowy wpis."
      }
    }
  },
  "nl-NL": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Vul alstublieft een geldige datum in."
      },
      "emptyField": {
        "message": "Voer een geldige invoer in."
      }
    }
  },
  "bg-BG": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Моля, въведете валидна дата."
      },
      "emptyField": {
        "message": "Въведете валиден запис."
      }
    }
  },
  "bs-BA": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Unesite valjani datum."
      },
      "emptyField": {
        "message": "Unesite valjani unos."
      }
    }
  },
  "zh-CN": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "请输入一个有效的日期。"
      },
      "emptyField": {
        "message": "输入有效的条目。"
      }
    }
  },
  "da-DK": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Indtast venligst en gyldig dato."
      },
      "emptyField": {
        "message": "Indtast en gyldig post."
      }
    }
  },
  "et-EE": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Sisestage kehtiv kuupäev."
      },
      "emptyField": {
        "message": "Sisestage kehtiv kirje."
      }
    }
  },
  "fi-FI": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Anna kelvollinen päivämäärä."
      },
      "emptyField": {
        "message": "Anna kelvollinen merkintä."
      }
    }
  },
  "el-GR": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Παρακαλώ εισάγετε μία έγκυρη ημερομηνία."
      },
      "emptyField": {
        "message": "Εισαγάγετε μια έγκυρη καταχώριση."
      }
    }
  },
  "he-IL": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "נא להזין תאריך חוקי."
      },
      "emptyField": {
        "message": "אנא הזן ערך חוקי."
      }
    }
  },
  "ga-IE": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Iontráil dáta bailí le do thoil."
      },
      "emptyField": {
        "message": "Iontráil iontráil bhailí."
      }
    }
  },
  "ja-JP": {
    "input": {
      "placeholder": "cl.components.Inputs.DpDatePickerCustom.input.placeholder",
      "label": "cl.components.Inputs.DpDatePickerCustom.input.label"
    },
    "inputValidation": {
      "invalidDate": {
        "message": "有効な日付を入力してください。"
      },
      "emptyField": {
        "message": "有効なエントリを入力してください。"
      }
    }
  },
  "hr-HR": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Unesite valjani datum."
      },
      "emptyField": {
        "message": "Unesite valjani unos."
      }
    }
  },
  "lv-LV": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Lūdzu, ievadiet derīgu datumu."
      },
      "emptyField": {
        "message": "Ievadiet derīgu ierakstu."
      }
    }
  },
  "lt-LT": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Įveskite galiojančią datą."
      },
      "emptyField": {
        "message": "Įveskite tinkamą įrašą."
      }
    }
  },
  "pt-PT": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Por favor insira uma data válida."
      },
      "emptyField": {
        "message": "Insira uma entrada válida."
      }
    }
  },
  "ro-RO": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Vă rugăm să introduceți o dată validă."
      },
      "emptyField": {
        "message": "Vă rugăm să introduceți o intrare validă."
      }
    }
  },
  "ru-RU": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Пожалуйста, введите правильную дату."
      },
      "emptyField": {
        "message": "Введите действительную запись."
      }
    }
  },
  "sv-SE": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Vänligen ange ett giltigt datum."
      },
      "emptyField": {
        "message": "Ange en giltig post."
      }
    }
  },
  "sk-SK": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Zadajte platný dátum."
      },
      "emptyField": {
        "message": "Zadajte platný údaj."
      }
    }
  },
  "cs-CZ": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Prosím vložte správné datum."
      },
      "emptyField": {
        "message": "Zadejte prosím platnou položku."
      }
    }
  },
  "tr-TR": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Lütfen geçerli bir tarih giriniz."
      },
      "emptyField": {
        "message": "Geçerli bir giriş girin."
      }
    }
  },
  "hu-HU": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Kérjük, adjon meg egy érvényes dátumot."
      },
      "emptyField": {
        "message": "Adjon meg egy érvényes bejegyzést."
      }
    }
  },
  "ar-AE": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "ارجوك ادخل تاريخ صحيح."
      },
      "emptyField": {
        "message": "أدخل إدخالاً صالحًا."
      }
    }
  },
  "fr-BE": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Veuillez entrer une date valide."
      },
      "emptyField": {
        "message": "Saisissez une entrée valide."
      }
    }
  },
  "fr-LU": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Veuillez entrer une date valide."
      },
      "emptyField": {
        "message": "Saisissez une entrée valide."
      }
    }
  },
  "mt-MT": {
    "input": {
      "placeholder": "cl.components.Inputs.DpDatePickerCustom.input.placeholder",
      "label": "cl.components.Inputs.DpDatePickerCustom.input.label"
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Jekk jogħġbok daħħal data valida."
      },
      "emptyField": {
        "message": "Daħħal dħul validu."
      }
    }
  },
  "sl-SI": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Vnesite veljaven datum."
      },
      "emptyField": {
        "message": "Vnesite veljaven vnos."
      }
    }
  },
  "el-CY": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Παρακαλώ εισάγετε μία έγκυρη ημερομηνία."
      },
      "emptyField": {
        "message": "Εισαγάγετε μια έγκυρη καταχώριση."
      }
    }
  },
  "tr-CY": {
    "input": {
      "placeholder": "",
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Lütfen geçerli bir tarih giriniz."
      },
      "emptyField": {
        "message": "Geçerli bir giriş girin."
      }
    }
  }
}
</i18n>

