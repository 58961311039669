export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de-DE": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei unserem Service ist ein Fehler aufgetreten. Bitte versuche es erneut."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versuch es noch einmal."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlender oder ungültiger Eintrag. Anweisung im Formular beachten."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulareingabe wurde erfolgreich verarbeitet."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])}
        }
      },
      "de-AT": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei unserem Service ist ein Fehler aufgetreten. Bitte versuche es erneut."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versuch es noch einmal."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlender oder ungültiger Eintrag. Anweisung im Formular beachten."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulareingabe wurde erfolgreich verarbeitet."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])}
        }
      },
      "de-CH": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei unserem Service ist ein Fehler aufgetreten. Bitte versuche es erneut."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versuch es noch einmal."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlender oder ungültiger Eintrag. Anweisung im Formular beachten."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Aktion"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulareingabe wurde erfolgreich verarbeitet."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])}
        }
      },
      "en-US": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred in our service. Please try again."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing or invalid entry. Follow the instructions in the form."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful action"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form input was processed successfully."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])}
        }
      },
      "en-GB": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred in our service. Please try again."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing or invalid entry. Follow the instructions in the form."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful action"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form input was processed successfully."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])}
        }
      },
      "it-IT": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore nel nostro servizio. Per favore riprova."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riprova."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voce mancante o non valida. Segui le istruzioni nel modulo."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azione riuscita"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il modulo è stato elaborato con successo."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])}
        }
      },
      "fr-FR": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite dans notre service. Veuillez réessayer."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essayez encore."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrée manquante ou invalide. Suivez les instructions du formulaire"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La saisie du formulaire a été traitée avec succès."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
        }
      },
      "es-ES": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha habido un error en nuestro servicio. Inténtelo de nuevo, por favor."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inténtelo de nuevo."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siga las instrucciones del formulario."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listo"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El formulario se ha procesado correctamente."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])}
        }
      },
      "pl-PL": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W naszym serwisie wystąpił błąd. Prosimy, spróbuj ponownie."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spróbuj ponownie."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brakujący lub nieprawidłowy wpis. Postępuj zgodnie z instrukcjami w formularzu."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czynność zakończona powodzeniem"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane wejściowe formularza zostały pomyślnie przetworzone."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź"])}
        }
      },
      "nl-NL": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden in onze service. Gelieve opnieuw te proberen."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer het nog eens."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontbrekende of ongeldige invoer. Volg de instructies op het formulier."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslaagde actie"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulier invoer is succesvol verwerkt."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigen"])}
        }
      },
      "bg-BG": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възникна грешка в нашата услуга. Моля, опитайте отново."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опитайте отново."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Липсващ или невалиден запис. Следвайте инструкциите във формуляра."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["грешка"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно действие"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведените във формуляра данни са обработени успешно."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потвърждение"])}
        }
      },
      "bs-BA": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Došlo je do greške u našem servisu. Molimo pokušajte ponovo."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokušajte ponovo."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unos nedostaje ili je nevažeći. Slijedite upute u obrascu."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greška"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno obavljeno"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unos obrasca je uspješno obrađen."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdite"])}
        }
      },
      "zh-CN": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的服务发生了错误。 请再试一遍。"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再试一次。"])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条目缺失或无效。请按窗体中的说明操作。"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作成功"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["窗体输入已成功处理。"])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])}
        }
      },
      "da-DK": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der opstod en fejl i vores service. Prøv igen."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv igen."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manglende eller ugyldig indtastning. Følg instruktionerne i formularen."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejl"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vellykket handling"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formularinput blev behandlet med succes."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræft"])}
        }
      },
      "et-EE": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meie teenuses on ilmnenud tõrge. Palun proovi uuesti."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proovi uuesti."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puuduv või kehtetu sisestus. Järgige vormis olevaid juhiseid."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viga"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edukas tegevus"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vormi sisendi töötlemine õnnestus."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinnita"])}
        }
      },
      "fi-FI": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palvelussamme on tapahtunut virhe. Olkaa hyvä, ja yrittäkää uudelleen."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yritä uudelleen."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puuttuva tai virheellinen merkintä. Noudata lomakkeen ohjeita."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onnistunut toiminta"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lomakkeen syötteen käsittely onnistui."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvista"])}
        }
      },
      "el-GR": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρουσιάστηκε σφάλμα στην υπηρεσία μας. ΠΑΡΑΚΑΛΩ προσπαθησε ξανα."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσπάθησε ξανά."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λείπει ή δεν είναι έγκυρη η καταχώριση. Ακολουθήστε τις οδηγίες στη φόρμα."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχημένη ενέργεια"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η εισαγωγή της φόρμας υποβλήθηκε σε επεξεργασία με επιτυχία."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση"])}
        }
      },
      "he-IL": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אירעה שגיאה בשירות שלנו. בבקשה נסה שוב."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["נסה שוב."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ערך חסר או לא חוקי. עקוב אחר ההוראות בטופס."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שְׁגִיאָה"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["פעולה מוצלחת"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קלט הטופס עבר עיבוד בהצלחה."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לְאַשֵׁר"])}
        }
      },
      "ga-IE": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tharla earráid inár seirbhís. Arís, le d'thoil."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déan iarracht arís."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iontráil ar iarraidh nó neamhbhailí. Lean na treoracha san fhoirm."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earráid"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gníomh rathúil"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próiseáladh an t-ionchur foirme go rathúil."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deimhnigh"])}
        }
      },
      "ja-JP": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービスでエラーが発生しました。 もう一度お試しください。"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再試行。"])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エントリがないか無効です。 フォームの指示に従ってください。"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラー"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功したアクション"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォーム入力は正常に処理されました。"])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認"])}
        }
      },
      "hr-HR": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Došlo je do pogreške u našoj usluzi. Molim vas pokušajte ponovno."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokušajte ponovo."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unos nedostaje ili je nevažeći. Slijedite upute u obrascu."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogreška"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješna akcija"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unos obrasca uspješno je obrađen."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrda"])}
        }
      },
      "lv-LV": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mūsu pakalpojumā ir radusies kļūda. Lūdzu, mēģiniet vēlreiz."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mēģiniet vēlreiz."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trūkstošs vai nederīgs ieraksts. Izpildiet veidlapā sniegtos norādījumus."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kļūda"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veiksmīga darbība"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veidlapas ievade tika veiksmīgi apstrādāta."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt"])}
        }
      },
      "lt-LT": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mūsų tarnyboje įvyko klaida. Bandykite dar kartą."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bandykite iš naujo."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trūksta arba neteisingas įrašas. Vykdykite instrukcijas formoje."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaida"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sėkmingas veiksmas"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formos įvestis sėkmingai apdorota."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patvirtinti"])}
        }
      },
      "pt-PT": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocorreu um erro no nosso serviço. Tente novamente."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tente novamente."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrada ausente ou inválida. Siga as instruções do formulário."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ação bem-sucedida"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A entrada do formulário foi processada com sucesso."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])}
        }
      },
      "ro-RO": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A apărut o eroare în serviciu. Încercați din nou."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încercați din nou."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valori lipsă sau incorecte. Urmați instrucțiunile din formular."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eroare"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acțiune reușită"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valorile introduse în formular au fost prelucrate cu succes."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmare"])}
        }
      },
      "ru-RU": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка в нашем сервисе. Пожалуйста, попробуйте еще раз."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попробуйте еще раз."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные отсутствуют или недействительны. Следуйте инструкциям в форме."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешное действие"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ввод формы произведен успешно."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])}
        }
      },
      "sv-SE": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett fel har uppstått i vår tjänst. Var god försök igen."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försök igen."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saknad eller felaktig inmatning. Följ instruktionerna i formuläret."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fel"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åtgärd utförd"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formuläret fylldes i korrekt."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekräfta"])}
        }
      },
      "sk-SK": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V našej službe sa vyskytla chyba. Prosím skúste znova."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skúste to znova."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chýbajúci alebo neplatný záznam. Postupujte podľa pokynov vo formulári."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspešná akcia"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadanie formulára bolo úspešne spracované."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdiť"])}
        }
      },
      "cs-CZ": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V naší službě se vyskytla chyba. Zkuste to prosím znovu."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkuste znovu."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chybějící nebo neplatný záznam. Postupujte podle pokynů ve formuláři."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěšná akce"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadání formuláře bylo úspěšně zpracováno."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit"])}
        }
      },
      "tr-TR": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hizmetimizde bir hata oluştu. Lütfen tekrar deneyin."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrar deneyin."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksik veya geçersiz giriş. Formdaki talimatları izleyin."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı eylem"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form girişi başarıyla işlendi."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onayla"])}
        }
      },
      "hu-HU": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba történt a szolgáltatásunkban. Próbálja meg újra."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próbálja meg újra."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiányzó vagy érvénytelen bejegyzés. Kövesse az űrlapon található utasításokat."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeres művelet"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az űrlap bevitele sikeresen feldolgozva"])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megerősít"])}
        }
      },
      "ar-AE": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ في خدمتنا. حاول مرة اخرى."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاول مجددا."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدخال مفقود أو غير صالح. اتبع التعليمات الواردة في النموذج."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل ناجح"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت معالجة إدخال النموذج بنجاح."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])}
        }
      },
      "fr-BE": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite dans notre service. Veuillez réessayer."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essayez encore."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrée manquante ou invalide. Suivez les instructions du formulaire."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La saisie du formulaire a été traitée avec succès."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
        }
      },
      "fr-LU": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite dans notre service. Veuillez réessayer."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essayez encore."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrée manquante ou invalide. Suivez les instructions du formulaire."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La saisie du formulaire a été traitée avec succès."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
        }
      },
      "mt-MT": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seħħ żball fis-servizz tagħna. Jekk jogħġbok erġa' pprova."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erġa' pprova."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dħul nieqes jew invalidu. Segwi l-istruzzjonijiet fil-formolae."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żball"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azzjoni ta' suċċess"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id-dħul tal-formola ġie pproċessat b'suċċess."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikkonferma"])}
        }
      },
      "sl-SI": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prišlo je do napake v naši storitvi. Poskusite ponovno."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poskusite ponovno."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnos manjka ali ni veljaven. Upoštevajte navodila v obrazcu."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napaka"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspešno opravljeno"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnos obrazca je bil uspešno obdelan."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potrdi"])}
        }
      },
      "el-CY": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρουσιάστηκε σφάλμα στην υπηρεσία μας. ΠΑΡΑΚΑΛΩ προσπαθησε ξανα."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσπάθησε ξανά."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λείπει ή δεν είναι έγκυρη η καταχώριση. Ακολουθήστε τις οδηγίες στη φόρμα."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχημένη ενέργεια"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η εισαγωγή της φόρμας υποβλήθηκε σε επεξεργασία με επιτυχία."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση"])}
        }
      },
      "tr-CY": {
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hizmetimizde bir hata oluştu. Lütfen tekrar deneyin."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrar deneyin."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksik veya geçersiz giriş. Formdaki talimatları izleyin."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])}
          },
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı eylem"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form girişi başarıyla işlendi."])}
          }
        },
        "primaryButton": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onayla"])}
        }
      }
    }
  })
}
