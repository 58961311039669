<script setup>
import { inject, defineProps, defineEmits } from 'vue';
const composables = inject('composables');
const { isRTL } = composables.useJurisdictionSwitch()

const props = defineProps({
  modelValue: {required: true},
  required: {type: Boolean, default: false},
  repeat: {type: Boolean, default: false},
  tooltip: {type: String, default: undefined},
  description: {type: String, default: undefined},
  tooltipRepeat: {type: String, default: undefined},
  descriptionRepeat: {type: String, default: undefined},
  label: {type: String},
  labelRepeat: {type: String},
  inputParameters: {type: Object},
  inputParametersRepeat: {type: Object}
})
const emit = defineEmits(['update', 'load'])
const input = composables.useTwoWayBinding(props, emit, 'modelValue')
</script>
<script>
import isEmpty from 'lodash/isEmpty';

export default {
  name: "DpInputPassword",
  data() {
    return {
      inputRepeat: '',
      translationReportAlias: {
        tooltip: this.$t('input.tooltip'),
        description: this.$t('input.description'),
        tooltipRepeat: this.$t('inputRepeat.tooltip'),
        descriptionRepeat: this.$t('inputRepeat.description')
      },
      isEmpty: isEmpty
    };
  },
  computed: {
    inputLabel() {
      return (this.label) ? this.label : this.$t('input.label');
    },
    placeholder() {
      return (this.inputParameters && this.inputParameters.placeholder) ? this.inputParameters.placeholder : this.$t('input.placeholder');
    },
    inputLabelRepeat() {
      return (this.labelRepeat) ? this.labelRepeat : this.$t('inputRepeat.label');
    },
    placeholderRepeat() {
      return (this.inputParametersRepeat && this.inputParametersRepeat.placeholder) ? this.inputParametersRepeat.placeholder : this.$t('inputRepeat.placeholder');
    }
  },
  methods: {
    validatePassword(rule, value, callback) {
      if (this.required !== true) return callback();
      if (value === '') {
        callback(new Error(this.$t('inputValidation.emptyField.message')));
      } else if (value.length < 12) {
        callback(new Error(this.$t('inputValidation.passwordTooShort.message')));
      } else {
        callback();
      }
    },
    validateRepeatedPassword(rule, value, callback) {
      if (this.repeat === true && this.input !== this.inputRepeat) {
        callback(new Error(this.$t('inputValidation.notIdentical.message')));
      } else {
        callback();
      }
    }
  },
  created() {
    this.$emit('load', {label: this.inputLabel});
  }
}
</script>

<template>
  <div>
    <el-form-item
        :class="{'is-required': required, 'right-to-left': isRTL}"
        v-bind="$attrs"
        :label="inputLabel"
        :rules="[
        { validator: validatePassword, trigger: ['blur', 'change'] }
      ]">

      <template #label>
        <span>{{inputLabel}}</span>
        <span v-if="!isEmpty(tooltip)" class="form-item__description"><i class="el-icon-info"/> {{tooltip}}</span>
        <span v-if="!isEmpty(description)" class="form-item__description"><i class="el-icon-info"/> {{description}}</span>
      </template>

      <el-input
          v-model="input"
          v-bind="inputParameters"
          type="password"
          autocorrect="off"
          autocapitalize="none"
          show-password
          :placeholder="placeholder"
          minlength="12"
      ></el-input>
    </el-form-item>

    <el-form-item
        :class="{'is-required': required, 'right-to-left': isRTL}"
        v-bind="$attrs"
        v-if="repeat === true"
        :label="inputLabelRepeat"
        :rules="[
        { validator: requiredRepeatedPassword, trigger: ['blur', 'change'] }
      ]">

      <template #label>
        <span>{{inputLabelRepeat}}</span>
        <span v-if="!isEmpty(tooltipRepeat)" class="form-item__description"><i class="el-icon-info"/> {{tooltipRepeat}}</span>
        <span v-if="!isEmpty(descriptionRepeat)" class="form-item__description"><i class="el-icon-info"/> {{descriptionRepeat}}</span>
      </template>

      <el-input
          v-model="inputRepeat"
          v-bind="inputParametersRepeat"
          type="password"
          autocorrect="off"
          autocapitalize="none"
          show-password
          :placeholder="placeholderRepeat"
          minlength="12"
      ></el-input>
    </el-form-item>
  </div>
</template>

<i18n>
{
  "de-DE": {
    "input": {
      "placeholder": "Passwort",
      "label": "Passwort",
      "tooltip": "Passwort"
    },
    "inputRepeat": {
      "tooltip": "Passwort wiederholen",
      "placeholder": "Passwort wiederholen",
      "label": "Passwort wiederholen"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Bitte gib mindestens 12 Zeichen ein."
      },
      "passwordTooShort": {
        "message": "Bitte gib mindestens 12 Zeichen ein."
      },
      "notIdentical": {
        "message": "Die Einträge sind nicht identisch."
      }
    }
  },
  "de-AT": {
    "input": {
      "placeholder": "Passwort",
      "label": "Passwort",
      "tooltip": "Passwort"
    },
    "inputRepeat": {
      "tooltip": "Passwort wiederholen",
      "placeholder": "Passwort wiederholen",
      "label": "Passwort wiederholen"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Bitte gib mindestens 12 Zeichen ein."
      },
      "passwordTooShort": {
        "message": "Bitte gib mindestens 12 Zeichen ein."
      },
      "notIdentical": {
        "message": "Die Einträge sind nicht identisch."
      }
    }
  },
  "de-CH": {
    "input": {
      "placeholder": "Passwort",
      "label": "Passwort",
      "tooltip": "Passwort"
    },
    "inputRepeat": {
      "tooltip": "Passwort wiederholen",
      "placeholder": "Passwort wiederholen",
      "label": "Passwort wiederholen"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Bitte gib mindestens 12 Zeichen ein."
      },
      "passwordTooShort": {
        "message": "Bitte gib mindestens 12 Zeichen ein."
      },
      "notIdentical": {
        "message": "Die Einträge sind nicht identisch."
      }
    }
  },
  "en-US": {
    "input": {
      "placeholder": "Password",
      "label": "Password",
      "tooltip": "Password"
    },
    "inputRepeat": {
      "tooltip": "Repeat password",
      "placeholder": "Repeat password",
      "label": "Repeat password"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Please enter at least 12 characters."
      },
      "passwordTooShort": {
        "message": "Please enter at least 12 characters."
      },
      "notIdentical": {
        "message": "The entries are not identical."
      }
    }
  },
  "en-GB": {
    "input": {
      "placeholder": "Password",
      "label": "Password",
      "tooltip": "Password"
    },
    "inputRepeat": {
      "tooltip": "Repeat password",
      "placeholder": "Repeat password",
      "label": "Repeat password"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Please enter at least 12 characters."
      },
      "passwordTooShort": {
        "message": "Please enter at least 12 characters."
      },
      "notIdentical": {
        "message": "The entries are not identical."
      }
    }
  },
  "it-IT": {
    "input": {
      "placeholder": "Password",
      "label": "Password",
      "tooltip": "Password"
    },
    "inputRepeat": {
      "tooltip": "Ripeti la password",
      "placeholder": "Ripeti la password",
      "label": "Ripeti la password"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Inserisci almeno 12 caratteri."
      },
      "passwordTooShort": {
        "message": "Inserisci almeno 12 caratteri."
      },
      "notIdentical": {
        "message": "Le voci non sono identiche."
      }
    }
  },
  "fr-FR": {
    "input": {
      "placeholder": "Mot de passe",
      "label": "Mot de passe",
      "tooltip": "Mot de passe"
    },
    "inputRepeat": {
      "tooltip": "Saisissez à nouveau votre mot de passe",
      "placeholder": "Saisissez à nouveau votre mot de passe",
      "label": "Saisissez à nouveau votre mot de passe"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Veuillez saisir au moins 12 caractères."
      },
      "passwordTooShort": {
        "message": "Veuillez saisir au moins 12 caractères."
      },
      "notIdentical": {
        "message": "Les entrées ne sont pas identiques."
      }
    }
  },
  "es-ES": {
    "input": {
      "placeholder": "Contraseña",
      "label": "Contraseña",
      "tooltip": "Contraseña"
    },
    "inputRepeat": {
      "tooltip": "Repite la contraseña",
      "placeholder": "Repite la contraseña",
      "label": "Repite la contraseña"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Introduzca al menos 12 caracteres."
      },
      "passwordTooShort": {
        "message": "Introduzca al menos 12 caracteres."
      },
      "notIdentical": {
        "message": "Las contraseñas no son iguales."
      }
    }
  },
  "pl-PL": {
    "input": {
      "placeholder": "Hasło",
      "label": "Hasło",
      "tooltip": "Hasło"
    },
    "inputRepeat": {
      "tooltip": "Powtórz hasło",
      "placeholder": "Powtórz hasło",
      "label": "Powtórz hasło"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Wpisz co najmniej 12 znaków."
      },
      "passwordTooShort": {
        "message": "Wpisz co najmniej 12 znaków."
      },
      "notIdentical": {
        "message": "Wpisy nie są identyczne."
      }
    }
  },
  "nl-NL": {
    "input": {
      "placeholder": "Wachtwoord",
      "label": "Wachtwoord",
      "tooltip": "Wachtwoord"
    },
    "inputRepeat": {
      "tooltip": "Herhaal wachtwoord",
      "placeholder": "Herhaal wachtwoord",
      "label": "Herhaal wachtwoord"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Voer ten minste 12 tekens in."
      },
      "passwordTooShort": {
        "message": "Voer ten minste 12 tekens in."
      },
      "notIdentical": {
        "message": "De invoer is niet identiek."
      }
    }
  },
  "bg-BG": {
    "input": {
      "placeholder": "Парола",
      "label": "Парола",
      "tooltip": "Парола"
    },
    "inputRepeat": {
      "tooltip": "Повторете паролата",
      "placeholder": "Повторете паролата",
      "label": "Повторете паролата"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Моля, въведете поне 12 символа."
      },
      "passwordTooShort": {
        "message": "Моля, въведете поне 12 символа."
      },
      "notIdentical": {
        "message": "Въведеното не съвпада."
      }
    }
  },
  "bs-BA": {
    "input": {
      "placeholder": "Lozinka",
      "label": "Lozinka",
      "tooltip": "Lozinka"
    },
    "inputRepeat": {
      "tooltip": "Ponovite lozinku",
      "placeholder": "Ponovite lozinku",
      "label": "Ponovite lozinku"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Unesite najmanje 12 znakova."
      },
      "passwordTooShort": {
        "message": "Unesite najmanje 12 znakova."
      },
      "notIdentical": {
        "message": "Unosi nisu identični."
      }
    }
  },
  "zh-CN": {
    "input": {
      "placeholder": "密码",
      "label": "密码",
      "tooltip": "密码"
    },
    "inputRepeat": {
      "tooltip": "再次输入密码",
      "placeholder": "再次输入密码",
      "label": "再次输入密码"
    },
    "inputValidation": {
      "emptyField": {
        "message": "请输入至少12个字符。"
      },
      "passwordTooShort": {
        "message": "请输入至少12个字符。"
      },
      "notIdentical": {
        "message": "密码不同。"
      }
    }
  },
  "da-DK": {
    "input": {
      "placeholder": "Adgangskode",
      "label": "Adgangskode",
      "tooltip": "Adgangskode"
    },
    "inputRepeat": {
      "tooltip": "Gentag adgangskode",
      "placeholder": "Gentag adgangskode",
      "label": "Gentag adgangskode"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Indtast mindst 12 tegn."
      },
      "passwordTooShort": {
        "message": "Indtast mindst 12 tegn."
      },
      "notIdentical": {
        "message": "Posterne er ikke identiske."
      }
    }
  },
  "et-EE": {
    "input": {
      "placeholder": "Parool",
      "label": "Parool",
      "tooltip": "Parool"
    },
    "inputRepeat": {
      "tooltip": "Korda parooli",
      "placeholder": "Korda parooli",
      "label": "Korda parooli"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Sisestage vähemalt 12 tähemärki."
      },
      "passwordTooShort": {
        "message": "Sisestage vähemalt 12 tähemärki."
      },
      "notIdentical": {
        "message": "Kirjed pole identsed."
      }
    }
  },
  "fi-FI": {
    "input": {
      "placeholder": "Salasana",
      "label": "Salasana",
      "tooltip": "Salasana"
    },
    "inputRepeat": {
      "tooltip": "Toista salasana",
      "placeholder": "Toista salasana",
      "label": "Toista salasana"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Kirjoita vähintään 12 merkkiä."
      },
      "passwordTooShort": {
        "message": "Kirjoita vähintään 12 merkkiä."
      },
      "notIdentical": {
        "message": "Merkinnät eivät ole identtisiä."
      }
    }
  },
  "el-GR": {
    "input": {
      "placeholder": "Κωδικός πρόσβασης",
      "label": "Κωδικός πρόσβασης",
      "tooltip": "Κωδικός πρόσβασης"
    },
    "inputRepeat": {
      "tooltip": "Επαναλάβετε τον κωδικό πρόσβασης",
      "placeholder": "Επαναλάβετε τον κωδικό πρόσβασης",
      "label": "Επαναλάβετε τον κωδικό πρόσβασης"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Εισαγάγετε τουλάχιστον 12 χαρακτήρες."
      },
      "passwordTooShort": {
        "message": "Εισαγάγετε τουλάχιστον 12 χαρακτήρες."
      },
      "notIdentical": {
        "message": "Οι καταχωρήσεις δεν είναι πανομοιότυπες."
      }
    }
  },
  "he-IL": {
    "input": {
      "placeholder": "סיסמה",
      "label": "סיסמה",
      "tooltip": "סיסמה"
    },
    "inputRepeat": {
      "tooltip": "חזור על הסיסמה",
      "placeholder": "חזור על הסיסמה",
      "label": "חזור על הסיסמה"
    },
    "inputValidation": {
      "emptyField": {
        "message": "אנא הזן לפחות 12 תווים."
      },
      "passwordTooShort": {
        "message": "אנא הזן לפחות 12 תווים."
      },
      "notIdentical": {
        "message": "הערכים אינם זהים."
      }
    }
  },
  "ga-IE": {
    "input": {
      "placeholder": "Pasfhocal",
      "label": "Pasfhocal",
      "tooltip": "Pasfhocal"
    },
    "inputRepeat": {
      "tooltip": "Athscríobh an pasfhocal",
      "placeholder": "Athscríobh an pasfhocal",
      "label": "Athscríobh an pasfhocal"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Iontráil 12 charachtar ar a laghad."
      },
      "passwordTooShort": {
        "message": "Iontráil 12 charachtar ar a laghad."
      },
      "notIdentical": {
        "message": "Níl na hiontrálacha comhionann."
      }
    }
  },
  "ja-JP": {
    "input": {
      "placeholder": "パスワード",
      "label": "パスワード",
      "tooltip": "パスワード"
    },
    "inputRepeat": {
      "tooltip": "パスワードを再度入力してください",
      "placeholder": "パスワードを再度入力してください",
      "label": "パスワードを再度入力してください"
    },
    "inputValidation": {
      "emptyField": {
        "message": "12文字以上入力してください。"
      },
      "passwordTooShort": {
        "message": "12文字以上入力してください。"
      },
      "notIdentical": {
        "message": "エントリは同一ではありません。"
      }
    }
  },
  "hr-HR": {
    "input": {
      "placeholder": "Lozinka",
      "label": "Lozinka",
      "tooltip": "Lozinka"
    },
    "inputRepeat": {
      "tooltip": "Ponovite lozinku",
      "placeholder": "Ponovite lozinku",
      "label": "Ponovite lozinku"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Unesite najmanje 12 znakova."
      },
      "passwordTooShort": {
        "message": "Unesite najmanje 12 znakova."
      },
      "notIdentical": {
        "message": "Unosi nisu identični."
      }
    }
  },
  "lv-LV": {
    "input": {
      "placeholder": "Parole",
      "label": "Parole",
      "tooltip": "Parole"
    },
    "inputRepeat": {
      "tooltip": "Atkārtot paroli",
      "placeholder": "Atkārtot paroli",
      "label": "Atkārtot paroli"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Lūdzu, ievadiet vismaz 12 rakstzīmes."
      },
      "passwordTooShort": {
        "message": "Lūdzu, ievadiet vismaz 12 rakstzīmes."
      },
      "notIdentical": {
        "message": "Ieraksti nav identiski."
      }
    }
  },
  "lt-LT": {
    "input": {
      "placeholder": "Slaptažodis",
      "label": "Slaptažodis",
      "tooltip": "Slaptažodis"
    },
    "inputRepeat": {
      "tooltip": "Pakartokite slaptažodį",
      "placeholder": "Pakartokite slaptažodį",
      "label": "Pakartokite slaptažodį"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Įveskite mažiausiai 12 simbolių."
      },
      "passwordTooShort": {
        "message": "Įveskite mažiausiai 12 simbolių."
      },
      "notIdentical": {
        "message": "Įrašai nėra tapatūs."
      }
    }
  },
  "pt-PT": {
    "input": {
      "placeholder": "Palavra-passe",
      "label": "Palavra-passe",
      "tooltip": "Palavra-passe"
    },
    "inputRepeat": {
      "tooltip": "Repita a palavra-passe",
      "placeholder": "Repita a palavra-passe",
      "label": "Repita a palavra-passe"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Introduza pelo menos 12 caracteres."
      },
      "passwordTooShort": {
        "message": "Introduza pelo menos 12 caracteres."
      },
      "notIdentical": {
        "message": "As entradas não são idênticas."
      }
    }
  },
  "ro-RO": {
    "input": {
      "placeholder": "Parola",
      "label": "Parola",
      "tooltip": "Parola"
    },
    "inputRepeat": {
      "tooltip": "Repetați parola",
      "placeholder": "Repetați parola",
      "label": "Repetați parola"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Vă rugăm să introduceți cel puțin 12 caractere."
      },
      "passwordTooShort": {
        "message": "Vă rugăm să introduceți cel puțin 12 caractere."
      },
      "notIdentical": {
        "message": "Valorile introduse nu sunt identice."
      }
    }
  },
  "ru-RU": {
    "input": {
      "placeholder": "Пароль",
      "label": "Пароль",
      "tooltip": "Пароль"
    },
    "inputRepeat": {
      "tooltip": "Повторите пароль",
      "placeholder": "Повторите пароль",
      "label": "Повторите пароль"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Пожалуйста, введите не менее 12 символов."
      },
      "passwordTooShort": {
        "message": "Пожалуйста, введите не менее 12 символов."
      },
      "notIdentical": {
        "message": "Введенные данные не совпадают."
      }
    }
  },
  "sv-SE": {
    "input": {
      "placeholder": "Lösenord",
      "label": "Lösenord",
      "tooltip": "Lösenord"
    },
    "inputRepeat": {
      "tooltip": "Upprepa lösenord",
      "placeholder": "Upprepa lösenord",
      "label": "Upprepa lösenord"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Ange minst 12 tecken."
      },
      "passwordTooShort": {
        "message": "Ange minst 12 tecken."
      },
      "notIdentical": {
        "message": "Inmatningarna är inte identiska"
      }
    }
  },
  "sk-SK": {
    "input": {
      "placeholder": "Heslo",
      "label": "Heslo",
      "tooltip": "Heslo"
    },
    "inputRepeat": {
      "tooltip": "Zopakujte heslo",
      "placeholder": "Zopakujte heslo",
      "label": "Zopakujte heslo"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Zadajte prosím aspoň 12 znakov."
      },
      "passwordTooShort": {
        "message": "Zadajte prosím aspoň 12 znakov."
      },
      "notIdentical": {
        "message": "Položky nie sú totožné."
      }
    }
  },
  "cs-CZ": {
    "input": {
      "placeholder": "Heslo",
      "label": "Heslo",
      "tooltip": "Heslo"
    },
    "inputRepeat": {
      "tooltip": "Zopakovat heslo",
      "placeholder": "Zopakovat heslo",
      "label": "Zopakovat heslo"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Zadejte nejméně 12 znaků."
      },
      "passwordTooShort": {
        "message": "Zadejte nejméně 12 znaků."
      },
      "notIdentical": {
        "message": "Položky nejsou totožné."
      }
    }
  },
  "tr-TR": {
    "input": {
      "placeholder": "Parola",
      "label": "Parola",
      "tooltip": "Parola"
    },
    "inputRepeat": {
      "tooltip": "Şifreyi tekrar girin",
      "placeholder": "Şifreyi tekrar girin",
      "label": "Şifreyi tekrar girin"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Lütfen en az 12 karakter girin."
      },
      "passwordTooShort": {
        "message": "Lütfen en az 12 karakter girin."
      },
      "notIdentical": {
        "message": "Girişler aynı değil."
      }
    }
  },
  "hu-HU": {
    "input": {
      "placeholder": "Jelszó",
      "label": "Jelszó",
      "tooltip": "Jelszó"
    },
    "inputRepeat": {
      "tooltip": "Jelszó újra",
      "placeholder": "Jelszó újra",
      "label": "Jelszó újra"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Kérjük, adjon meg legalább 12 karaktert."
      },
      "passwordTooShort": {
        "message": "Kérjük, adjon meg legalább 12 karaktert."
      },
      "notIdentical": {
        "message": "A megadott adatok nem egyeznek."
      }
    }
  },
  "ar-AE": {
    "input": {
      "placeholder": "كلمة السر",
      "label": "كلمة السر",
      "tooltip": "كلمة السر"
    },
    "inputRepeat": {
      "tooltip": "اعد كلمة السر",
      "placeholder": "اعد كلمة السر",
      "label": "اعد كلمة السر"
    },
    "inputValidation": {
      "emptyField": {
        "message": "الرجاء إدخال 12 حرفًا على الأقل."
      },
      "passwordTooShort": {
        "message": "الرجاء إدخال 12 حرفًا على الأقل."
      },
      "notIdentical": {
        "message": "الإدخالات ليست متطابقة."
      }
    }
  },
  "fr-BE": {
    "input": {
      "placeholder": "Mot de passe",
      "label": "Mot de passe",
      "tooltip": "Mot de passe"
    },
    "inputRepeat": {
      "tooltip": "Saisissez à nouveau votre mot de passe",
      "placeholder": "Saisissez à nouveau votre mot de passe",
      "label": "Saisissez à nouveau votre mot de passe"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Veuillez saisir au moins 12 caractères."
      },
      "passwordTooShort": {
        "message": "Veuillez saisir au moins 12 caractères."
      },
      "notIdentical": {
        "message": "Les entrées ne sont pas identiques."
      }
    }
  },
  "fr-LU": {
    "input": {
      "placeholder": "Mot de passe",
      "label": "Mot de passe",
      "tooltip": "Mot de passe"
    },
    "inputRepeat": {
      "tooltip": "Répéter le mot de passe",
      "placeholder": "Répéter le mot de passe",
      "label": "Répéter le mot de passe"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Veuillez saisir au moins 12 caractères."
      },
      "passwordTooShort": {
        "message": "Veuillez saisir au moins 12 caractères."
      },
      "notIdentical": {
        "message": "Les entrées ne sont pas identiques."
      }
    }
  },
  "mt-MT": {
    "input": {
      "placeholder": "Password",
      "label": "Password",
      "tooltip": "Password"
    },
    "inputRepeat": {
      "tooltip": "Irrepeti l-password",
      "placeholder": "Irrepeti l-password",
      "label": "Irrepeti l-password"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Jekk jogħġbok daħħal mill-inqas 12-il karattru."
      },
      "passwordTooShort": {
        "message": "Jekk jogħġbok daħħal mill-inqas 12-il karattru."
      },
      "notIdentical": {
        "message": "L-entrati mhumiex identiċi."
      }
    }
  },
  "sl-SI": {
    "input": {
      "placeholder": "Geslo",
      "label": "Geslo",
      "tooltip": "Geslo"
    },
    "inputRepeat": {
      "tooltip": "Ponovite geslo",
      "placeholder": "Ponovite geslo",
      "label": "Ponovite geslo"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Vnesite vsaj 12 znakov."
      },
      "passwordTooShort": {
        "message": "Vnesite vsaj 12 znakov."
      },
      "notIdentical": {
        "message": "Vnosa nista enaka."
      }
    }
  },
  "el-CY": {
    "input": {
      "placeholder": "Κωδικός πρόσβασης",
      "label": "Κωδικός πρόσβασης",
      "tooltip": "Κωδικός πρόσβασης"
    },
    "inputRepeat": {
      "tooltip": "Επαναλάβετε τον κωδικό πρόσβασης",
      "placeholder": "Επαναλάβετε τον κωδικό πρόσβασης",
      "label": "Επαναλάβετε τον κωδικό πρόσβασης"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Εισαγάγετε τουλάχιστον 12 χαρακτήρες."
      },
      "passwordTooShort": {
        "message": "Εισαγάγετε τουλάχιστον 12 χαρακτήρες."
      },
      "notIdentical": {
        "message": "Οι καταχωρήσεις δεν είναι πανομοιότυπες."
      }
    }
  },
  "tr-CY": {
    "input": {
      "placeholder": "Parola",
      "label": "Parola",
      "tooltip": "Parola"
    },
    "inputRepeat": {
      "tooltip": "Şifreyi tekrar girin",
      "placeholder": "Şifreyi tekrar girin",
      "label": "Şifreyi tekrar girin"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Lütfen en az 12 karakter girin."
      },
      "passwordTooShort": {
        "message": "Lütfen en az 12 karakter girin."
      },
      "notIdentical": {
        "message": "Girişler aynı değil."
      }
    }
  }
}
</i18n>
