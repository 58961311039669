<script setup>
import { inject, computed, defineProps, defineEmits, onMounted, onBeforeMount } from 'vue';
const composables = inject('composables');
const { isRTL } = composables.useJurisdictionSwitch()
import dayjs from 'dayjs'

const props = defineProps({
  modelValue: {required: true},
  required: {type: Boolean, default: false},
  tooltip: {type: String, default: undefined},
  description: {type: String, default: undefined},
  label: {type: String},
  inputParameters: {type: Object}
})
const emit = defineEmits(['update', 'input', 'load'])
const input = composables.useTwoWayBinding(props, emit, 'modelValue')

import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const { locale } = useI18n({useScope: 'global'})
// eslint-disable-next-line no-unused-vars
const translationReportAlias = {
  operatorOn: t('operators.on.selectValue'),
  operatorOnOrBefore: t('operators.onOrBefore.selectValue'),
  operatorOnOrAfter: t('operators.onOrAfter.selectValue'),
  tooltip: t('input.tooltip')
};
const operators = [
  {
    labelTranslationKey: 'operators.on.selectValue',
    value: 'eq'
  }, {
    labelTranslationKey: 'operators.onOrBefore.selectValue',
    value: 'lte'
  }, {
    labelTranslationKey: 'operators.onOrAfter.selectValue',
    value: 'gte'
  }
]

function validate(rule, value, callback) {
  if (props.required !== true) return callback();
  if (!(value.op === 'eq' || value.op === 'lte' || value.op === 'gte')) {
    callback(new Error(t('inputValidation.noOperator.message')));
  } else if (!dayjs(value.date).isValid()) {
    callback(new Error(t('inputValidation.invalidDate.message')));
  } else {
    callback();
  }
}
function emitValue() {
  emit("input", input.value);
}

import { isEmpty, get } from 'lodash';
import {useEnvStore} from "@dutypay/store-modules";
const envStore = useEnvStore();
const hasTooltip = computed(() => {
  return !isEmpty(props.tooltip)
})
const hasDescription = computed(() => {
  return !isEmpty(props.description)
})
const clearableDate = computed(() => {
  return get(props.inputParameters, 'date.clearable', dayjs(input.value.date).isValid());
})
const formInputLabel = computed(() => {
  return (!isEmpty(props.label)) ? props.label : t('input.label');
})
const formatDate = computed(() => {
  return get(props.inputParameters, 'date.format', envStore.dateFormat[locale.value]);
})
const styleDate = computed(() => {
  return get(props.inputParameters, 'date.style', 'width: 70%');
})
const styleOp = computed(() => {
  return get(props.inputParameters, 'op.style', 'width: 30%');
})
const sizeOp = computed(() => {
  return get(props.inputParameters, 'op.size', 'default');
})
const placeholderDate = computed(() => {
  return get(props.inputParameters, 'date.placeholder', t('input.date.placeholder'))
})
const placeholderOp = computed(() => {
  return get(props.inputParameters, 'op.placeholder', t('input.operator.placeholder'))
})

onBeforeMount(() => {
  emit('load', formInputLabel.value)
});
onMounted(() => {
  input.vaule = isEmpty(props.modelValue) ? {op: 'eq', date: null} : props.modelValue;
  emitValue();
});
</script>
<script>
  export default {
    name: "DpDatePickerCustomOp"
  }
</script>

<template>

  <el-form-item
      :class="{'is-required': required, 'right-to-left': isRTL}"
      v-bind="$attrs"
      :label="formInputLabel"
      :rules="[
        { validator: validate, trigger: ['blur', 'change'] }
      ]">
    <template #label>
      <span>{{formInputLabel}}</span>
      <span v-if="hasTooltip" class="form-item__description"><i class="el-icon-info"/> {{tooltip}}</span>
      <span v-if="hasDescription" class="form-item__description"><i class="el-icon-info"/> {{description}}</span>
    </template>

    <el-select v-model="input.op"
               v-bind="get(inputParameters,'op')"
               :placeholder="placeholderOp"
               :style="styleOp"
               :size="sizeOp"
               class="dp-input__grouped__left">
      <el-option
          v-for="(item, index) in operators"
          :key="index"
          :label="t(item.labelTranslationKey)"
          :value="item.value">
      </el-option>
    </el-select>

    <el-date-picker
        v-model="input.date"
        v-bind="get(inputParameters,'date')"
        type="date"
        :format="formatDate"
        :style="styleDate"
        class="dp-input__grouped__right"
        :clearable="clearableDate"
        v-on:change="emitValue"
        :placeholder="placeholderDate">
    </el-date-picker>

  </el-form-item>

</template>

<i18n>
{
  "de-DE": {
    "input": {
      "date": {
        "placeholder": "Datum auswählen"
      },
      "operator": {
        "placeholder": "Datum auswählen"
      },
      "label": "cl.components.Inputs.DpDatePickerCustomOp.input.label"
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Bitte gib ein gültiges Datum ein."
      }
    },
    "operators": {
      "on": {
        "selectValue": "am"
      },
      "onOrBefore": {
        "selectValue": "am oder vor"
      },
      "onOrAfter": {
        "selectValue": "am oder nach"
      }
    }
  },
  "de-AT": {
    "input": {
      "date": {
        "placeholder": "Datum auswählen"
      },
      "operator": {
        "placeholder": "Datum auswählen"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Bitte gib ein gültiges Datum ein."
      }
    },
    "operators": {
      "on": {
        "selectValue": "am"
      },
      "onOrBefore": {
        "selectValue": "am oder vor"
      },
      "onOrAfter": {
        "selectValue": "am oder nach"
      }
    }
  },
  "de-CH": {
    "input": {
      "date": {
        "placeholder": "Datum auswählen"
      },
      "operator": {
        "placeholder": "Datum auswählen"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Bitte gib ein gültiges Datum ein."
      }
    },
    "operators": {
      "on": {
        "selectValue": "am"
      },
      "onOrBefore": {
        "selectValue": "am oder vor"
      },
      "onOrAfter": {
        "selectValue": "am oder nach"
      }
    }
  },
  "en-US": {
    "input": {
      "date": {
        "placeholder": "Choose a date"
      },
      "operator": {
        "placeholder": "Choose a date"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Please enter a valid date."
      }
    },
    "operators": {
      "on": {
        "selectValue": "on"
      },
      "onOrBefore": {
        "selectValue": "on or before"
      },
      "onOrAfter": {
        "selectValue": "on or after"
      }
    }
  },
  "en-GB": {
    "input": {
      "date": {
        "placeholder": "Choose a date"
      },
      "operator": {
        "placeholder": "Choose a date"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Please enter a valid date."
      }
    },
    "operators": {
      "on": {
        "selectValue": "on"
      },
      "onOrBefore": {
        "selectValue": "on or before"
      },
      "onOrAfter": {
        "selectValue": "on or after"
      }
    }
  },
  "it-IT": {
    "input": {
      "date": {
        "placeholder": "Scegli una data"
      },
      "operator": {
        "placeholder": "Scegli una data"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Per favore, inserisci una data valida."
      }
    },
    "operators": {
      "on": {
        "selectValue": "sopra"
      },
      "onOrBefore": {
        "selectValue": "prima o poi"
      },
      "onOrAfter": {
        "selectValue": "su o dopo"
      }
    }
  },
  "fr-FR": {
    "input": {
      "date": {
        "placeholder": "Choisissez la date"
      },
      "operator": {
        "placeholder": "Choisissez la date"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Veuillez entrer une date valide."
      }
    },
    "operators": {
      "on": {
        "selectValue": "au"
      },
      "onOrBefore": {
        "selectValue": "le ou avant"
      },
      "onOrAfter": {
        "selectValue": "le ou après"
      }
    }
  },
  "es-ES": {
    "input": {
      "date": {
        "placeholder": "Elige una fecha"
      },
      "operator": {
        "placeholder": "Elige una fecha"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Por favor introduzca una fecha valida."
      }
    },
    "operators": {
      "on": {
        "selectValue": "en"
      },
      "onOrBefore": {
        "selectValue": "en o antes"
      },
      "onOrAfter": {
        "selectValue": "sobre o después"
      }
    }
  },
  "pl-PL": {
    "input": {
      "date": {
        "placeholder": "Wybierz datę"
      },
      "operator": {
        "placeholder": "Wybierz datę"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Proszę wprowadź poprawną datę."
      }
    },
    "operators": {
      "on": {
        "selectValue": "na"
      },
      "onOrBefore": {
        "selectValue": "w dniu lub wcześniej"
      },
      "onOrAfter": {
        "selectValue": "w dniu lub po"
      }
    }
  },
  "nl-NL": {
    "input": {
      "date": {
        "placeholder": "Kies een datum"
      },
      "operator": {
        "placeholder": "Kies een datum"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Vul alstublieft een geldige datum in."
      }
    },
    "operators": {
      "on": {
        "selectValue": "aan"
      },
      "onOrBefore": {
        "selectValue": "op of voor"
      },
      "onOrAfter": {
        "selectValue": "op of na"
      }
    }
  },
  "bg-BG": {
    "input": {
      "date": {
        "placeholder": "Изберете дата"
      },
      "operator": {
        "placeholder": "Изберете дата"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Моля, въведете валидна дата."
      }
    },
    "operators": {
      "on": {
        "selectValue": "hа"
      },
      "onOrBefore": {
        "selectValue": "на или преди"
      },
      "onOrAfter": {
        "selectValue": "на или след"
      }
    }
  },
  "bs-BA": {
    "input": {
      "date": {
        "placeholder": "Odaberite datum"
      },
      "operator": {
        "placeholder": "Odaberite datum"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Unesite valjani datum."
      }
    },
    "operators": {
      "on": {
        "selectValue": "na"
      },
      "onOrBefore": {
        "selectValue": "na ili pre"
      },
      "onOrAfter": {
        "selectValue": "na ili posle"
      }
    }
  },
  "zh-CN": {
    "input": {
      "date": {
        "placeholder": "选择日期"
      },
      "operator": {
        "placeholder": "选择日期"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "请输入一个有效的日期。"
      }
    },
    "operators": {
      "on": {
        "selectValue": "上"
      },
      "onOrBefore": {
        "selectValue": "или раньше"
      },
      "onOrAfter": {
        "selectValue": "或之后"
      }
    }
  },
  "da-DK": {
    "input": {
      "date": {
        "placeholder": "Vælg dato"
      },
      "operator": {
        "placeholder": "Vælg dato"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Indtast venligst en gyldig dato."
      }
    },
    "operators": {
      "on": {
        "selectValue": "på"
      },
      "onOrBefore": {
        "selectValue": "på eller før"
      },
      "onOrAfter": {
        "selectValue": "på eller efter"
      }
    }
  },
  "et-EE": {
    "input": {
      "date": {
        "placeholder": "Valige kuupäev"
      },
      "operator": {
        "placeholder": "Valige kuupäev"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Sisestage kehtiv kuupäev."
      }
    },
    "operators": {
      "on": {
        "selectValue": "peal"
      },
      "onOrBefore": {
        "selectValue": "enne või enne"
      },
      "onOrAfter": {
        "selectValue": "sisse või pärast"
      }
    }
  },
  "fi-FI": {
    "input": {
      "date": {
        "placeholder": "Valitse päivämäärä"
      },
      "operator": {
        "placeholder": "Valitse päivämäärä"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Anna kelvollinen päivämäärä."
      }
    },
    "operators": {
      "on": {
        "selectValue": "päällä"
      },
      "onOrBefore": {
        "selectValue": "ennen tai ennen"
      },
      "onOrAfter": {
        "selectValue": "päällä tai sen jälkeen"
      }
    }
  },
  "el-GR": {
    "input": {
      "date": {
        "placeholder": "Επιλέξτε μια ημερομηνία"
      },
      "operator": {
        "placeholder": "Επιλέξτε μια ημερομηνία"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Παρακαλώ εισάγετε μία έγκυρη ημερομηνία."
      }
    },
    "operators": {
      "on": {
        "selectValue": "επί"
      },
      "onOrBefore": {
        "selectValue": "στις ή πριν από"
      },
      "onOrAfter": {
        "selectValue": "μετά ή μετά"
      }
    }
  },
  "he-IL": {
    "input": {
      "date": {
        "placeholder": "בחר תאריך"
      },
      "operator": {
        "placeholder": "בחר תאריך"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "נא להזין תאריך חוקי."
      }
    },
    "operators": {
      "on": {
        "selectValue": "עַל"
      },
      "onOrBefore": {
        "selectValue": "בזמן או לפני"
      },
      "onOrAfter": {
        "selectValue": "על או אחרי"
      }
    }
  },
  "ga-IE": {
    "input": {
      "date": {
        "placeholder": "Roghnaigh dáta"
      },
      "operator": {
        "placeholder": "Roghnaigh dáta"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Iontráil dáta bailí le do thoil."
      }
    },
    "operators": {
      "on": {
        "selectValue": "ar"
      },
      "onOrBefore": {
        "selectValue": "ar nó roimhe"
      },
      "onOrAfter": {
        "selectValue": "ar nó ina dhiaidh"
      }
    }
  },
  "ja-JP": {
    "input": {
      "date": {
        "placeholder": "日付を選択してください"
      },
      "operator": {
        "placeholder": "日付を選択してください"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "有効な日付を入力してください。"
      }
    },
    "operators": {
      "on": {
        "selectValue": "オン"
      },
      "onOrBefore": {
        "selectValue": "期日またはそれ以前"
      },
      "onOrAfter": {
        "selectValue": "以降"
      }
    }
  },
  "hr-HR": {
    "input": {
      "date": {
        "placeholder": "Odaberite datum"
      },
      "operator": {
        "placeholder": "Odaberite datum"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Unesite valjani datum."
      }
    },
    "operators": {
      "on": {
        "selectValue": "na"
      },
      "onOrBefore": {
        "selectValue": "na ili prije"
      },
      "onOrAfter": {
        "selectValue": "na ili poslije"
      }
    }
  },
  "lv-LV": {
    "input": {
      "date": {
        "placeholder": "Izvēlieties datumu"
      },
      "operator": {
        "placeholder": "Izvēlieties datumu"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Lūdzu, ievadiet derīgu datumu."
      }
    },
    "operators": {
      "on": {
        "selectValue": "ieslēgts"
      },
      "onOrBefore": {
        "selectValue": "uz vai pirms tam"
      },
      "onOrAfter": {
        "selectValue": "uz vai pēc"
      }
    }
  },
  "lt-LT": {
    "input": {
      "date": {
        "placeholder": "Pasirinkite datą"
      },
      "operator": {
        "placeholder": "Pasirinkite datą"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Įveskite galiojančią datą."
      }
    },
    "operators": {
      "on": {
        "selectValue": "įjungta"
      },
      "onOrBefore": {
        "selectValue": "prieš arba prieš"
      },
      "onOrAfter": {
        "selectValue": "įjungtas arba po jo"
      }
    }
  },
  "pt-PT": {
    "input": {
      "date": {
        "placeholder": "Escolha uma data"
      },
      "operator": {
        "placeholder": "Escolha uma data"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Por favor insira uma data válida."
      }
    },
    "operators": {
      "on": {
        "selectValue": "em"
      },
      "onOrBefore": {
        "selectValue": "em ou antes"
      },
      "onOrAfter": {
        "selectValue": "em ou depois"
      }
    }
  },
  "ro-RO": {
    "input": {
      "date": {
        "placeholder": "Alegeți o dată"
      },
      "operator": {
        "placeholder": "Alegeți o dată"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Vă rugăm să introduceți o dată validă."
      }
    },
    "operators": {
      "on": {
        "selectValue": "pe"
      },
      "onOrBefore": {
        "selectValue": "pe sau înainte"
      },
      "onOrAfter": {
        "selectValue": "pe sau după"
      }
    }
  },
  "ru-RU": {
    "input": {
      "date": {
        "placeholder": "Выберите дату"
      },
      "operator": {
        "placeholder": "Выберите дату"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Пожалуйста, введите правильную дату."
      }
    },
    "operators": {
      "on": {
        "selectValue": "на"
      },
      "onOrBefore": {
        "selectValue": "или раньше"
      },
      "onOrAfter": {
        "selectValue": "на или после"
      }
    }
  },
  "sv-SE": {
    "input": {
      "date": {
        "placeholder": "Välj ett datum"
      },
      "operator": {
        "placeholder": "Välj ett datum"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Vänligen ange ett giltigt datum."
      }
    },
    "operators": {
      "on": {
        "selectValue": "på"
      },
      "onOrBefore": {
        "selectValue": "på eller innan"
      },
      "onOrAfter": {
        "selectValue": "på eller efter"
      }
    }
  },
  "sk-SK": {
    "input": {
      "date": {
        "placeholder": "Vyberte dátum"
      },
      "operator": {
        "placeholder": "Vyberte dátum"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Zadajte platný dátum."
      }
    },
    "operators": {
      "on": {
        "selectValue": "na"
      },
      "onOrBefore": {
        "selectValue": "na alebo skôr"
      },
      "onOrAfter": {
        "selectValue": "po alebo po"
      }
    }
  },
  "cs-CZ": {
    "input": {
      "date": {
        "placeholder": "Vyberte datum"
      },
      "operator": {
        "placeholder": "Vyberte datum"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Prosím vložte správné datum."
      }
    },
    "operators": {
      "on": {
        "selectValue": "na"
      },
      "onOrBefore": {
        "selectValue": "na nebo před"
      },
      "onOrAfter": {
        "selectValue": "na nebo po"
      }
    }
  },
  "tr-TR": {
    "input": {
      "date": {
        "placeholder": "Tarih seçin"
      },
      "operator": {
        "placeholder": "Tarih seçin"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Lütfen geçerli bir tarih giriniz."
      }
    },
    "operators": {
      "on": {
        "selectValue": "üzerinde"
      },
      "onOrBefore": {
        "selectValue": "önce veya önce"
      },
      "onOrAfter": {
        "selectValue": "Üzerinde veya sonrasında"
      }
    }
  },
  "hu-HU": {
    "input": {
      "date": {
        "placeholder": "Válassza ki a dátumot"
      },
      "operator": {
        "placeholder": "Válassza ki a dátumot"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Kérjük, adjon meg egy érvényes dátumot."
      }
    },
    "operators": {
      "on": {
        "selectValue": "tovább"
      },
      "onOrBefore": {
        "selectValue": "rajta vagy előtte"
      },
      "onOrAfter": {
        "selectValue": "be vagy után"
      }
    }
  },
  "ar-AE": {
    "input": {
      "date": {
        "placeholder": "اختر التاريخ"
      },
      "operator": {
        "placeholder": "اختر التاريخ"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "ارجوك ادخل تاريخ صحيح."
      }
    },
    "operators": {
      "on": {
        "selectValue": "على"
      },
      "onOrBefore": {
        "selectValue": "في أو قبل"
      },
      "onOrAfter": {
        "selectValue": "في أو بعد"
      }
    }
  },
  "fr-BE": {
    "input": {
      "date": {
        "placeholder": "Choisissez la date"
      },
      "operator": {
        "placeholder": "Choisissez la date"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Veuillez entrer une date valide."
      }
    },
    "operators": {
      "on": {
        "selectValue": "au"
      },
      "onOrBefore": {
        "selectValue": "le ou avant"
      },
      "onOrAfter": {
        "selectValue": "le ou après"
      }
    }
  },
  "fr-LU": {
    "input": {
      "date": {
        "placeholder": "Choisissez la date"
      },
      "operator": {
        "placeholder": "Choisissez la date"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Veuillez entrer une date valide."
      }
    },
    "operators": {
      "on": {
        "selectValue": "au"
      },
      "onOrBefore": {
        "selectValue": "le ou avant"
      },
      "onOrAfter": {
        "selectValue": "le ou après"
      }
    }
  },
  "mt-MT": {
    "input": {
      "date": {
        "placeholder": "Agħżel id-data"
      },
      "operator": {
        "placeholder": "operator - Agħżel id-data"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Jekk jogħġbok daħħal data valida."
      }
    },
    "operators": {
      "on": {
        "selectValue": "fil -"
      },
      "onOrBefore": {
        "selectValue": "fuq jew qabel"
      },
      "onOrAfter": {
        "selectValue": "fuq jew wara"
      }
    }
  },
  "sl-SI": {
    "input": {
      "date": {
        "placeholder": "Izberite datum"
      },
      "operator": {
        "placeholder": "Izberite datum"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Vnesite veljaven datum."
      }
    },
    "operators": {
      "on": {
        "selectValue": "naprej"
      },
      "onOrBefore": {
        "selectValue": "na ali pred"
      },
      "onOrAfter": {
        "selectValue": "ob ali pozneje"
      }
    }
  },
  "el-CY": {
    "input": {
      "date": {
        "placeholder": "Επιλέξτε μια ημερομηνία"
      },
      "operator": {
        "placeholder": "Επιλέξτε μια ημερομηνία"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Παρακαλώ εισάγετε μία έγκυρη ημερομηνία."
      }
    },
    "operators": {
      "on": {
        "selectValue": "επί"
      },
      "onOrBefore": {
        "selectValue": "στις ή πριν από"
      },
      "onOrAfter": {
        "selectValue": "μετά ή μετά"
      }
    }
  },
  "tr-CY": {
    "input": {
      "date": {
        "placeholder": "Tarih seçin"
      },
      "operator": {
        "placeholder": "Tarih seçin"
      },
      "label": ""
    },
    "inputValidation": {
      "invalidDate": {
        "message": "Lütfen geçerli bir tarih giriniz."
      }
    },
    "operators": {
      "on": {
        "selectValue": "üzerinde"
      },
      "onOrBefore": {
        "selectValue": "önce veya önce"
      },
      "onOrAfter": {
        "selectValue": "Üzerinde veya sonrasında"
      }
    }
  }
}
</i18n>

