<script setup>
import { inject, defineProps, defineEmits } from 'vue';
const composables = inject('composables');
const {isRTL} = composables.useJurisdictionSwitch()

const props = defineProps({
  modelValue: {required: true},
  textBlockContent: {type: String},
  label: {type: String},
  required: {type: Boolean, default: false},
  checkboxParameters: {type: Object},
  validate: {type: Function, default: undefined}
})
const emit = defineEmits(['update'])
const checkbox = composables.useTwoWayBinding(props, emit, 'modelValue')
</script>
<script>
export default {
  name: "DpCheckbox",
  computed: {
    validator() {
      if (this.validate !== undefined) {
        return this.validate;
      } else {
        return this.validateDefault;
      }
    }
  },
  methods: {
    validateDefault(rule, value, callback) {
      if (this.required !== true) return callback();
      if (value !== true) {
        callback(new Error(this.$t('inputValidation.unchecked.message')));
      } else {
        callback();
      }
    },
  },
}
</script>

<template>
  <el-form-item
      v-bind="$attrs"
      :class="{
        'is-required': required,
        'right-to-left': isRTL
      }"
      :rules="[
        { validator: validator, trigger: ['change'] }
      ]">

    <el-checkbox v-model="checkbox">
      <span v-if="textBlockContent !== undefined" v-html="textBlockContent"></span>
      <span v-else>{{label}}</span>
    </el-checkbox>

  </el-form-item>
</template>

<i18n>
{
}
</i18n>
