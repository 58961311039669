import script from "./DpInputBIC.vue?vue&type=script&setup=true&lang=js"
export * from "./DpInputBIC.vue?vue&type=script&setup=true&lang=js"

import "./DpInputBIC.vue?vue&type=style&index=0&id=75a5d3c0&lang=scss"
/* custom blocks */
import block0 from "./DpInputBIC.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(script)


const __exports__ = script;

export default __exports__