<script setup>
import { inject, defineProps, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const composables = inject('composables');
const { isRTL } = composables.useJurisdictionSwitch()

const props = defineProps({
  modelValue: {required: true},
  required: {type: Boolean, default: false},
  tooltip: {type: String, default: undefined},
  description: {type: String, default: undefined},
  label: {type: String, required: true},
  slotLabel: {type: String},
  slotPrefix: {type: String},
  inputParameters: {type: Array}
})
const emit = defineEmits(['update', 'input', 'load'])
const input = composables.useTwoWayBinding(props, emit, 'modelValue')
</script>
<script>
import isEmpty from 'lodash/isEmpty';
import dropRight from 'lodash/dropRight';
import each from 'lodash/each';
import {mapState} from 'pinia';
  import {useEnvStore} from '@dutypay/store-modules'

export default {
  name: "DpInputCustomWithPeriods",
  data() {
    return {
      defaultRow: {value: '', period: ['', '']},
      translationReportAlias: {
        label: this.$t('input.label'),
        tooltip: this.$t('input.tooltip'),
        description: this.$t('input.description')
      },
      isEmpty: isEmpty
    };
  },
  computed: {
    ...mapState(useEnvStore, ['dateFormat']),
    format() {
      return [
        (this.inputParameters && this.inputParameters[0] && this.inputParameters[0].format) ? this.inputParameters[0].format : this.dateFormat[this.$i18n.locale],
        (this.inputParameters && this.inputParameters[1] && this.inputParameters[1].format) ? this.inputParameters[1].format : this.dateFormat[this.$i18n.locale]
      ];
    },
    style() {
      return [
        (this.inputParameters && this.inputParameters[0] && this.inputParameters[0].style) ? this.inputParameters[0].style : 'width: 100%',
        (this.inputParameters && this.inputParameters[1] && this.inputParameters[1].style) ? this.inputParameters[1].style : 'width: 100%'
      ];
    },
    placeholder() {
      return [
        (this.inputParameters && this.inputParameters[0] && this.inputParameters[0].placeholder) ? this.inputParameters[0].placeholder : this.$t('input.validFrom.placeholder'),
        (this.inputParameters && this.inputParameters[1] && this.inputParameters[1].placeholder) ? this.inputParameters[1].placeholder : this.$t('input.validThrough.placeholder')
      ];
    }
  },
  methods: {
    validate(rule, value, callback) {
      if (this.required !== true) return callback();
      let lastIndex = value.length - 1;
      if (this.notAllValuesFilled()) {
        callback(new Error(this.$t('inputValidation.emptyValue.message')));
      } else if (value === [this.defaultRow]) {
        callback(new Error(this.$t('inputValidation.emptyField.message')));
      } else if (value[lastIndex].period[0] === null || value[lastIndex].period[0] === '') {
        callback(new Error(this.$t('inputValidation.emptyPeriod.message')));
      } else {
        callback();
      }
    },
    notAllValuesFilled() {
      let returnValue = false;
      each(this.input, function (item) {
        if (item.value === undefined || item.value === '' || item.value === null)
          returnValue = true;
      })
      return returnValue;
    },
    emitValue: function () {
      this.$emit("input", this.input);
    },
    removeLastEntry() {
      this.input = dropRight(this.input);
      this.emitValue();
    },
    addEntry() {
      this.input.push({value: '', period: ['', '']});
      this.emitValue();
    },
    disabledDateValidFrom(date, index) { // Whoever debugs this, please forgive me.
      // You need to understand that element-UI will iterate through all dates and checks with this function whether the date is displayed as disabled in the interface or not. Thus, the return of the function is a boolean.
      // One row (index) has two dates (input[index][0] and input[index][1]).
      if (!!(this.input[index - 1]) && !!(this.input[index]) && // Row before and current row exist?
          this.input[index - 1].period[1] !== null && this.input[index - 1].period[1] !== '' && // The date before the current is filled?
          (this.input[index].period[1] === null || this.input[index].period[1] === '')) { // The date after the current is not filled?
        return date < new Date(new Date(this.input[index - 1].period[1]).setDate(new Date(this.input[index - 1].period[1]).getDate() - 1)); // This line compares the (iterative) date with the last input from the row before/above, minus one day. It's a mess, but moment.js was too slow.
      }
      if (!!(this.input[index - 1]) && !!(this.input[index]) &&
          this.input[index - 1].period[1] !== null && this.input[index - 1].period[1] !== '' &&
          this.input[index].period[1] !== null && this.input[index].period[1] !== '') { // The date before and after exists. The comparision has to happen between both dates.
        return (date < new Date(new Date(this.input[index - 1].period[1]).setDate(new Date(this.input[index - 1].period[1]).getDate() - 1)) ||
            date > new Date(new Date(this.input[index].period[1]).setDate(new Date(this.input[index].period[1]).getDate() - 1)));
      }
      if (!!(this.input[index]) && this.input[index].period[1] !== null) { // The first two cases failed. If the user wants to change the very first date, this comparision makes sure it can't be selected beyond input[index][1].
        return date > new Date(new Date(this.input[index].period[1]).setDate(new Date(this.input[index].period[1]).getDate() - 1));
      }
      return false; // default case
    },
    disabledDateValidThrough(date, index) {
      if (!!(this.input[index + 1]) && !!(this.input[index]) &&
          this.input[index + 1].period[0] !== null && this.input[index + 1].period[0] !== '' &&
          this.input[index].period[0] !== null && this.input[index].period[0] !== '') { // Analog to the function above, this is disabling the dates before and after the input[index][1].
        return (date > new Date(new Date(this.input[index + 1].period[0]).setDate(new Date(this.input[index + 1].period[0]).getDate() - 1)) ||
            date < new Date(new Date(this.input[index].period[0]).setDate(new Date(this.input[index].period[0]).getDate() - 1)));
      }
      if (!!(this.input[index]) && this.input[index].period[0] !== null) { // Compare with the input before input[index][1].
        return date < new Date(new Date(this.input[index].period[0]).setDate(new Date(this.input[index].period[0]).getDate() - 1));
      }
      return false; // default case
    },
    inputParameter(index, key, standardValue) {
      return (this.inputParameters && this.inputParameters[index] && this.inputParameters[index][key]) ? this.inputParameters[index][key] : standardValue;
    }
  },
  watch: {
    input: {
      deep: true,
      handler() {
        // If a date field within this.input is emptied by hand,
        // the UI has to react on this change and empty all date fields that come after.
        let self = this;
        let lastIndex = this.input.length - 1;
        each(this.input, function (item, index) {
          if (index < lastIndex && (item.period[1] === null || item.period[1] === ''))
            self.input[index + 1].period[0] = null;
          if (item.period[0] === null || item.period[0] === '') {
            self.input[index].period[1] = null;
            if (index < lastIndex) self.input[index + 1].period[0] = null;
          }
        })
      }
    }
  },
  created() {
    this.$emit('load', {label: this.label});
  },
  mounted() {
    this.input = (isEmpty(this.value)) ? this.defaultRow : this.modelValue;
    this.emitValue();
  }
}
</script>

<template>

  <div>

    <el-form-item
        :class="{'is-required': required, 'right-to-left': isRTL}"
        v-bind="$attrs"
        :label="this.label"
        :rules="[
        { validator: validate, trigger: ['blur', 'change'] }
      ]">

      <template #label>
        <span>{{label}}</span>
        <span v-if="!isEmpty(tooltip)" class="form-item__description"><i class="el-icon-info"/> {{tooltip}}</span>
        <span v-if="!isEmpty(description)" class="form-item__description"><i class="el-icon-info"/> {{description}}</span>
      </template>

      <div v-for="(item, index) in input" :key="index" class="dp-input__period__container">
        <span v-if="slotLabel">{{ slotLabel }}</span>

        <slot :name="(slotPrefix) ? slotPrefix + index.toString() : index"></slot>

        <el-row>
          <el-col :span="12">
            <span>{{ t('input.validFrom.label') }}</span>
            <el-date-picker
                v-if="item.period !== undefined"
                v-model="input[index].period[0]"
                v-bind="(inputParameters) ? inputParameters[0] : ''"
                type="date"
                :format="format[0]"
                class="dp-input__grouped__left"
                :style="style[0]"
                :clearable="inputParameter(0, 'clearable', !$dp.dayjs(item.period[1]).isValid())"
                v-on:change="emitValue"
                :default-value="inputParameter(0, 'defaultValue', (!!(input[index-1]) && $dp.dayjs(input[index-1][1]).isValid()) ? new Date(input[index-1][1]) : new Date())"
                :placeholder="placeholder[0]"
                :picker-options="inputParameter(0, 'pickerOptions', {disabledDate: (date) => {return disabledDateValidFrom(date, index)}})"
                :disabled="inputParameter(0, 'disabled', (index > 0 && (input[index - 1].period[1] === undefined || !$dp.dayjs(input[index - 1].period[1]).isValid())))">
            </el-date-picker>
          </el-col>
          <el-col :span="12">
            <span>{{ t('input.validThrough.label') }}</span>
            <el-date-picker
                v-if="item.period !== undefined"
                v-model="input[index].period[1]"
                v-bind="(inputParameters) ? inputParameters[1] : ''"
                type="date"
                :format="format[1]"
                class="dp-input__grouped__right"
                :style="style[1]"
                :clearable="inputParameter(0, 'clearable', !(input[index + 1] !== undefined && $dp.dayjs(input[index + 1].period[0]).isValid()))"
                v-on:change="emitValue"
                :default-value="inputParameter(0, 'defaultValue', ($dp.dayjs(input[index][0]).isValid()) ? new Date(input[index][0]) : new Date())"
                :placeholder="placeholder[1]"
                :picker-options="inputParameter(0, 'pickerOptions', {disabledDate: (date) => {return disabledDateValidThrough(date, index)}})"
                :disabled="inputParameter(0, 'disabled', !$dp.dayjs(item.period[0]).isValid())">
            </el-date-picker>
          </el-col>
        </el-row>

      </div>

    </el-form-item>

    <div class="dp-input__period__button-container">
      <dp-button v-if="input.length > 1"
                 size="tiny"
                 purpose="destructive"
                 @click="removeLastEntry">
        <i class="el-icon-minus"></i>&nbsp;{{ t('removeLastEntry.buttonLabel') }}
      </dp-button>
      <dp-button size="tiny"
                 @click="addEntry"
                 :disabled="input[input.length-1] === undefined || notAllValuesFilled() || input[input.length-1].period[1] === '' || input[input.length-1].period[1] === null">
        <i class="el-icon-plus"></i>&nbsp;{{ t('addMore.buttonLabel') }}
      </dp-button>
    </div>

  </div>

</template>

<i18n>
{
}
</i18n>

