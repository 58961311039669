export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de-DE": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Bestätigung die untenstehende E-Mail-Adresse im Eingabefeld eintragen."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Eintrag im Eingabefeld ist nicht identisch mit der oben vorgegebenen E-Mail-Adresse."])}
          }
        }
      },
      "de-AT": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Bestätigung die untenstehende E-Mail-Adresse im Eingabefeld eintragen."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Eintrag im Eingabefeld ist nicht identisch mit der oben vorgegebenen E-Mail-Adresse."])}
          }
        }
      },
      "de-CH": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Bestätigung die untenstehende E-Mail-Adresse im Eingabefeld eintragen."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Eintrag im Eingabefeld ist nicht identisch mit der oben vorgegebenen E-Mail-Adresse."])}
          }
        }
      },
      "en-US": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To confirm, enter the email address below in the input field."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The entry in the input field is not identical to the email address given above."])}
          }
        }
      },
      "en-GB": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To confirm, enter the email address below in the input field."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The entry in the input field is not identical to the email address given above."])}
          }
        }
      },
      "it-IT": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per confermare, inserisci l'indirizzo email sottostante nel campo di inserimento."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La voce nel campo di input non è identica all'indirizzo e-mail sopra indicato."])}
          }
        }
      },
      "fr-FR": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour confirmer, entrez l'adresse e-mail ci-dessous dans le champ de saisie."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'entrée dans le champ de saisie n'est pas identique à l'adresse e-mail indiquée ci-dessus."])}
          }
        }
      },
      "es-ES": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para confirmar, ingrese la dirección de correo electrónico a continuación en el campo de entrada."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La entrada en el campo de entrada no es idéntica a la dirección de correo electrónico proporcionada anteriormente."])}
          }
        }
      },
      "pl-PL": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby potwierdzić, wprowadź adres e-mail poniżej w polu wejściowym."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpis w polu wprowadzania nie jest identyczny z adresem e-mail podanym powyżej."])}
          }
        }
      },
      "nl-NL": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om te bevestigen vult u het onderstaande e-mailadres in het invoerveld in."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De invoer in het invoerveld is niet identiek aan het hierboven vermelde e-mailadres."])}
          }
        }
      },
      "bg-BG": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За да потвърдите, въведете имейл адреса по-долу в полето за въвеждане."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Записът в полето за въвеждане не е идентичен с посочения по-горе имейл адрес."])}
          }
        }
      },
      "bs-BA": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za potvrdu unesite adresu e-pošte ispod u polje za unos."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unos u polju za unos nije identičan gore navedenoj adresi e-pošte."])}
          }
        }
      },
      "zh-CN": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要确认，请在输入字段中输入下面的电子邮件地址。"])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入字段中的条目与上面给出的电子邮件地址不同。"])}
          }
        }
      },
      "da-DK": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For at bekræfte skal du indtaste e-mailadressen nedenfor i indtastningsfeltet."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtastningen i indtastningsfeltet er ikke identisk med den ovenfor angivne e-mailadresse."])}
          }
        }
      },
      "et-EE": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinnitamiseks sisestage allolevale sisestusväljale e-posti aadress."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestusvälja kirje ei ole identne ülaltoodud e-posti aadressiga."])}
          }
        }
      },
      "fi-FI": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvista kirjoittamalla alla oleva sähköpostiosoite syöttökenttään."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syöttökentässä oleva merkintä ei ole identtinen yllä olevan sähköpostiosoitteen kanssa."])}
          }
        }
      },
      "el-GR": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για επιβεβαίωση, εισαγάγετε τη διεύθυνση email παρακάτω στο πεδίο εισαγωγής."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η καταχώρηση στο πεδίο εισαγωγής δεν είναι πανομοιότυπη με τη διεύθυνση email που δίνεται παραπάνω."])}
          }
        }
      },
      "he-IL": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כדי לאשר, הזן את כתובת הדוא＂ל למטה בשדה הקלט."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הערך בשדה הקלט אינו זהה לכתובת המייל שצוינה לעיל."])}
          }
        }
      },
      "ga-IE": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chun a dhearbhú, iontráil an seoladh ríomhphoist thíos sa réimse ionchuir."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Níl an iontráil sa réimse ionchuir comhionann leis an seoladh ríomhphoist a thugtar thuas."])}
          }
        }
      },
      "ja-JP": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認するには、以下のメールアドレスを入力フィールドに入力してください。"])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力フィールドの入力は、上記の電子メールアドレスと同じではありません。"])}
          }
        }
      },
      "hr-HR": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za potvrdu unesite adresu e-pošte ispod u polje za unos."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unos u polju za unos nije identičan gore navedenoj adresi e-pošte."])}
          }
        }
      },
      "lv-LV": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lai apstiprinātu, ievadiet tālāk norādīto e-pasta adresi ievades laukā."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ievades laukā esošais ieraksts nav identisks iepriekš norādītajai e-pasta adresei."])}
          }
        }
      },
      "lt-LT": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norėdami patvirtinti, toliau esančiame laukelyje įveskite el. pašto adresą."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įvesties lauke esantis įrašas nėra identiškas aukščiau pateiktam el. pašto adresui."])}
          }
        }
      },
      "pt-PT": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para confirmar, insira o endereço de e-mail abaixo no campo de entrada."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A entrada no campo de entrada não é idêntica ao endereço de e-mail fornecido acima."])}
          }
        }
      },
      "ro-RO": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a confirma, introduceți adresa de e-mail de mai jos în câmpul de introducere."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intrarea în câmpul de introducere nu este identică cu adresa de e-mail dată mai sus."])}
          }
        }
      },
      "ru-RU": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для подтверждения введите адрес электронной почты ниже в поле ввода."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запись в поле ввода не идентична указанному выше адресу электронной почты."])}
          }
        }
      },
      "sv-SE": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["För att bekräfta, ange e-postadressen nedan i inmatningsfältet."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inmatningen i inmatningsfältet är inte identisk med e-postadressen ovan."])}
          }
        }
      },
      "sk-SK": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre potvrdenie zadajte e-mailovú adresu nižšie do vstupného poľa."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Záznam vo vstupnom poli nie je identický s e-mailovou adresou uvedenou vyššie."])}
          }
        }
      },
      "cs-CZ": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro potvrzení zadejte e-mailovou adresu níže do vstupního pole."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Záznam ve vstupním poli není shodný s výše uvedenou e-mailovou adresou."])}
          }
        }
      },
      "tr-TR": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylamak için aşağıdaki giriş alanına e-posta adresini girin."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş alanındaki giriş, yukarıda verilen e-posta adresiyle aynı değil."])}
          }
        }
      },
      "hu-HU": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megerősítéshez írja be az alábbi e-mail címet a beviteli mezőbe."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beviteli mezőben szereplő bejegyzés nem egyezik meg a fent megadott e-mail címmel."])}
          }
        }
      },
      "ar-AE": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للتأكيد ، أدخل عنوان البريد الإلكتروني أدناه في حقل الإدخال."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإدخال في حقل الإدخال غير مطابق لعنوان البريد الإلكتروني الوارد أعلاه."])}
          }
        }
      },
      "fr-BE": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour confirmer, entrez l'adresse e-mail ci-dessous dans le champ de saisie."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'entrée dans le champ de saisie n'est pas identique à l'adresse e-mail indiquée ci-dessus."])}
          }
        }
      },
      "fr-LU": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour confirmer, entrez l'adresse e-mail ci-dessous dans le champ de saisie."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'entrée dans le champ de saisie n'est pas identique à l'adresse e-mail indiquée ci-dessus."])}
          }
        }
      },
      "mt-MT": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biex tikkonferma, daħħal l-indirizz tal-email hawn taħt fil-qasam tal-input."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id-dħul fil-qasam tad-dħul mhuwiex identiku għall-indirizz elettroniku mogħti hawn fuq."])}
          }
        }
      },
      "sl-SI": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za potrditev vnesite spodnji e-poštni naslov v vnosno polje."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnos v vnosno polje ni enak zgoraj navedenemu e-poštnemu naslovu."])}
          }
        }
      },
      "el-CY": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για επιβεβαίωση, εισαγάγετε τη διεύθυνση email παρακάτω στο πεδίο εισαγωγής."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η καταχώρηση στο πεδίο εισαγωγής δεν είναι πανομοιότυπη με τη διεύθυνση email που δίνεται παραπάνω."])}
          }
        }
      },
      "tr-CY": {
        "pleaseConfirm": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylamak için aşağıdaki giriş alanına e-posta adresini girin."])}
        },
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş alanındaki giriş, yukarıda verilen e-posta adresiyle aynı değil."])}
          }
        }
      }
    }
  })
}
