export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de-DE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cl.components.Inputs.DpDatePickerCustom.input.placeholder"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cl.components.Inputs.DpDatePickerCustom.input.label"])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib ein gültiges Datum ein."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigen Eintrag eingeben."])}
          }
        }
      },
      "de-AT": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib ein gültiges Datum ein."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigen Eintrag eingeben."])}
          }
        }
      },
      "de-CH": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib ein gültiges Datum ein."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigen Eintrag eingeben."])}
          }
        }
      },
      "en-US": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cl.components.Inputs.DpDatePickerCustom.input.placeholder"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cl.components.Inputs.DpDatePickerCustom.input.label"])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid date."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid entry."])}
          }
        }
      },
      "en-GB": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid date."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid entry."])}
          }
        }
      },
      "it-IT": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci una data valida."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di inserire una voce valida."])}
          }
        }
      },
      "fr-FR": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer une date valide."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez une entrée valide."])}
          }
        }
      },
      "es-ES": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor introduzca una fecha valida."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese una entrada válida."])}
          }
        }
      },
      "pl-PL": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proszę wprowadź poprawną datę."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź prawidłowy wpis."])}
          }
        }
      },
      "nl-NL": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul alstublieft een geldige datum in."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer een geldige invoer in."])}
          }
        }
      },
      "bg-BG": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моля, въведете валидна дата."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете валиден запис."])}
          }
        }
      },
      "bs-BA": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite valjani datum."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite valjani unos."])}
          }
        }
      },
      "zh-CN": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入一个有效的日期。"])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入有效的条目。"])}
          }
        }
      },
      "da-DK": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast venligst en gyldig dato."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast en gyldig post."])}
          }
        }
      },
      "et-EE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage kehtiv kuupäev."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage kehtiv kirje."])}
          }
        }
      },
      "fi-FI": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna kelvollinen päivämäärä."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna kelvollinen merkintä."])}
          }
        }
      },
      "el-GR": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλώ εισάγετε μία έγκυρη ημερομηνία."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε μια έγκυρη καταχώριση."])}
          }
        }
      },
      "he-IL": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["נא להזין תאריך חוקי."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אנא הזן ערך חוקי."])}
          }
        }
      },
      "ga-IE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iontráil dáta bailí le do thoil."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iontráil iontráil bhailí."])}
          }
        }
      },
      "ja-JP": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cl.components.Inputs.DpDatePickerCustom.input.placeholder"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cl.components.Inputs.DpDatePickerCustom.input.label"])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効な日付を入力してください。"])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なエントリを入力してください。"])}
          }
        }
      },
      "hr-HR": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite valjani datum."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite valjani unos."])}
          }
        }
      },
      "lv-LV": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lūdzu, ievadiet derīgu datumu."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ievadiet derīgu ierakstu."])}
          }
        }
      },
      "lt-LT": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite galiojančią datą."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite tinkamą įrašą."])}
          }
        }
      },
      "pt-PT": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor insira uma data válida."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira uma entrada válida."])}
          }
        }
      },
      "ro-RO": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă rugăm să introduceți o dată validă."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă rugăm să introduceți o intrare validă."])}
          }
        }
      },
      "ru-RU": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите правильную дату."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите действительную запись."])}
          }
        }
      },
      "sv-SE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vänligen ange ett giltigt datum."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange en giltig post."])}
          }
        }
      },
      "sk-SK": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte platný dátum."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte platný údaj."])}
          }
        }
      },
      "cs-CZ": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím vložte správné datum."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte prosím platnou položku."])}
          }
        }
      },
      "tr-TR": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir tarih giriniz."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli bir giriş girin."])}
          }
        }
      },
      "hu-HU": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, adjon meg egy érvényes dátumot."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon meg egy érvényes bejegyzést."])}
          }
        }
      },
      "ar-AE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارجوك ادخل تاريخ صحيح."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل إدخالاً صالحًا."])}
          }
        }
      },
      "fr-BE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer une date valide."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez une entrée valide."])}
          }
        }
      },
      "fr-LU": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer une date valide."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez une entrée valide."])}
          }
        }
      },
      "mt-MT": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cl.components.Inputs.DpDatePickerCustom.input.placeholder"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cl.components.Inputs.DpDatePickerCustom.input.label"])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jekk jogħġbok daħħal data valida."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daħħal dħul validu."])}
          }
        }
      },
      "sl-SI": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnesite veljaven datum."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnesite veljaven vnos."])}
          }
        }
      },
      "el-CY": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλώ εισάγετε μία έγκυρη ημερομηνία."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε μια έγκυρη καταχώριση."])}
          }
        }
      },
      "tr-CY": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "inputValidation": {
          "invalidDate": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir tarih giriniz."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli bir giriş girin."])}
          }
        }
      }
    }
  })
}
