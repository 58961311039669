export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de-DE": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum auswählen"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfangsdatum auswählen"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfangsdatum"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigen Eintrag eingeben."])}
          }
        }
      },
      "de-AT": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum auswählen"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfangsdatum auswählen"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfangsdatum"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigen Eintrag eingeben."])}
          }
        }
      },
      "de-CH": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum auswählen"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfangsdatum auswählen"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfangsdatum"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigen Eintrag eingeben."])}
          }
        }
      },
      "en-US": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose end date"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose start date"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid entry."])}
          }
        }
      },
      "en-GB": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose end date"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose start date"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid entry."])}
          }
        }
      },
      "it-IT": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli la data di fine"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di fine"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli la data di inizio"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data d'inizio"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di inserire una voce valida."])}
          }
        }
      },
      "fr-FR": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la date de fin"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la date de début"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez une entrée valide."])}
          }
        }
      },
      "es-ES": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija la fecha de finalización"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha final"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija la fecha de inicio"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de inicio"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese una entrada válida."])}
          }
        }
      },
      "pl-PL": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz datę zakończenia"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data końcowa"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz datę rozpoczęcia"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data rozpoczęcia"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź prawidłowy wpis."])}
          }
        }
      },
      "nl-NL": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een einddatum"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einddatum"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies startdatum"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer een geldige invoer in."])}
          }
        }
      },
      "bg-BG": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете крайна дата"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крайна дата"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете начална дата"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начална дата"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете валиден запис."])}
          }
        }
      },
      "bs-BA": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite datum završetka"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum završetka"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite datum početka"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum početka"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite valjani unos."])}
          }
        }
      },
      "zh-CN": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择结束日期"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结束日期"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择开始日期"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始日期"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入有效的条目。"])}
          }
        }
      },
      "da-DK": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg slutdato"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slutdato"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg startdato"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start dato"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast en gyldig post."])}
          }
        }
      },
      "et-EE": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valige lõppkuupäev"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lõppkuupäev"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valige alguskuupäev"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algus kuupäev"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage kehtiv kirje."])}
          }
        }
      },
      "fi-FI": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse lopetuspäivä"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päättymispäivä"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse aloituspäivä"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aloituspäivämäärä"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna kelvollinen merkintä."])}
          }
        }
      },
      "el-GR": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε ημερομηνία λήξης"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία λήξης"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε ημερομηνία έναρξης"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία έναρξης"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε μια έγκυρη καταχώριση."])}
          }
        }
      },
      "he-IL": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בחר תאריך סיום"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תאריך סיום"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בחר תאריך התחלה"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תאריך התחלה"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אנא הזן ערך חוקי."])}
          }
        }
      },
      "ga-IE": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roghnaigh an dáta deiridh"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dáta deiridh"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roghnaigh an dáta tosaigh"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dáta tosaigh"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iontráil iontráil bhailí."])}
          }
        }
      },
      "ja-JP": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了日を選択してください"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了日"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始日を選択してください"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始日"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なエントリを入力してください。"])}
          }
        }
      },
      "hr-HR": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite datum završetka"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum završetka"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite datum početka"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Početni datum"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite valjani unos."])}
          }
        }
      },
      "lv-LV": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties beigu datumu"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beigu datums"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties sākuma datumu"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sākuma datums"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ievadiet derīgu ierakstu."])}
          }
        }
      },
      "lt-LT": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkite pabaigos datą"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pabaigos data"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkite pradžios datą"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pradžios data"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite tinkamą įrašą."])}
          }
        }
      },
      "pt-PT": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha a data de término"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data final"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha a data de início"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de início"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira uma entrada válida."])}
          }
        }
      },
      "ro-RO": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alegeți data de încheiere"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de încheiere"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alegeți data de începere"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de început"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă rugăm să introduceți o intrare validă."])}
          }
        }
      },
      "ru-RU": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите дату окончания"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите дату начала"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата начала"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите действительную запись."])}
          }
        }
      },
      "sv-SE": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj slutdatum"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slutdatum"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj startdatum"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start datum"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange en giltig post."])}
          }
        }
      },
      "sk-SK": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte dátum ukončenia"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum ukončenia"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte dátum začatia"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum začiatku"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte platný údaj."])}
          }
        }
      },
      "cs-CZ": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte datum ukončení"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum ukončení"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte datum zahájení"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počáteční datum"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte prosím platnou položku."])}
          }
        }
      },
      "tr-TR": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitiş tarihini seçin"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitiş tarihi"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlangıç tarihini seçin"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlangıç tarihi"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli bir giriş girin."])}
          }
        }
      },
      "hu-HU": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassza ki a befejezés dátumát"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befejezés dátuma"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassza ki a kezdő dátumot"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kezdő dátum"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon meg egy érvényes bejegyzést."])}
          }
        }
      },
      "ar-AE": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر تاريخ الانتهاء"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الانتهاء"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر تاريخ البدء"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ البدء"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل إدخالاً صالحًا."])}
          }
        }
      },
      "fr-BE": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la date de fin"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la date de début"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez une entrée valide."])}
          }
        }
      },
      "fr-LU": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la date de fin"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la date de début"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez une entrée valide."])}
          }
        }
      },
      "mt-MT": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agħżel id-data tat-tmiem"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data tat-tmiem"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agħżel id-data tal-bidu"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id-data tal-bidu"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daħħal dħul validu."])}
          }
        }
      },
      "sl-SI": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izberite končni datum"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Končni datum"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izberite datum začetka"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začetni datum"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnesite veljaven vnos."])}
          }
        }
      },
      "el-CY": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε ημερομηνία λήξης"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία λήξης"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε ημερομηνία έναρξης"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία έναρξης"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε μια έγκυρη καταχώριση."])}
          }
        }
      },
      "tr-CY": {
        "input": {
          "endDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitiş tarihini seçin"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitiş tarihi"])}
          },
          "startDate": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlangıç tarihini seçin"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlangıç tarihi"])}
          }
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli bir giriş girin."])}
          }
        }
      }
    }
  })
}
