export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de-DE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungscode"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungscode"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib einen gültigen Authentifizierungscode ein."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib einen gültigen Authentifizierungscode ein."])}
          }
        }
      },
      "de-AT": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungscode"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungscode"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib einen gültigen Authentifizierungscode ein."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib einen gültigen Authentifizierungscode ein."])}
          }
        }
      },
      "de-CH": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungscode"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungscode"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib einen gültigen Authentifizierungscode ein."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib einen gültigen Authentifizierungscode ein."])}
          }
        }
      },
      "en-US": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication code"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication code"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid authentication code."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid authentication code."])}
          }
        }
      },
      "en-GB": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication code"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication code"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid authentication code."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid authentication code."])}
          }
        }
      },
      "it-IT": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice di autenticazione"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice di autenticazione"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci un codice di autenticazione valido."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci un codice di autenticazione valido."])}
          }
        }
      },
      "fr-FR": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d'authentification"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d'authentification"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un code d'authentification valide."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un code d'authentification valide."])}
          }
        }
      },
      "es-ES": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de autenticación"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de autenticación"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca un código de autenticación válido."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca un código de autenticación válido."])}
          }
        }
      },
      "pl-PL": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod weryfikacyjny"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod weryfikacyjny"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź prawidłowy kod uwierzytelniający."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź prawidłowy kod uwierzytelniający."])}
          }
        }
      },
      "nl-NL": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticatie code"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticatie code"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer een geldige verificatiecode in."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer een geldige verificatiecode in."])}
          }
        }
      },
      "bg-BG": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код за удостоверяване"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код за удостоверяване"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моля, въведете валиден код за удостоверяване."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моля, въведете валиден код за удостоверяване."])}
          }
        }
      },
      "bs-BA": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikacijski kôd"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikacijski kôd"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite važeći autentifikacijski kôd."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite važeći autentifikacijski kôd."])}
          }
        }
      },
      "zh-CN": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入有效的验证码。"])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入有效的验证码。"])}
          }
        }
      },
      "da-DK": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendelseskode"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendelseskode"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast en gyldig godkendelseskode."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast en gyldig godkendelseskode."])}
          }
        }
      },
      "et-EE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentimiskood"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentimiskood"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage kehtiv autentimiskood."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage kehtiv autentimiskood."])}
          }
        }
      },
      "fi-FI": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todennuskoodi"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todennuskoodi"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syötä voimassaoleva todennuskoodi."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syötä voimassaoleva todennuskoodi."])}
          }
        }
      },
      "el-GR": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός ελέγχου ταυτότητας"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός ελέγχου ταυτότητας"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε έναν έγκυρο κωδικό ελέγχου ταυτότητας."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε έναν έγκυρο κωδικό ελέγχου ταυτότητας."])}
          }
        }
      },
      "he-IL": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קוד אימות"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קוד אימות"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אנא הזן קוד אימות חוקי."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אנא הזן קוד אימות חוקי."])}
          }
        }
      },
      "ga-IE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cód fíordheimhnithe"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cód fíordheimhnithe"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iontráil cód fíordheimhnithe bailí."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iontráil cód fíordheimhnithe bailí."])}
          }
        }
      },
      "ja-JP": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コード"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コード"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効な認証コードを入力してください。"])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効な認証コードを入力してください。"])}
          }
        }
      },
      "hr-HR": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikacijski kod"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikacijski kod"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite važeći autentifikacijski kôd."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite važeći autentifikacijski kôd."])}
          }
        }
      },
      "lv-LV": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikācijas kods"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikācijas kods"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lūdzu, ievadiet derīgu autentifikācijas kodu."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lūdzu, ievadiet derīgu autentifikācijas kodu."])}
          }
        }
      },
      "lt-LT": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikavimo kodas"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikavimo kodas"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite galiojantį autentifikavimo kodą."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite galiojantį autentifikavimo kodą."])}
          }
        }
      },
      "pt-PT": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de Autenticação"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de Autenticação"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduza um código de autenticação válido."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduza um código de autenticação válido."])}
          }
        }
      },
      "ro-RO": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cod de autentificare"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cod de autentificare"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți un cod de autentificare corect."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți un cod de autentificare corect."])}
          }
        }
      },
      "ru-RU": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код аутентификации"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код аутентификации"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите действительный код аутентификации."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите действительный код аутентификации."])}
          }
        }
      },
      "sv-SE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringskod"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringskod"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange en giltig autentiseringskod."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange en giltig autentiseringskod."])}
          }
        }
      },
      "sk-SK": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overovací kód"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overovací kód"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte prosím platný overovací kód."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte prosím platný overovací kód."])}
          }
        }
      },
      "cs-CZ": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřovací kód"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřovací kód"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte platný ověřovací kód."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte platný ověřovací kód."])}
          }
        }
      },
      "tr-TR": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir kimlik doğrulama kodu girin."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir kimlik doğrulama kodu girin."])}
          }
        }
      },
      "hu-HU": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitelesítő kód"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitelesítő kód"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, érvényes hitelesítő kódot adjon meg."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, érvényes hitelesítő kódot adjon meg."])}
          }
        }
      },
      "ar-AE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز المصادقة"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز المصادقة"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال رمز مصادقة صالح."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال رمز مصادقة صالح."])}
          }
        }
      },
      "fr-BE": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d'authentification"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d'authentification"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un code d'authentification valide."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un code d'authentification valide."])}
          }
        }
      },
      "fr-LU": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d'authentification"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d'authentification"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un code d'authentification valide."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un code d'authentification valide."])}
          }
        }
      },
      "mt-MT": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kodiċi ta' Awtentikazzjoni"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kodiċi ta' Awtentikazzjoni"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jekk jogħġbok daħħal kodiċi ta' awtentikazzjoni validu."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jekk jogħġbok daħħal kodiċi ta' awtentikazzjoni validu."])}
          }
        }
      },
      "sl-SI": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koda za preverjanje pristnosti"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koda za preverjanje pristnosti"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnesite veljavno kodo za preverjanje pristnosti."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnesite veljavno kodo za preverjanje pristnosti."])}
          }
        }
      },
      "el-CY": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός ελέγχου ταυτότητας"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός ελέγχου ταυτότητας"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε έναν έγκυρο κωδικό ελέγχου ταυτότητας."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε έναν έγκυρο κωδικό ελέγχου ταυτότητας."])}
          }
        }
      },
      "tr-CY": {
        "input": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama kodu"])}
        },
        "inputValidation": {
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir kimlik doğrulama kodu girin."])}
          },
          "regEx": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir kimlik doğrulama kodu girin."])}
          }
        }
      }
    }
  })
}
