(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@dutypay/store-modules"), require("numeral"), require("pinia"), require("vue-i18n"), require("lodash"), require("vue"));
	else if(typeof define === 'function' && define.amd)
		define(["@dutypay/store-modules", "numeral", "pinia", "vue-i18n", "lodash", ], factory);
	else if(typeof exports === 'object')
		exports["components-library"] = factory(require("@dutypay/store-modules"), require("numeral"), require("pinia"), require("vue-i18n"), require("lodash"), require("vue"));
	else
		root["components-library"] = factory(root["@dutypay/store-modules"], root["numeral"], root["pinia"], root["vue-i18n"], root["_"], root["Vue"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__2706__, __WEBPACK_EXTERNAL_MODULE__3033__, __WEBPACK_EXTERNAL_MODULE__3085__, __WEBPACK_EXTERNAL_MODULE__1013__, __WEBPACK_EXTERNAL_MODULE__7607__, __WEBPACK_EXTERNAL_MODULE__7203__) {
return 