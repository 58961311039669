import each from 'lodash/each';
import startsWith from 'lodash/startsWith';
import {useTrimNumber} from "./trimNumber";

export function useHasValidCountryCallingCode (jurisdictionsList, number) {
  number = useTrimNumber(number);
  let hasValidCountryCallingCode = false;
  each(jurisdictionsList, (jurisdiction) => {
    if (startsWith(number, jurisdiction.callingCode)) {
      hasValidCountryCallingCode = true;
    }
  });
  return hasValidCountryCallingCode;
}

