<script setup>
import InputBasis from "./InputBasis.vue";
</script>
<script>
import isEmpty from 'lodash/isEmpty';

export default {
  name: "DpInputMultiFactorAuthenticationCode",
  data() {
    return {
      translationReportAlias: {
        label: this.$t('input.label'),
        placeholder: this.$t('input.placeholder'),
        tooltip: this.$t('input.tooltip'),
        description: this.$t('input.description')
      }
    };
  },
  methods: {
    validate(rule, value, callback) {
      if (!isEmpty(value))
        value = value.trim();
      if (value === '') {
        callback(new Error(this.$t('inputValidation.emptyField.message')));
      } else if (!/[0-9]{6}/.test(value) || value.length !== 6) {
        callback(new Error(this.$t('inputValidation.regEx.message')));
      } else {
        callback();
      }
    }
  }
}
</script>

<template>
  <InputBasis :rules="[{ validator: validate, trigger: ['blur', 'change'] }]" />
</template>

<i18n>
{
  "de-DE": {
    "input": {
      "placeholder": "Authentifizierungscode",
      "label": "Authentifizierungscode"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Bitte gib einen gültigen Authentifizierungscode ein."
      },
      "regEx": {
        "message": "Bitte gib einen gültigen Authentifizierungscode ein."
      }
    }
  },
  "de-AT": {
    "input": {
      "placeholder": "Authentifizierungscode",
      "label": "Authentifizierungscode"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Bitte gib einen gültigen Authentifizierungscode ein."
      },
      "regEx": {
        "message": "Bitte gib einen gültigen Authentifizierungscode ein."
      }
    }
  },
  "de-CH": {
    "input": {
      "placeholder": "Authentifizierungscode",
      "label": "Authentifizierungscode"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Bitte gib einen gültigen Authentifizierungscode ein."
      },
      "regEx": {
        "message": "Bitte gib einen gültigen Authentifizierungscode ein."
      }
    }
  },
  "en-US": {
    "input": {
      "placeholder": "Authentication code",
      "label": "Authentication code"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Please enter a valid authentication code."
      },
      "regEx": {
        "message": "Please enter a valid authentication code."
      }
    }
  },
  "en-GB": {
    "input": {
      "placeholder": "Authentication code",
      "label": "Authentication code"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Please enter a valid authentication code."
      },
      "regEx": {
        "message": "Please enter a valid authentication code."
      }
    }
  },
  "it-IT": {
    "input": {
      "placeholder": "Codice di autenticazione",
      "label": "Codice di autenticazione"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Inserisci un codice di autenticazione valido."
      },
      "regEx": {
        "message": "Inserisci un codice di autenticazione valido."
      }
    }
  },
  "fr-FR": {
    "input": {
      "placeholder": "Code d'authentification",
      "label": "Code d'authentification"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Veuillez entrer un code d'authentification valide."
      },
      "regEx": {
        "message": "Veuillez entrer un code d'authentification valide."
      }
    }
  },
  "es-ES": {
    "input": {
      "placeholder": "Código de autenticación",
      "label": "Código de autenticación"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Introduzca un código de autenticación válido."
      },
      "regEx": {
        "message": "Introduzca un código de autenticación válido."
      }
    }
  },
  "pl-PL": {
    "input": {
      "placeholder": "Kod weryfikacyjny",
      "label": "Kod weryfikacyjny"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Wprowadź prawidłowy kod uwierzytelniający."
      },
      "regEx": {
        "message": "Wprowadź prawidłowy kod uwierzytelniający."
      }
    }
  },
  "nl-NL": {
    "input": {
      "placeholder": "Authenticatie code",
      "label": "Authenticatie code"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Voer een geldige verificatiecode in."
      },
      "regEx": {
        "message": "Voer een geldige verificatiecode in."
      }
    }
  },
  "bg-BG": {
    "input": {
      "placeholder": "Код за удостоверяване",
      "label": "Код за удостоверяване"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Моля, въведете валиден код за удостоверяване."
      },
      "regEx": {
        "message": "Моля, въведете валиден код за удостоверяване."
      }
    }
  },
  "bs-BA": {
    "input": {
      "placeholder": "Autentifikacijski kôd",
      "label": "Autentifikacijski kôd"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Unesite važeći autentifikacijski kôd."
      },
      "regEx": {
        "message": "Unesite važeći autentifikacijski kôd."
      }
    }
  },
  "zh-CN": {
    "input": {
      "placeholder": "验证码",
      "label": "验证码"
    },
    "inputValidation": {
      "emptyField": {
        "message": "请输入有效的验证码。"
      },
      "regEx": {
        "message": "请输入有效的验证码。"
      }
    }
  },
  "da-DK": {
    "input": {
      "placeholder": "Godkendelseskode",
      "label": "Godkendelseskode"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Indtast en gyldig godkendelseskode."
      },
      "regEx": {
        "message": "Indtast en gyldig godkendelseskode."
      }
    }
  },
  "et-EE": {
    "input": {
      "placeholder": "Autentimiskood",
      "label": "Autentimiskood"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Sisestage kehtiv autentimiskood."
      },
      "regEx": {
        "message": "Sisestage kehtiv autentimiskood."
      }
    }
  },
  "fi-FI": {
    "input": {
      "placeholder": "Todennuskoodi",
      "label": "Todennuskoodi"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Syötä voimassaoleva todennuskoodi."
      },
      "regEx": {
        "message": "Syötä voimassaoleva todennuskoodi."
      }
    }
  },
  "el-GR": {
    "input": {
      "placeholder": "Κωδικός ελέγχου ταυτότητας",
      "label": "Κωδικός ελέγχου ταυτότητας"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Εισαγάγετε έναν έγκυρο κωδικό ελέγχου ταυτότητας."
      },
      "regEx": {
        "message": "Εισαγάγετε έναν έγκυρο κωδικό ελέγχου ταυτότητας."
      }
    }
  },
  "he-IL": {
    "input": {
      "placeholder": "קוד אימות",
      "label": "קוד אימות"
    },
    "inputValidation": {
      "emptyField": {
        "message": "אנא הזן קוד אימות חוקי."
      },
      "regEx": {
        "message": "אנא הזן קוד אימות חוקי."
      }
    }
  },
  "ga-IE": {
    "input": {
      "placeholder": "Cód fíordheimhnithe",
      "label": "Cód fíordheimhnithe"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Iontráil cód fíordheimhnithe bailí."
      },
      "regEx": {
        "message": "Iontráil cód fíordheimhnithe bailí."
      }
    }
  },
  "ja-JP": {
    "input": {
      "placeholder": "認証コード",
      "label": "認証コード"
    },
    "inputValidation": {
      "emptyField": {
        "message": "有効な認証コードを入力してください。"
      },
      "regEx": {
        "message": "有効な認証コードを入力してください。"
      }
    }
  },
  "hr-HR": {
    "input": {
      "placeholder": "Autentifikacijski kod",
      "label": "Autentifikacijski kod"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Unesite važeći autentifikacijski kôd."
      },
      "regEx": {
        "message": "Unesite važeći autentifikacijski kôd."
      }
    }
  },
  "lv-LV": {
    "input": {
      "placeholder": "Autentifikācijas kods",
      "label": "Autentifikācijas kods"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Lūdzu, ievadiet derīgu autentifikācijas kodu."
      },
      "regEx": {
        "message": "Lūdzu, ievadiet derīgu autentifikācijas kodu."
      }
    }
  },
  "lt-LT": {
    "input": {
      "placeholder": "Autentifikavimo kodas",
      "label": "Autentifikavimo kodas"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Įveskite galiojantį autentifikavimo kodą."
      },
      "regEx": {
        "message": "Įveskite galiojantį autentifikavimo kodą."
      }
    }
  },
  "pt-PT": {
    "input": {
      "placeholder": "Código de Autenticação",
      "label": "Código de Autenticação"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Introduza um código de autenticação válido."
      },
      "regEx": {
        "message": "Introduza um código de autenticação válido."
      }
    }
  },
  "ro-RO": {
    "input": {
      "placeholder": "Cod de autentificare",
      "label": "Cod de autentificare"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Introduceți un cod de autentificare corect."
      },
      "regEx": {
        "message": "Introduceți un cod de autentificare corect."
      }
    }
  },
  "ru-RU": {
    "input": {
      "placeholder": "Код аутентификации",
      "label": "Код аутентификации"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Пожалуйста, введите действительный код аутентификации."
      },
      "regEx": {
        "message": "Пожалуйста, введите действительный код аутентификации."
      }
    }
  },
  "sv-SE": {
    "input": {
      "placeholder": "Autentiseringskod",
      "label": "Autentiseringskod"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Ange en giltig autentiseringskod."
      },
      "regEx": {
        "message": "Ange en giltig autentiseringskod."
      }
    }
  },
  "sk-SK": {
    "input": {
      "placeholder": "Overovací kód",
      "label": "Overovací kód"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Zadajte prosím platný overovací kód."
      },
      "regEx": {
        "message": "Zadajte prosím platný overovací kód."
      }
    }
  },
  "cs-CZ": {
    "input": {
      "placeholder": "Ověřovací kód",
      "label": "Ověřovací kód"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Zadejte platný ověřovací kód."
      },
      "regEx": {
        "message": "Zadejte platný ověřovací kód."
      }
    }
  },
  "tr-TR": {
    "input": {
      "placeholder": "Kimlik doğrulama kodu",
      "label": "Kimlik doğrulama kodu"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Lütfen geçerli bir kimlik doğrulama kodu girin."
      },
      "regEx": {
        "message": "Lütfen geçerli bir kimlik doğrulama kodu girin."
      }
    }
  },
  "hu-HU": {
    "input": {
      "placeholder": "Hitelesítő kód",
      "label": "Hitelesítő kód"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Kérjük, érvényes hitelesítő kódot adjon meg."
      },
      "regEx": {
        "message": "Kérjük, érvényes hitelesítő kódot adjon meg."
      }
    }
  },
  "ar-AE": {
    "input": {
      "placeholder": "رمز المصادقة",
      "label": "رمز المصادقة"
    },
    "inputValidation": {
      "emptyField": {
        "message": "الرجاء إدخال رمز مصادقة صالح."
      },
      "regEx": {
        "message": "الرجاء إدخال رمز مصادقة صالح."
      }
    }
  },
  "fr-BE": {
    "input": {
      "placeholder": "Code d'authentification",
      "label": "Code d'authentification"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Veuillez entrer un code d'authentification valide."
      },
      "regEx": {
        "message": "Veuillez entrer un code d'authentification valide."
      }
    }
  },
  "fr-LU": {
    "input": {
      "placeholder": "Code d'authentification",
      "label": "Code d'authentification"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Veuillez entrer un code d'authentification valide."
      },
      "regEx": {
        "message": "Veuillez entrer un code d'authentification valide."
      }
    }
  },
  "mt-MT": {
    "input": {
      "placeholder": "Kodiċi ta' Awtentikazzjoni",
      "label": "Kodiċi ta' Awtentikazzjoni"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Jekk jogħġbok daħħal kodiċi ta' awtentikazzjoni validu."
      },
      "regEx": {
        "message": "Jekk jogħġbok daħħal kodiċi ta' awtentikazzjoni validu."
      }
    }
  },
  "sl-SI": {
    "input": {
      "placeholder": "Koda za preverjanje pristnosti",
      "label": "Koda za preverjanje pristnosti"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Vnesite veljavno kodo za preverjanje pristnosti."
      },
      "regEx": {
        "message": "Vnesite veljavno kodo za preverjanje pristnosti."
      }
    }
  },
  "el-CY": {
    "input": {
      "placeholder": "Κωδικός ελέγχου ταυτότητας",
      "label": "Κωδικός ελέγχου ταυτότητας"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Εισαγάγετε έναν έγκυρο κωδικό ελέγχου ταυτότητας."
      },
      "regEx": {
        "message": "Εισαγάγετε έναν έγκυρο κωδικό ελέγχου ταυτότητας."
      }
    }
  },
  "tr-CY": {
    "input": {
      "placeholder": "Kimlik doğrulama kodu",
      "label": "Kimlik doğrulama kodu"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Lütfen geçerli bir kimlik doğrulama kodu girin."
      },
      "regEx": {
        "message": "Lütfen geçerli bir kimlik doğrulama kodu girin."
      }
    }
  }
}
</i18n>

