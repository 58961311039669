export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de-DE": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib eine gültige E-Mail-Adresse ein."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib eine gültige E-Mail-Adresse ein."])}
          }
        }
      },
      "de-AT": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cl.components.Inputs.DpInputEmailAddress.input.tooltip"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib eine gültige E-Mail-Adresse ein."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib eine gültige E-Mail-Adresse ein."])}
          }
        }
      },
      "de-CH": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cl.components.Inputs.DpInputEmailAddress.input.tooltip"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib eine gültige E-Mail-Adresse ein."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib eine gültige E-Mail-Adresse ein."])}
          }
        }
      },
      "en-US": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid e-mail address."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid e-mail address."])}
          }
        }
      },
      "en-GB": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid e-mail address."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid e-mail address."])}
          }
        }
      },
      "it-IT": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo e-mail"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo e-mail"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci un indirizzo e-mail valido."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci un indirizzo e-mail valido."])}
          }
        }
      },
      "fr-FR": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir une adresse mail valide."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir une adresse mail valide."])}
          }
        }
      },
      "es-ES": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de correo electrónico"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de correo electrónico"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca una dirección de correo electrónico válida."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca una dirección de correo electrónico válida."])}
          }
        }
      },
      "pl-PL": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres e-mail"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres e-mail"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpisz prawidłowy adres e-mail."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpisz prawidłowy adres e-mail."])}
          }
        }
      },
      "nl-NL": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer een geldig e-mailadres in."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer een geldig e-mailadres in."])}
          }
        }
      },
      "bg-BG": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна поща"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имейл адрес"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имейл адрес"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моля, въведете валиден имейл адрес."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моля, въведете валиден имейл адрес."])}
          }
        }
      },
      "bs-BA": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa e-pošte"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa e-pošte"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molimo Vas da unesete važeću adresu e-pošte."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molimo Vas da unesete važeću adresu e-pošte."])}
          }
        }
      },
      "zh-CN": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件地址"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件地址"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入有效的电子邮件地址。"])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入有效的电子邮件地址。"])}
          }
        }
      },
      "da-DK": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadresse"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadresse"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast en gyldig e-mailadresse."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast en gyldig e-mailadresse."])}
          }
        }
      },
      "et-EE": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-post"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posti aadress"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posti aadress"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palun sisesta kehtiv e-posti aadress."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palun sisesta kehtiv e-posti aadress."])}
          }
        }
      },
      "fi-FI": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköposti"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköpostiosoite"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköpostiosoite"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna voimassa oleva sähköpostiosoite."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna voimassa oleva sähköpostiosoite."])}
          }
        }
      },
      "el-GR": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διεύθυνση ηλεκτρονικού ταχυδρομείου"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διεύθυνση ηλεκτρονικού ταχυδρομείου"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλώ εισαγάγετε μια έγκυρη διεύθυνση ηλεκτρονικού ταχυδρομείου."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλώ εισαγάγετε μια έγκυρη διεύθυνση ηλεκτρονικού ταχυδρομείου."])}
          }
        }
      },
      "he-IL": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אימייל"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כתובת דואל"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כתובת דואל"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אנא הכנס כתובת דואר אלקטרוני תקינה."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אנא הכנס כתובת דואר אלקטרוני תקינה."])}
          }
        }
      },
      "ga-IE": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R-phost"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seoladh ríomhphoist"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seoladh ríomhphoist"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuir isteach seoladh ríomhphoist bailí."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuir isteach seoladh ríomhphoist bailí."])}
          }
        }
      },
      "ja-JP": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメール"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子メールアドレス"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子メールアドレス"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なメールアドレスを入力してください。"])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なメールアドレスを入力してください。"])}
          }
        }
      },
      "hr-HR": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-pošta"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa e-pošte"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa e-pošte"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite valjanu adresu e-pošte."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite valjanu adresu e-pošte."])}
          }
        }
      },
      "lv-LV": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-pasts"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-pasta adrese"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-pasta adrese"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lūdzu, ievadiet derīgu e-pasta adresi."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lūdzu, ievadiet derīgu e-pasta adresi."])}
          }
        }
      },
      "lt-LT": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El. Paštas"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektroninio pašto adresas"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektroninio pašto adresas"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prašom įvesti galiojantį elektroninio pašto adresą."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prašom įvesti galiojantį elektroninio pašto adresą."])}
          }
        }
      },
      "pt-PT": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de e-mail"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de e-mail"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduza um endereço de e-mail válido."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduza um endereço de e-mail válido."])}
          }
        }
      },
      "ro-RO": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa de e-mail"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa de e-mail"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți o adresă de e-mail validă."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți o adresă de e-mail validă."])}
          }
        }
      },
      "ru-RU": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эл. почта"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес электронной почты"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес электронной почты"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите действительный адрес электронной почты."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите действительный адрес электронной почты."])}
          }
        }
      },
      "sv-SE": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-post"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadress"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadress"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange en giltig e-postadress."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange en giltig e-postadress."])}
          }
        }
      },
      "sk-SK": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailová adresa"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailová adresa"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte prosím platnú e-mailovú adresu."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte prosím platnú e-mailovú adresu."])}
          }
        }
      },
      "cs-CZ": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailem"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailová adresa"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailová adresa"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím zadejte platnou -emailovou adresu."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím zadejte platnou -emailovou adresu."])}
          }
        }
      },
      "tr-TR": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta adresi"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta adresi"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir e-posta adresi girin."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir e-posta adresi girin."])}
          }
        }
      },
      "hu-HU": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail-cím"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail-cím"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érvényes e-mail-címet adjon meg."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érvényes e-mail-címet adjon meg."])}
          }
        }
      },
      "ar-AE": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان البريد الإلكتروني"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان البريد الإلكتروني"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال بريد إلكتروني صحيح."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال بريد إلكتروني صحيح."])}
          }
        }
      },
      "fr-BE": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir une adresse mail valide."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir une adresse mail valide."])}
          }
        }
      },
      "fr-LU": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir une adresse mail valide."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir une adresse mail valide."])}
          }
        }
      },
      "mt-MT": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizz tal-posta elettronika"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizz tal-posta elettronika"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jekk jogħġbok ikteb indirizz elettroniku validu."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jekk jogħġbok ikteb indirizz elettroniku validu."])}
          }
        }
      },
      "sl-SI": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-naslov"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-poštni naslov"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-poštni naslov"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnesite veljaven e-poštni naslov."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnesite veljaven e-poštni naslov."])}
          }
        }
      },
      "el-CY": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διεύθυνση ηλεκτρονικού ταχυδρομείου"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διεύθυνση ηλεκτρονικού ταχυδρομείου"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλώ εισαγάγετε μια έγκυρη διεύθυνση ηλεκτρονικού ταχυδρομείου."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλώ εισαγάγετε μια έγκυρη διεύθυνση ηλεκτρονικού ταχυδρομείου. "])}
          }
        }
      },
      "tr-CY": {
        "input": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta adresi"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta adresi"])}
        },
        "inputValidation": {
          "invalidEmail": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir e-posta adresi girin."])}
          },
          "emptyField": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir e-posta adresi girin."])}
          }
        }
      }
    }
  })
}
