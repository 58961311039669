<script setup>
import { inject, computed, defineProps, defineEmits, onBeforeMount } from 'vue';
const composables = inject('composables');
const { isRTL } = composables.useJurisdictionSwitch()
import dayjs from 'dayjs'

const props = defineProps({
  modelValue: {required: true},
  required: {type: Boolean, default: false},
  tooltip: {type: String, default: undefined},
  description: {type: String, default: undefined},
  label: {type: String},
  inputParameters: {type: Object}
})
const emit = defineEmits(['update', 'input', 'load'])
const input = composables.useTwoWayBinding(props, emit, 'modelValue')

import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const { locale } = useI18n({useScope: 'global'})
// eslint-disable-next-line no-unused-vars
const translationReportAlias = {
  tooltip: t('input.tooltip'),
  description: t('input.description')
};

function validate(rule, value, callback) {
  if (props.required !== true) return callback();
  if (value === null || value === '') {
    callback(new Error(t('inputValidation.emptyField.message')));
  } else if (!dayjs(value).isValid()) {
    callback(new Error(t('inputValidation.invalidDate.message')));
  } else {
    callback();
  }
}
function emitValue() {
  emit('input', input.value)
}

import { isEmpty, get } from 'lodash';
import {useEnvStore} from "@dutypay/store-modules";
const envStore = useEnvStore();
const clearable = computed(() => {
  return get(props.inputParameters, 'clearable', dayjs(input.value).isValid());
})
const formInputLabel = computed(() => {
  return (props.label) ? props.label : t('input.label');
})
const format = computed(() => {
  return get(props.inputParameters, 'format', envStore.dateTimeFormat[locale.value]);
})
const style = computed(() => {
  return get(props.inputParameters, 'style', 'width: 100%');
})
const placeholder = computed(() => {
  return get(props.inputParameters, 'placeholder', t('input.placeholder'));
})

onBeforeMount(() => {
  emit('load', formInputLabel.value)
});
</script>
<script>

  export default {
    name: "DpDateTimePickerCustom"
  }
</script>

<template>

  <el-form-item
      :class="{'is-required': required, 'right-to-left': isRTL}"
      v-bind="$attrs"
      :label="formInputLabel"
      :rules="[
        { validator: validate, trigger: ['blur', 'change'] }
      ]">
    <template #label>
      <span>{{formInputLabel}}</span>
      <span v-if="!isEmpty(tooltip)" class="form-item__description"><i class="el-icon-info"/> {{tooltip}}</span>
      <span v-if="!isEmpty(description)" class="form-item__description"><i class="el-icon-info"/> {{description}}</span>
    </template>

    <el-date-picker
        v-model="input"
        v-bind="inputParameters"
        type="datetime"
        :format="format"
        :style="style"
        :clearable="clearable"
        v-on:change="emitValue"
        :placeholder="placeholder">
    </el-date-picker>

  </el-form-item>

</template>

<i18n>
{
  "de-DE": {
    "input": {
      "placeholder": "Zeitpunkt auswählen",
      "label": "cl.components.Inputs.DpDateTimePickerCustom.input.label"
    },
    "inputValidation": {
      "emptyField": {
        "message": "Gültigen Eintrag eingeben."
      },
      "invalidDate": {
        "message": "Bitte gib ein gültiges Datum ein."
      }
    }
  },
  "de-AT": {
    "input": {
      "placeholder": "Zeitpunkt auswählen",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Gültigen Eintrag eingeben."
      },
      "invalidDate": {
        "message": "Bitte gib ein gültiges Datum ein."
      }
    }
  },
  "de-CH": {
    "input": {
      "placeholder": "Zeitpunkt auswählen",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Gültigen Eintrag eingeben."
      },
      "invalidDate": {
        "message": "Bitte gib ein gültiges Datum ein."
      }
    }
  },
  "en-US": {
    "input": {
      "placeholder": "Select time",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Enter a valid entry."
      },
      "invalidDate": {
        "message": "Please enter a valid date."
      }
    }
  },
  "en-GB": {
    "input": {
      "placeholder": "Select time",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Enter a valid entry."
      },
      "invalidDate": {
        "message": "Please enter a valid date."
      }
    }
  },
  "it-IT": {
    "input": {
      "placeholder": "Seleziona l'ora",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Si prega di inserire una voce valida."
      },
      "invalidDate": {
        "message": "Per favore, inserisci una data valida."
      }
    }
  },
  "fr-FR": {
    "input": {
      "placeholder": "Sélectionnez l'heure",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Saisissez une entrée valide."
      },
      "invalidDate": {
        "message": "Veuillez entrer une date valide."
      }
    }
  },
  "es-ES": {
    "input": {
      "placeholder": "Seleccionar hora",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Ingrese una entrada válida."
      },
      "invalidDate": {
        "message": "Por favor introduzca una fecha valida."
      }
    }
  },
  "pl-PL": {
    "input": {
      "placeholder": "Wybierz czas",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Wprowadź prawidłowy wpis."
      },
      "invalidDate": {
        "message": "Proszę wprowadź poprawną datę."
      }
    }
  },
  "nl-NL": {
    "input": {
      "placeholder": "Selecteer tijd",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Voer een geldige invoer in."
      },
      "invalidDate": {
        "message": "Vul alstublieft een geldige datum in."
      }
    }
  },
  "bg-BG": {
    "input": {
      "placeholder": "Изберете час",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Въведете валиден запис."
      },
      "invalidDate": {
        "message": "Моля, въведете валидна дата."
      }
    }
  },
  "bs-BA": {
    "input": {
      "placeholder": "Odaberite vrijeme",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Unesite valjani unos."
      },
      "invalidDate": {
        "message": "Unesite valjani datum."
      }
    }
  },
  "zh-CN": {
    "input": {
      "placeholder": "选择时间",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "输入有效的条目。"
      },
      "invalidDate": {
        "message": "请输入一个有效的日期。"
      }
    }
  },
  "da-DK": {
    "input": {
      "placeholder": "Vælg tid",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Indtast en gyldig post."
      },
      "invalidDate": {
        "message": "Indtast venligst en gyldig dato."
      }
    }
  },
  "et-EE": {
    "input": {
      "placeholder": "Valige aeg",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Sisestage kehtiv kirje."
      },
      "invalidDate": {
        "message": "Sisestage kehtiv kuupäev."
      }
    }
  },
  "fi-FI": {
    "input": {
      "placeholder": "Valitse aika",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Anna kelvollinen merkintä."
      },
      "invalidDate": {
        "message": "Anna kelvollinen päivämäärä."
      }
    }
  },
  "el-GR": {
    "input": {
      "placeholder": "Επιλέξτε ώρα",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Εισαγάγετε μια έγκυρη καταχώριση."
      },
      "invalidDate": {
        "message": "Παρακαλώ εισάγετε μία έγκυρη ημερομηνία."
      }
    }
  },
  "he-IL": {
    "input": {
      "placeholder": "בחר זמן",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "אנא הזן ערך חוקי."
      },
      "invalidDate": {
        "message": "נא להזין תאריך חוקי."
      }
    }
  },
  "ga-IE": {
    "input": {
      "placeholder": "Roghnaigh am",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Iontráil iontráil bhailí."
      },
      "invalidDate": {
        "message": "Iontráil dáta bailí le do thoil."
      }
    }
  },
  "ja-JP": {
    "input": {
      "placeholder": "時間を選択",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "有効なエントリを入力してください。"
      },
      "invalidDate": {
        "message": "有効な日付を入力してください。"
      }
    }
  },
  "hr-HR": {
    "input": {
      "placeholder": "Odaberite vrijeme",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Unesite valjani unos."
      },
      "invalidDate": {
        "message": "Unesite valjani datum."
      }
    }
  },
  "lv-LV": {
    "input": {
      "placeholder": "Atlasiet laiku",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Ievadiet derīgu ierakstu."
      },
      "invalidDate": {
        "message": "Lūdzu, ievadiet derīgu datumu."
      }
    }
  },
  "lt-LT": {
    "input": {
      "placeholder": "Pasirinkite laiką",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Įveskite tinkamą įrašą."
      },
      "invalidDate": {
        "message": "Įveskite galiojančią datą."
      }
    }
  },
  "pt-PT": {
    "input": {
      "placeholder": "Selecione o tempo",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Insira uma entrada válida."
      },
      "invalidDate": {
        "message": "Por favor insira uma data válida."
      }
    }
  },
  "ro-RO": {
    "input": {
      "placeholder": "Selectați ora",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Vă rugăm să introduceți o intrare validă."
      },
      "invalidDate": {
        "message": "Vă rugăm să introduceți o dată validă."
      }
    }
  },
  "ru-RU": {
    "input": {
      "placeholder": "Выберите время",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Введите действительную запись."
      },
      "invalidDate": {
        "message": "Пожалуйста, введите правильную дату."
      }
    }
  },
  "sv-SE": {
    "input": {
      "placeholder": "Välj tid",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Ange en giltig post."
      },
      "invalidDate": {
        "message": "Vänligen ange ett giltigt datum."
      }
    }
  },
  "sk-SK": {
    "input": {
      "placeholder": "Vyberte čas",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Zadajte platný údaj."
      },
      "invalidDate": {
        "message": "Zadajte platný dátum."
      }
    }
  },
  "cs-CZ": {
    "input": {
      "placeholder": "Vyberte čas",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Zadejte prosím platnou položku."
      },
      "invalidDate": {
        "message": "Prosím vložte správné datum."
      }
    }
  },
  "tr-TR": {
    "input": {
      "placeholder": "Saati seçin",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Geçerli bir giriş girin."
      },
      "invalidDate": {
        "message": "Lütfen geçerli bir tarih giriniz."
      }
    }
  },
  "hu-HU": {
    "input": {
      "placeholder": "Válassza ki az időt",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Adjon meg egy érvényes bejegyzést."
      },
      "invalidDate": {
        "message": "Kérjük, adjon meg egy érvényes dátumot."
      }
    }
  },
  "ar-AE": {
    "input": {
      "placeholder": "حدد الوقت",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "أدخل إدخالاً صالحًا."
      },
      "invalidDate": {
        "message": "ارجوك ادخل تاريخ صحيح."
      }
    }
  },
  "fr-BE": {
    "input": {
      "placeholder": "Sélectionnez l'heure",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Saisissez une entrée valide."
      },
      "invalidDate": {
        "message": "Veuillez entrer une date valide."
      }
    }
  },
  "fr-LU": {
    "input": {
      "placeholder": "Sélectionnez l'heure",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Saisissez une entrée valide."
      },
      "invalidDate": {
        "message": "Veuillez entrer une date valide."
      }
    }
  },
  "mt-MT": {
    "input": {
      "placeholder": "Agħżel il-ħin",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Daħħal dħul validu."
      },
      "invalidDate": {
        "message": "Jekk jogħġbok daħħal data valida."
      }
    }
  },
  "sl-SI": {
    "input": {
      "placeholder": "Izberite čas",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Vnesite veljaven vnos."
      },
      "invalidDate": {
        "message": "Vnesite veljaven datum."
      }
    }
  },
  "el-CY": {
    "input": {
      "placeholder": "Επιλέξτε ώρα",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Εισαγάγετε μια έγκυρη καταχώριση."
      },
      "invalidDate": {
        "message": "Παρακαλώ εισάγετε μία έγκυρη ημερομηνία."
      }
    }
  },
  "tr-CY": {
    "input": {
      "placeholder": "Saati seçin",
      "label": ""
    },
    "inputValidation": {
      "emptyField": {
        "message": "Geçerli bir giriş girin."
      },
      "invalidDate": {
        "message": "Lütfen geçerli bir tarih giriniz."
      }
    }
  }
}
</i18n>

