export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de-DE": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigen Eintrag eingeben."])}
          }
        }
      },
      "de-AT": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigen Eintrag eingeben."])}
          }
        }
      },
      "de-CH": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigen Eintrag eingeben."])}
          }
        }
      },
      "en-US": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid entry."])}
          }
        }
      },
      "en-GB": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid entry."])}
          }
        }
      },
      "it-IT": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di inserire una voce valida."])}
          }
        }
      },
      "fr-FR": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez une entrée valide."])}
          }
        }
      },
      "es-ES": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese una entrada válida."])}
          }
        }
      },
      "pl-PL": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź prawidłowy wpis."])}
          }
        }
      },
      "nl-NL": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer een geldige invoer in."])}
          }
        }
      },
      "bg-BG": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете валиден запис."])}
          }
        }
      },
      "bs-BA": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite valjani unos."])}
          }
        }
      },
      "zh-CN": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入有效的条目。"])}
          }
        }
      },
      "da-DK": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast en gyldig post."])}
          }
        }
      },
      "et-EE": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage kehtiv kirje."])}
          }
        }
      },
      "fi-FI": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna kelvollinen merkintä."])}
          }
        }
      },
      "el-GR": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε μια έγκυρη καταχώριση."])}
          }
        }
      },
      "he-IL": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אנא הזן ערך חוקי."])}
          }
        }
      },
      "ga-IE": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iontráil iontráil bhailí."])}
          }
        }
      },
      "ja-JP": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なエントリを入力してください。"])}
          }
        }
      },
      "hr-HR": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite valjani unos."])}
          }
        }
      },
      "lv-LV": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ievadiet derīgu ierakstu."])}
          }
        }
      },
      "lt-LT": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite tinkamą įrašą."])}
          }
        }
      },
      "pt-PT": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira uma entrada válida."])}
          }
        }
      },
      "ro-RO": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă rugăm să introduceți o intrare validă."])}
          }
        }
      },
      "ru-RU": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите действительную запись."])}
          }
        }
      },
      "sv-SE": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange en giltig post."])}
          }
        }
      },
      "sk-SK": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte platný údaj."])}
          }
        }
      },
      "cs-CZ": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte prosím platnou položku."])}
          }
        }
      },
      "tr-TR": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli bir giriş girin."])}
          }
        }
      },
      "hu-HU": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon meg egy érvényes bejegyzést."])}
          }
        }
      },
      "ar-AE": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل إدخالاً صالحًا."])}
          }
        }
      },
      "fr-BE": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez une entrée valide."])}
          }
        }
      },
      "fr-LU": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez une entrée valide."])}
          }
        }
      },
      "mt-MT": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daħħal dħul validu."])}
          }
        }
      },
      "sl-SI": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnesite veljaven vnos."])}
          }
        }
      },
      "el-CY": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε μια έγκυρη καταχώριση."])}
          }
        }
      },
      "tr-CY": {
        "inputValidation": {
          "incorrectPhrase": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli bir giriş girin."])}
          }
        }
      }
    }
  })
}
