<script setup>
import { inject, defineProps, defineEmits } from 'vue';
const composables = inject('composables');
const { isRTL } = composables.useJurisdictionSwitch()

const props = defineProps({
  modelValue: {required: true},
  label: {type: String, required: true},
  placeholder: {type: String, default: undefined},
  tooltip: {type: String, default: undefined},
  description: {type: String, default: undefined},
  required: {type: Boolean, default: false},
  inputType: {type: String},
  inputParameters: {type: Object},
  overwriteValidate: {type: Function, default: undefined}
})
const emit = defineEmits(['update', 'input', 'load'])
const input = composables.useTwoWayBinding(props, emit, 'modelValue')
</script>
<script>
  import isEmpty from 'lodash/isEmpty';

  export default {
    name: "DpInputCustom",
    data() {
      return {
        translationReportAlias: {
          label: this.$t('input.label'),
          placeholder: this.$t('input.placeholder'),
          tooltip: this.$t('input.tooltip'),
          description: this.$t('input.description')
        },
        isEmpty: isEmpty
      };
    },
    methods: {
      validate(rule, value, callback) {
        if (this.required !== true) return callback();
        if (value === '') {
          callback(new Error(this.$t('inputValidation.emptyField.message')));
        } else {
          callback();
        }
      },
      emitValue: function () {
        this.$emit("input", this.input);
      }
    },
    created() {
      this.$emit('load', {label: this.label});
    }
  }
</script>

<template>
  <el-form-item
      :class="{'is-required': required, 'right-to-left': isRTL}"
      v-bind="$attrs"
      :label="label"
      :rules="[
        { validator: (overwriteValidate !== undefined) ? overwriteValidate : validate, trigger: ['blur', 'change'] }
      ]">

    <template #label>
      <span>{{label}}</span>
      <span v-if="!isEmpty(tooltip)" class="form-item__description"><i class="el-icon-info"/> {{tooltip}}</span>
      <span v-if="!isEmpty(description)" class="form-item__description"><i class="el-icon-info"/> {{description}}</span>
    </template>

    <el-input v-if="inputType === 'textarea'"
              type="textarea"
              :rows="5"
              :autosize="{ minRows: 5 }"
              :placeholder="placeholder || label"
              v-model="input"
              v-bind="inputParameters"
    ></el-input>

    <el-input v-else
              v-model="input"
              :placeholder="placeholder || label"
              v-bind="inputParameters"
    ></el-input>

  </el-form-item>
</template>

<i18n>
{
  "de-DE": {
    "inputValidation": {
      "emptyField": {
        "message": "Gültigen Eintrag eingeben."
      }
    }
  },
  "de-AT": {
    "inputValidation": {
      "emptyField": {
        "message": "Gültigen Eintrag eingeben."
      }
    }
  },
  "de-CH": {
    "inputValidation": {
      "emptyField": {
        "message": "Gültigen Eintrag eingeben."
      }
    }
  },
  "en-US": {
    "inputValidation": {
      "emptyField": {
        "message": "Enter a valid entry."
      }
    }
  },
  "en-GB": {
    "inputValidation": {
      "emptyField": {
        "message": "Enter a valid entry."
      }
    }
  },
  "it-IT": {
    "inputValidation": {
      "emptyField": {
        "message": "Si prega di inserire una voce valida."
      }
    }
  },
  "fr-FR": {
    "inputValidation": {
      "emptyField": {
        "message": "Saisissez une entrée valide."
      }
    }
  },
  "es-ES": {
    "inputValidation": {
      "emptyField": {
        "message": "Ingrese una entrada válida."
      }
    }
  },
  "pl-PL": {
    "inputValidation": {
      "emptyField": {
        "message": "Wprowadź prawidłowy wpis."
      }
    }
  },
  "nl-NL": {
    "inputValidation": {
      "emptyField": {
        "message": "Voer een geldige invoer in."
      }
    }
  },
  "bg-BG": {
    "inputValidation": {
      "emptyField": {
        "message": "Въведете валиден запис."
      }
    }
  },
  "bs-BA": {
    "inputValidation": {
      "emptyField": {
        "message": "Unesite valjani unos."
      }
    }
  },
  "zh-CN": {
    "inputValidation": {
      "emptyField": {
        "message": "输入有效的条目。"
      }
    }
  },
  "da-DK": {
    "inputValidation": {
      "emptyField": {
        "message": "Indtast en gyldig post."
      }
    }
  },
  "et-EE": {
    "inputValidation": {
      "emptyField": {
        "message": "Sisestage kehtiv kirje."
      }
    }
  },
  "fi-FI": {
    "inputValidation": {
      "emptyField": {
        "message": "Anna kelvollinen merkintä."
      }
    }
  },
  "el-GR": {
    "inputValidation": {
      "emptyField": {
        "message": "Εισαγάγετε μια έγκυρη καταχώριση."
      }
    }
  },
  "he-IL": {
    "inputValidation": {
      "emptyField": {
        "message": "אנא הזן ערך חוקי."
      }
    }
  },
  "ga-IE": {
    "inputValidation": {
      "emptyField": {
        "message": "Iontráil iontráil bhailí."
      }
    }
  },
  "ja-JP": {
    "inputValidation": {
      "emptyField": {
        "message": "有効なエントリを入力してください。"
      }
    }
  },
  "hr-HR": {
    "inputValidation": {
      "emptyField": {
        "message": "Unesite valjani unos."
      }
    }
  },
  "lv-LV": {
    "inputValidation": {
      "emptyField": {
        "message": "Ievadiet derīgu ierakstu."
      }
    }
  },
  "lt-LT": {
    "inputValidation": {
      "emptyField": {
        "message": "Įveskite tinkamą įrašą."
      }
    }
  },
  "pt-PT": {
    "inputValidation": {
      "emptyField": {
        "message": "Insira uma entrada válida."
      }
    }
  },
  "ro-RO": {
    "inputValidation": {
      "emptyField": {
        "message": "Vă rugăm să introduceți o intrare validă."
      }
    }
  },
  "ru-RU": {
    "inputValidation": {
      "emptyField": {
        "message": "Введите действительную запись."
      }
    }
  },
  "sv-SE": {
    "inputValidation": {
      "emptyField": {
        "message": "Ange en giltig post."
      }
    }
  },
  "sk-SK": {
    "inputValidation": {
      "emptyField": {
        "message": "Zadajte platný údaj."
      }
    }
  },
  "cs-CZ": {
    "inputValidation": {
      "emptyField": {
        "message": "Zadejte prosím platnou položku."
      }
    }
  },
  "tr-TR": {
    "inputValidation": {
      "emptyField": {
        "message": "Geçerli bir giriş girin."
      }
    }
  },
  "hu-HU": {
    "inputValidation": {
      "emptyField": {
        "message": "Adjon meg egy érvényes bejegyzést."
      }
    }
  },
  "ar-AE": {
    "inputValidation": {
      "emptyField": {
        "message": "أدخل إدخالاً صالحًا."
      }
    }
  },
  "fr-BE": {
    "inputValidation": {
      "emptyField": {
        "message": "Saisissez une entrée valide."
      }
    }
  },
  "fr-LU": {
    "inputValidation": {
      "emptyField": {
        "message": "Saisissez une entrée valide."
      }
    }
  },
  "mt-MT": {
    "inputValidation": {
      "emptyField": {
        "message": "Daħħal dħul validu."
      }
    }
  },
  "sl-SI": {
    "inputValidation": {
      "emptyField": {
        "message": "Vnesite veljaven vnos."
      }
    }
  },
  "el-CY": {
    "inputValidation": {
      "emptyField": {
        "message": "Εισαγάγετε μια έγκυρη καταχώριση."
      }
    }
  },
  "tr-CY": {
    "inputValidation": {
      "emptyField": {
        "message": "Geçerli bir giriş girin."
      }
    }
  }
}
</i18n>
