import script from "./DpInputIBAN.vue?vue&type=script&setup=true&lang=js"
export * from "./DpInputIBAN.vue?vue&type=script&setup=true&lang=js"

import "./DpInputIBAN.vue?vue&type=style&index=0&id=4763b43b&lang=scss"
/* custom blocks */
import block0 from "./DpInputIBAN.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(script)


const __exports__ = script;

export default __exports__