<script setup>
import { inject, defineProps, defineEmits } from 'vue';
const composables = inject('composables');
const { isRTL } = composables.useJurisdictionSwitch()

const props = defineProps({
  modelValue: {required: true},
  options: {type: Array, required: true},
  placeholder: {type: String, default: undefined},
  label: {type: String, required: true},
  tooltip: {type: String, default: undefined},
  description: {type: String, default: undefined},
  required: {type: Boolean, default: false},
  filterable: {type: Boolean},
  inputParameters: {type: Object},
  tFunction: {type: Function, default: () => {}}
})
const emit = defineEmits(['update', 'change', 'load'])
const input = composables.useTwoWayBinding(props, emit, 'modelValue')
</script>
<script>
  import isEmpty from 'lodash/isEmpty';

  export default {
    name: "DpSelectCustom",
    data() {
      return {
        translationReportAlias: {
          tooltip: this.$t('input.tooltip'),
          description: this.$t('input.description'),
        },
        isEmpty: isEmpty
      };
    },
    methods: {
      validate(rule, value, callback) {
        if (this.required !== true) return callback();
        if (value === '') {
          callback(new Error(this.$t('inputValidation.emptyField.message')));
        } else {
          callback();
        }
      },
      emitValue: function () {
        this.$emit("change", this.input);
      }
    },
    created() {
      this.$emit('load', {label: this.label});
    }
  }
</script>

<template>
  <el-form-item
      :class="{'is-required': required, 'right-to-left': isRTL}"
      v-bind="$attrs"
      :label="label"
      :rules="[
        { validator: required, trigger: ['blur', 'change'] }
      ]">

    <template #label>
      <span>{{label}}</span>
      <span v-if="!isEmpty(tooltip)" class="form-item__description"><i class="el-icon-info"/> {{tooltip}}</span>
      <span v-if="!isEmpty(description)" class="form-item__description"><i class="el-icon-info"/> {{description}}</span>
    </template>

    <el-select v-model="input"
               v-bind="inputParameters"
               :placeholder="placeholder || label"
               v-on:change="emitValue"
               style="width: 100%"
               :popper-class="isRTL ? 'right-to-left' : ''"
               :filterable="filterable">
      <el-option
          v-for="(item, index) in options"
          :key="item.key || index"
          :label="(item.translationKey) ? tFunction(item.translationKey) : item.label"
          :value="item.value">
        <slot name="custom-nodes" v-bind="item"></slot>
      </el-option>
    </el-select>
  </el-form-item>
</template>

<i18n>
  {
  }
</i18n>
