import { useViewportSize } from "./viewportSize";
import { useTwoWayBinding } from "./twoWayBinding";
import { useJurisdictionSwitch } from "./jurisdictionSwitch";
import { useDateFormat } from './dateFormat';

import {useHasValidCountryCallingCode } from './mobileNumber/hasValidCountryCallingCode'
import {useIsValidNumber } from './mobileNumber/isValidNumber'
import {useTrimNumber } from './mobileNumber/trimNumber'
const mobileNumber = {
    useHasValidCountryCallingCode,
    useIsValidNumber,
    useTrimNumber
}

export default {
    useViewportSize,
    useTwoWayBinding,
    useJurisdictionSwitch,
    useDateFormat,
    mobileNumber
}
