<script>
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import {mapState} from 'pinia';
  import {useEnvStore} from '@dutypay/store-modules'

export default {
  name: "DpForm",
  inheritAttrs: true,
  props: {
    modelValue: {type: Object, required: true},
    reference: {type: String, required: true},
    action: {required: true},
    notificationSuccess: {type: Object},
    notificationError: {type: Object},
    notificationAsyncError: {type: Object},
    disableNotification: [Boolean, Array],
    primaryButtonLabel: {type: String},
    primaryButtonParameter: {type: Object},
    triggerAction: {type: Boolean},
    labelPosition: {type: String, default: 'top'}
  },
  computed: {
    ...mapState(useEnvStore, ['debug']),
    buttonLabel() {
      return (!isEmpty(this.primaryButtonLabel) && this.primaryButtonLabel !== undefined) ? this.primaryButtonLabel : this.$t('primaryButton.buttonLabel');
    },
    notification() {
      return {
        success: (this.notificationSuccess !== undefined) ? this.notificationSuccess : {
          title: this.$t('notification.success.title'),
          message: this.$t('notification.success.message')
        },
        error: (this.notificationError !== undefined) ? this.notificationError : {
          title: this.$t('notification.error.title'),
          message: this.$t('notification.error.message')
        },
        asyncError: (this.notificationAsyncError !== undefined) ? this.notificationAsyncError : {
          title: this.$t('notification.asyncError.title'),
          message: this.$t('notification.asyncError.message')
        }
      }
    }
  },
  watch: {
    triggerAction: function (val) {
      if (val) this.validateForm();
    }
  },
  methods: {
    async validateForm() {
      let self = this;
      this.$refs[this.reference].validate(
          async (valid) => {
            if (valid) {
              try {
                let res = await self.action();
                if (res.status >= 400 && res.status <= 600) {
                  self.notifyAsyncError(self.notification.asyncError);
                  self.$emit('asyncError');
                  return;
                }
                self.notifySuccess(self.notification.success);
                self.$emit('success');
              } catch (e) {
                self.notifyAsyncError(self.notification.asyncError);
                self.$emit('asyncError');
                if (this.debug)
                  return e;
              }
            } else {
              self.notifyError(self.notification.error);
              self.$emit('error');
              return false;
            }
          }
      );
    },
    containsKey(key) {
      return find(this.disableNotification, (iteratee) => {
        return (iteratee === key)
      }) !== undefined;
    },
    notifyError(message) {
      if (this.disableNotification === true || this.containsKey('error'))
        return;
      this.$notify.error(message);
    },
    notifyAsyncError(message) {
      if (this.disableNotification === true || this.containsKey('asyncError'))
        return;
      this.$notify.error(message);
    },
    notifySuccess(message) {
      if (this.disableNotification === true || this.containsKey('success'))
        return;
      this.$notify.success(message);
    }
  }
}
</script>

<template>
  <el-form
      v-bind="$attrs"
      :model="modelValue"
      :ref="reference"
      :label-position="labelPosition"
      @submit.prevent
      @submit.native.prevent
      @keydown.enter.prevent
      @keydown.enter.native.prevent>

    <slot></slot>

    <el-form-item v-if="$slots['secondaryButton']">
      <slot name="secondaryButton"></slot>
    </el-form-item>

    <el-form-item>
      <dp-button purpose="primaryAction" v-bind="primaryButtonParameter" @click="validateForm()">
        {{ buttonLabel }}
      </dp-button>
    </el-form-item>

  </el-form>
</template>

<style scoped lang="scss">
</style>

<i18n>
{
  "de-DE": {
    "notification": {
      "asyncError": {
        "message": "Bei unserem Service ist ein Fehler aufgetreten. Bitte versuche es erneut.",
        "title": "Versuch es noch einmal."
      },
      "error": {
        "message": "Fehlender oder ungültiger Eintrag. Anweisung im Formular beachten.",
        "title": "Fehler"
      },
      "success": {
        "title": "Erfolgreiche Aktion",
        "message": "Formulareingabe wurde erfolgreich verarbeitet."
      }
    },
    "primaryButton": {
      "buttonLabel": "Bestätigen"
    }
  },
  "de-AT": {
    "notification": {
      "asyncError": {
        "message": "Bei unserem Service ist ein Fehler aufgetreten. Bitte versuche es erneut.",
        "title": "Versuch es noch einmal."
      },
      "error": {
        "message": "Fehlender oder ungültiger Eintrag. Anweisung im Formular beachten.",
        "title": "Fehler"
      },
      "success": {
        "title": "Erfolgreiche Aktion",
        "message": "Formulareingabe wurde erfolgreich verarbeitet."
      }
    },
    "primaryButton": {
      "buttonLabel": "Bestätigen"
    }
  },
  "de-CH": {
    "notification": {
      "asyncError": {
        "message": "Bei unserem Service ist ein Fehler aufgetreten. Bitte versuche es erneut.",
        "title": "Versuch es noch einmal."
      },
      "error": {
        "message": "Fehlender oder ungültiger Eintrag. Anweisung im Formular beachten.",
        "title": "Fehler"
      },
      "success": {
        "title": "Erfolgreiche Aktion",
        "message": "Formulareingabe wurde erfolgreich verarbeitet."
      }
    },
    "primaryButton": {
      "buttonLabel": "Bestätigen"
    }
  },
  "en-US": {
    "notification": {
      "asyncError": {
        "message": "An error has occurred in our service. Please try again.",
        "title": "Try again."
      },
      "error": {
        "message": "Missing or invalid entry. Follow the instructions in the form.",
        "title": "Error"
      },
      "success": {
        "title": "Successful action",
        "message": "Form input was processed successfully."
      }
    },
    "primaryButton": {
      "buttonLabel": "Confirm"
    }
  },
  "en-GB": {
    "notification": {
      "asyncError": {
        "message": "An error has occurred in our service. Please try again.",
        "title": "Try again."
      },
      "error": {
        "message": "Missing or invalid entry. Follow the instructions in the form.",
        "title": "Error"
      },
      "success": {
        "title": "Successful action",
        "message": "Form input was processed successfully."
      }
    },
    "primaryButton": {
      "buttonLabel": "Confirm"
    }
  },
  "it-IT": {
    "notification": {
      "asyncError": {
        "message": "Si è verificato un errore nel nostro servizio. Per favore riprova.",
        "title": "Riprova."
      },
      "error": {
        "message": "Voce mancante o non valida. Segui le istruzioni nel modulo.",
        "title": "Errore"
      },
      "success": {
        "title": "Azione riuscita",
        "message": "Il modulo è stato elaborato con successo."
      }
    },
    "primaryButton": {
      "buttonLabel": "Conferma"
    }
  },
  "fr-FR": {
    "notification": {
      "asyncError": {
        "message": "Une erreur s'est produite dans notre service. Veuillez réessayer.",
        "title": "Essayez encore."
      },
      "error": {
        "message": "Entrée manquante ou invalide. Suivez les instructions du formulaire",
        "title": "Erreur"
      },
      "success": {
        "title": "Action réussie",
        "message": "La saisie du formulaire a été traitée avec succès."
      }
    },
    "primaryButton": {
      "buttonLabel": "Confirmer"
    }
  },
  "es-ES": {
    "notification": {
      "asyncError": {
        "message": "Ha habido un error en nuestro servicio. Inténtelo de nuevo, por favor.",
        "title": "Inténtelo de nuevo."
      },
      "error": {
        "message": "Siga las instrucciones del formulario.",
        "title": "Error"
      },
      "success": {
        "title": "Listo",
        "message": "El formulario se ha procesado correctamente."
      }
    },
    "primaryButton": {
      "buttonLabel": "Confirmar"
    }
  },
  "pl-PL": {
    "notification": {
      "asyncError": {
        "message": "W naszym serwisie wystąpił błąd. Prosimy, spróbuj ponownie.",
        "title": "Spróbuj ponownie."
      },
      "error": {
        "message": "Brakujący lub nieprawidłowy wpis. Postępuj zgodnie z instrukcjami w formularzu.",
        "title": "Błąd"
      },
      "success": {
        "title": "Czynność zakończona powodzeniem",
        "message": "Dane wejściowe formularza zostały pomyślnie przetworzone."
      }
    },
    "primaryButton": {
      "buttonLabel": "Potwierdź"
    }
  },
  "nl-NL": {
    "notification": {
      "asyncError": {
        "message": "Er is een fout opgetreden in onze service. Gelieve opnieuw te proberen.",
        "title": "Probeer het nog eens."
      },
      "error": {
        "message": "Ontbrekende of ongeldige invoer. Volg de instructies op het formulier.",
        "title": "Fout"
      },
      "success": {
        "title": "Geslaagde actie",
        "message": "Formulier invoer is succesvol verwerkt."
      }
    },
    "primaryButton": {
      "buttonLabel": "Bevestigen"
    }
  },
  "bg-BG": {
    "notification": {
      "asyncError": {
        "message": "Възникна грешка в нашата услуга. Моля, опитайте отново.",
        "title": "Опитайте отново."
      },
      "error": {
        "message": "Липсващ или невалиден запис. Следвайте инструкциите във формуляра.",
        "title": "грешка"
      },
      "success": {
        "title": "Успешно действие",
        "message": "Въведените във формуляра данни са обработени успешно."
      }
    },
    "primaryButton": {
      "buttonLabel": "Потвърждение"
    }
  },
  "bs-BA": {
    "notification": {
      "asyncError": {
        "message": "Došlo je do greške u našem servisu. Molimo pokušajte ponovo.",
        "title": "Pokušajte ponovo."
      },
      "error": {
        "message": "Unos nedostaje ili je nevažeći. Slijedite upute u obrascu.",
        "title": "Greška"
      },
      "success": {
        "title": "Uspješno obavljeno",
        "message": "Unos obrasca je uspješno obrađen."
      }
    },
    "primaryButton": {
      "buttonLabel": "Potvrdite"
    }
  },
  "zh-CN": {
    "notification": {
      "asyncError": {
        "message": "我们的服务发生了错误。 请再试一遍。",
        "title": "再试一次。"
      },
      "error": {
        "message": "条目缺失或无效。请按窗体中的说明操作。",
        "title": "错误"
      },
      "success": {
        "title": "操作成功",
        "message": "窗体输入已成功处理。"
      }
    },
    "primaryButton": {
      "buttonLabel": "确认"
    }
  },
  "da-DK": {
    "notification": {
      "asyncError": {
        "message": "Der opstod en fejl i vores service. Prøv igen.",
        "title": "Prøv igen."
      },
      "error": {
        "message": "Manglende eller ugyldig indtastning. Følg instruktionerne i formularen.",
        "title": "Fejl"
      },
      "success": {
        "title": "Vellykket handling",
        "message": "Formularinput blev behandlet med succes."
      }
    },
    "primaryButton": {
      "buttonLabel": "Bekræft"
    }
  },
  "et-EE": {
    "notification": {
      "asyncError": {
        "message": "Meie teenuses on ilmnenud tõrge. Palun proovi uuesti.",
        "title": "Proovi uuesti."
      },
      "error": {
        "message": "Puuduv või kehtetu sisestus. Järgige vormis olevaid juhiseid.",
        "title": "Viga"
      },
      "success": {
        "title": "Edukas tegevus",
        "message": "Vormi sisendi töötlemine õnnestus."
      }
    },
    "primaryButton": {
      "buttonLabel": "Kinnita"
    }
  },
  "fi-FI": {
    "notification": {
      "asyncError": {
        "message": "Palvelussamme on tapahtunut virhe. Olkaa hyvä, ja yrittäkää uudelleen.",
        "title": "Yritä uudelleen."
      },
      "error": {
        "message": "Puuttuva tai virheellinen merkintä. Noudata lomakkeen ohjeita.",
        "title": "Virhe"
      },
      "success": {
        "title": "Onnistunut toiminta",
        "message": "Lomakkeen syötteen käsittely onnistui."
      }
    },
    "primaryButton": {
      "buttonLabel": "Vahvista"
    }
  },
  "el-GR": {
    "notification": {
      "asyncError": {
        "message": "Παρουσιάστηκε σφάλμα στην υπηρεσία μας. ΠΑΡΑΚΑΛΩ προσπαθησε ξανα.",
        "title": "Προσπάθησε ξανά."
      },
      "error": {
        "message": "Λείπει ή δεν είναι έγκυρη η καταχώριση. Ακολουθήστε τις οδηγίες στη φόρμα.",
        "title": "Σφάλμα"
      },
      "success": {
        "title": "Επιτυχημένη ενέργεια",
        "message": "Η εισαγωγή της φόρμας υποβλήθηκε σε επεξεργασία με επιτυχία."
      }
    },
    "primaryButton": {
      "buttonLabel": "Επιβεβαίωση"
    }
  },
  "he-IL": {
    "notification": {
      "asyncError": {
        "message": "אירעה שגיאה בשירות שלנו. בבקשה נסה שוב.",
        "title": "נסה שוב."
      },
      "error": {
        "message": "ערך חסר או לא חוקי. עקוב אחר ההוראות בטופס.",
        "title": "שְׁגִיאָה"
      },
      "success": {
        "title": "פעולה מוצלחת",
        "message": "קלט הטופס עבר עיבוד בהצלחה."
      }
    },
    "primaryButton": {
      "buttonLabel": "לְאַשֵׁר"
    }
  },
  "ga-IE": {
    "notification": {
      "asyncError": {
        "message": "Tharla earráid inár seirbhís. Arís, le d'thoil.",
        "title": "Déan iarracht arís."
      },
      "error": {
        "message": "Iontráil ar iarraidh nó neamhbhailí. Lean na treoracha san fhoirm.",
        "title": "Earráid"
      },
      "success": {
        "title": "Gníomh rathúil",
        "message": "Próiseáladh an t-ionchur foirme go rathúil."
      }
    },
    "primaryButton": {
      "buttonLabel": "Deimhnigh"
    }
  },
  "ja-JP": {
    "notification": {
      "asyncError": {
        "message": "サービスでエラーが発生しました。 もう一度お試しください。",
        "title": "再試行。"
      },
      "error": {
        "message": "エントリがないか無効です。 フォームの指示に従ってください。",
        "title": "エラー"
      },
      "success": {
        "title": "成功したアクション",
        "message": "フォーム入力は正常に処理されました。"
      }
    },
    "primaryButton": {
      "buttonLabel": "確認"
    }
  },
  "hr-HR": {
    "notification": {
      "asyncError": {
        "message": "Došlo je do pogreške u našoj usluzi. Molim vas pokušajte ponovno.",
        "title": "Pokušajte ponovo."
      },
      "error": {
        "message": "Unos nedostaje ili je nevažeći. Slijedite upute u obrascu.",
        "title": "Pogreška"
      },
      "success": {
        "title": "Uspješna akcija",
        "message": "Unos obrasca uspješno je obrađen."
      }
    },
    "primaryButton": {
      "buttonLabel": "Potvrda"
    }
  },
  "lv-LV": {
    "notification": {
      "asyncError": {
        "message": "Mūsu pakalpojumā ir radusies kļūda. Lūdzu, mēģiniet vēlreiz.",
        "title": "Mēģiniet vēlreiz."
      },
      "error": {
        "message": "Trūkstošs vai nederīgs ieraksts. Izpildiet veidlapā sniegtos norādījumus.",
        "title": "Kļūda"
      },
      "success": {
        "title": "Veiksmīga darbība",
        "message": "Veidlapas ievade tika veiksmīgi apstrādāta."
      }
    },
    "primaryButton": {
      "buttonLabel": "Apstiprināt"
    }
  },
  "lt-LT": {
    "notification": {
      "asyncError": {
        "message": "Mūsų tarnyboje įvyko klaida. Bandykite dar kartą.",
        "title": "Bandykite iš naujo."
      },
      "error": {
        "message": "Trūksta arba neteisingas įrašas. Vykdykite instrukcijas formoje.",
        "title": "Klaida"
      },
      "success": {
        "title": "Sėkmingas veiksmas",
        "message": "Formos įvestis sėkmingai apdorota."
      }
    },
    "primaryButton": {
      "buttonLabel": "Patvirtinti"
    }
  },
  "pt-PT": {
    "notification": {
      "asyncError": {
        "message": "Ocorreu um erro no nosso serviço. Tente novamente.",
        "title": "Tente novamente."
      },
      "error": {
        "message": "Entrada ausente ou inválida. Siga as instruções do formulário.",
        "title": "Erro"
      },
      "success": {
        "title": "Ação bem-sucedida",
        "message": "A entrada do formulário foi processada com sucesso."
      }
    },
    "primaryButton": {
      "buttonLabel": "Confirmar"
    }
  },
  "ro-RO": {
    "notification": {
      "asyncError": {
        "message": "A apărut o eroare în serviciu. Încercați din nou.",
        "title": "Încercați din nou."
      },
      "error": {
        "message": "Valori lipsă sau incorecte. Urmați instrucțiunile din formular.",
        "title": "Eroare"
      },
      "success": {
        "title": "Acțiune reușită",
        "message": "Valorile introduse în formular au fost prelucrate cu succes."
      }
    },
    "primaryButton": {
      "buttonLabel": "Confirmare"
    }
  },
  "ru-RU": {
    "notification": {
      "asyncError": {
        "message": "Произошла ошибка в нашем сервисе. Пожалуйста, попробуйте еще раз.",
        "title": "Попробуйте еще раз."
      },
      "error": {
        "message": "Данные отсутствуют или недействительны. Следуйте инструкциям в форме.",
        "title": "Ошибка"
      },
      "success": {
        "title": "Успешное действие",
        "message": "Ввод формы произведен успешно."
      }
    },
    "primaryButton": {
      "buttonLabel": "Подтвердить"
    }
  },
  "sv-SE": {
    "notification": {
      "asyncError": {
        "message": "Ett fel har uppstått i vår tjänst. Var god försök igen.",
        "title": "Försök igen."
      },
      "error": {
        "message": "Saknad eller felaktig inmatning. Följ instruktionerna i formuläret.",
        "title": "Fel"
      },
      "success": {
        "title": "Åtgärd utförd",
        "message": "Formuläret fylldes i korrekt."
      }
    },
    "primaryButton": {
      "buttonLabel": "Bekräfta"
    }
  },
  "sk-SK": {
    "notification": {
      "asyncError": {
        "message": "V našej službe sa vyskytla chyba. Prosím skúste znova.",
        "title": "Skúste to znova."
      },
      "error": {
        "message": "Chýbajúci alebo neplatný záznam. Postupujte podľa pokynov vo formulári.",
        "title": "Chyba"
      },
      "success": {
        "title": "Úspešná akcia",
        "message": "Zadanie formulára bolo úspešne spracované."
      }
    },
    "primaryButton": {
      "buttonLabel": "Potvrdiť"
    }
  },
  "cs-CZ": {
    "notification": {
      "asyncError": {
        "message": "V naší službě se vyskytla chyba. Zkuste to prosím znovu.",
        "title": "Zkuste znovu."
      },
      "error": {
        "message": "Chybějící nebo neplatný záznam. Postupujte podle pokynů ve formuláři.",
        "title": "Chyba"
      },
      "success": {
        "title": "Úspěšná akce",
        "message": "Zadání formuláře bylo úspěšně zpracováno."
      }
    },
    "primaryButton": {
      "buttonLabel": "Potvrdit"
    }
  },
  "tr-TR": {
    "notification": {
      "asyncError": {
        "message": "Hizmetimizde bir hata oluştu. Lütfen tekrar deneyin.",
        "title": "Tekrar deneyin."
      },
      "error": {
        "message": "Eksik veya geçersiz giriş. Formdaki talimatları izleyin.",
        "title": "Hata"
      },
      "success": {
        "title": "Başarılı eylem",
        "message": "Form girişi başarıyla işlendi."
      }
    },
    "primaryButton": {
      "buttonLabel": "Onayla"
    }
  },
  "hu-HU": {
    "notification": {
      "asyncError": {
        "message": "Hiba történt a szolgáltatásunkban. Próbálja meg újra.",
        "title": "Próbálja meg újra."
      },
      "error": {
        "message": "Hiányzó vagy érvénytelen bejegyzés. Kövesse az űrlapon található utasításokat.",
        "title": "Hiba"
      },
      "success": {
        "title": "Sikeres művelet",
        "message": "Az űrlap bevitele sikeresen feldolgozva"
      }
    },
    "primaryButton": {
      "buttonLabel": "Megerősít"
    }
  },
  "ar-AE": {
    "notification": {
      "asyncError": {
        "message": "حدث خطأ في خدمتنا. حاول مرة اخرى.",
        "title": "حاول مجددا."
      },
      "error": {
        "message": "إدخال مفقود أو غير صالح. اتبع التعليمات الواردة في النموذج.",
        "title": "خطأ"
      },
      "success": {
        "title": "عمل ناجح",
        "message": "تمت معالجة إدخال النموذج بنجاح."
      }
    },
    "primaryButton": {
      "buttonLabel": "تأكيد"
    }
  },
  "fr-BE": {
    "notification": {
      "asyncError": {
        "message": "Une erreur s'est produite dans notre service. Veuillez réessayer.",
        "title": "Essayez encore."
      },
      "error": {
        "message": "Entrée manquante ou invalide. Suivez les instructions du formulaire.",
        "title": "Erreur"
      },
      "success": {
        "title": "Action réussie",
        "message": "La saisie du formulaire a été traitée avec succès."
      }
    },
    "primaryButton": {
      "buttonLabel": "Confirmer"
    }
  },
  "fr-LU": {
    "notification": {
      "asyncError": {
        "message": "Une erreur s'est produite dans notre service. Veuillez réessayer.",
        "title": "Essayez encore."
      },
      "error": {
        "message": "Entrée manquante ou invalide. Suivez les instructions du formulaire.",
        "title": "Erreur"
      },
      "success": {
        "title": "Action réussie",
        "message": "La saisie du formulaire a été traitée avec succès."
      }
    },
    "primaryButton": {
      "buttonLabel": "Confirmer"
    }
  },
  "mt-MT": {
    "notification": {
      "asyncError": {
        "message": "Seħħ żball fis-servizz tagħna. Jekk jogħġbok erġa' pprova.",
        "title": "Erġa' pprova."
      },
      "error": {
        "message": "Dħul nieqes jew invalidu. Segwi l-istruzzjonijiet fil-formolae.",
        "title": "Żball"
      },
      "success": {
        "title": "Azzjoni ta' suċċess",
        "message": "Id-dħul tal-formola ġie pproċessat b'suċċess."
      }
    },
    "primaryButton": {
      "buttonLabel": "Ikkonferma"
    }
  },
  "sl-SI": {
    "notification": {
      "asyncError": {
        "message": "Prišlo je do napake v naši storitvi. Poskusite ponovno.",
        "title": "Poskusite ponovno."
      },
      "error": {
        "message": "Vnos manjka ali ni veljaven. Upoštevajte navodila v obrazcu.",
        "title": "Napaka"
      },
      "success": {
        "title": "Uspešno opravljeno",
        "message": "Vnos obrazca je bil uspešno obdelan."
      }
    },
    "primaryButton": {
      "buttonLabel": "Potrdi"
    }
  },
  "el-CY": {
    "notification": {
      "asyncError": {
        "message": "Παρουσιάστηκε σφάλμα στην υπηρεσία μας. ΠΑΡΑΚΑΛΩ προσπαθησε ξανα.",
        "title": "Προσπάθησε ξανά."
      },
      "error": {
        "message": "Λείπει ή δεν είναι έγκυρη η καταχώριση. Ακολουθήστε τις οδηγίες στη φόρμα.",
        "title": "Σφάλμα"
      },
      "success": {
        "title": "Επιτυχημένη ενέργεια",
        "message": "Η εισαγωγή της φόρμας υποβλήθηκε σε επεξεργασία με επιτυχία."
      }
    },
    "primaryButton": {
      "buttonLabel": "Επιβεβαίωση"
    }
  },
  "tr-CY": {
    "notification": {
      "asyncError": {
        "message": "Hizmetimizde bir hata oluştu. Lütfen tekrar deneyin.",
        "title": "Tekrar deneyin."
      },
      "error": {
        "message": "Eksik veya geçersiz giriş. Formdaki talimatları izleyin.",
        "title": "Hata"
      },
      "success": {
        "title": "Başarılı eylem",
        "message": "Form girişi başarıyla işlendi."
      }
    },
    "primaryButton": {
      "buttonLabel": "Onayla"
    }
  }
}
</i18n>
