import {computed} from 'vue'
import get from 'lodash/get'
import fromPairs from "lodash/fromPairs";
import compact from "lodash/compact";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import isNull from "lodash/isNull";

export function useJurisdictionSwitch() {
    const jurisdictionList = [
        {
            translationKeyId: '0205bf02-901e-4609-bca4-fde7e513ef34',
            flagISOCode: 'gb',
            i18n: 'en-GB',
            callingCode: '+44'
        },
        {
            translationKeyId: 'fa490575-bbdc-41ed-b9df-389ea5733c2d',
            flagISOCode: 'us',
            i18n: 'en-US',
            callingCode: '+1'
        },
        {
            translationKeyId: '2c6a919b-4a6c-4168-b2ec-98200d2079dc',
            flagISOCode: 'de',
            i18n: 'de-DE',
            callingCode: '+49'
        },
        {
            translationKeyId: '30fd246c-7c40-4335-a94d-9c5d140d3784',
            flagISOCode: 'at',
            i18n: 'de-AT',
            callingCode: '+43'
        },
        {
            translationKeyId: '8260ac56-f799-4c96-a8b3-9ef80022dcc2',
            flagISOCode: 'ch',
            i18n: 'de-CH',
            callingCode: '+41'
        },
        {
            translationKeyId: '59156566-1051-4ecb-82d2-c8761ac35142',
            flagISOCode: 'ae',
            i18n: 'ar-AE',
            rtl: true,
            largeFont: true,
            tallLineHeight: true,
            callingCode: '+971'
        },
        {
            translationKeyId: 'c2fadfed-10ac-4a66-95d0-1501c3de7709',
            flagISOCode: 'bg',
            i18n: 'bg-BG',
            callingCode: '+359'
        },
        {
            translationKeyId: '9d597a5a-476b-43ef-996e-ae36bf43613c',
            flagISOCode: 'ba',
            i18n: 'bs-BA',
            callingCode: '+387'
        },
        {
            translationKeyId: '863d4c07-6fd8-497c-81db-bb94eccd1b63',
            flagISOCode: 'cn',
            i18n: 'zh-CN',
            largeFont: true,
            callingCode: '+86'
        },
        {
            translationKeyId: '27b03091-3c1c-4ec1-9f65-e5c4ce316f7a',
            flagISOCode: 'cz',
            i18n: 'cs-CZ',
            callingCode: '+420'
        },
        {
            translationKeyId: '75a99a6c-1731-435e-8c41-9fc56a6875fb',
            flagISOCode: 'dk',
            i18n: 'da-DK',
            callingCode: '+45'
        },
        {
            translationKeyId: 'ddd91177-cd52-4fde-8c8f-28b40366145c',
            flagISOCode: 'cy',
            i18n: 'el-CY',
            callingCode: '+357'
        },
        {
            translationKeyId: '37f527c0-6339-4cf6-9f52-f05565ab687a',
            flagISOCode: 'gr',
            i18n: 'el-GR',
            callingCode: '+30'
        },
        {
            translationKeyId: '2f622db6-2730-498e-8074-9ab277da3f4a',
            flagISOCode: 'es',
            i18n: 'es-ES',
            callingCode: '+34'
        },
        {
            translationKeyId: '731f2c5e-8c0e-416e-b705-9714499f4801',
            flagISOCode: 'ee',
            i18n: 'et-EE',
            callingCode: '+372'
        },
        {
            translationKeyId: 'd256b935-933d-46da-8abb-22e0b6369c61',
            flagISOCode: 'fi',
            i18n: 'fi-FI',
            callingCode: '+358'
        },
        {
            translationKeyId: '6de042f7-9d92-4889-a37d-5e3793b7fb1b',
            flagISOCode: 'be',
            i18n: 'fr-BE',
            callingCode: '+32'
        },
        {
            translationKeyId: 'd93bab06-320f-40f2-a736-580c414ebf2c',
            flagISOCode: 'lu',
            i18n: 'fr-LU',
            callingCode: '+352'
        },
        {
            translationKeyId: 'e46a43eb-419a-4976-a31d-dda7fda38a62',
            flagISOCode: 'fr',
            i18n: 'fr-FR',
            callingCode: '+33'
        },
        {
            translationKeyId: '63f6528b-9c28-4b7e-8a11-c0eeb0fdac5c',
            flagISOCode: 'ie',
            i18n: 'ga-IE',
            callingCode: '+353'
        },
        {
            translationKeyId: '6e40aacc-0fc5-4ee1-ab48-6d792b1886ff',
            flagISOCode: 'il',
            i18n: 'he-IL',
            rtl: true,
            largeFont: true,
            callingCode: '+972'
        },
        {
            translationKeyId: 'a754c58f-9a05-4705-bba1-2d0dce245b0d',
            flagISOCode: 'hr',
            i18n: 'hr-HR',
            callingCode: '+385'
        },
        {
            translationKeyId: 'd8380dc1-0758-473b-9d91-9aa15c0f9d84',
            flagISOCode: 'hu',
            i18n: 'hu-HU',
            callingCode: '+36'
        },
        {
            translationKeyId: '88bcb731-63db-4b0b-8f20-f12c3affe47b',
            flagISOCode: 'it',
            i18n: 'it-IT',
            callingCode: '+39'
        },
        {
            translationKeyId: '531b7bcb-d246-4944-bc02-cfc7ed704841',
            flagISOCode: 'jp',
            i18n: 'ja-JP',
            largeFont: true,
            callingCode: '+81'
        },
        {
            translationKeyId: '737725a6-30d3-4f17-91b0-d01ba4ea1ab9',
            flagISOCode: 'lv',
            i18n: 'lv-LV',
            callingCode: '+371'
        },
        {
            translationKeyId: 'fa938fd1-4353-4c61-a2fb-90eb9dad399b',
            flagISOCode: 'lt',
            i18n: 'lt-LT',
            callingCode: '+370'
        },
        {
            translationKeyId: '5fc3e5c6-fce7-48aa-9f0f-8f5f2c1d92af',
            flagISOCode: 'mt',
            i18n: 'mt-MT',
            callingCode: '+356'
        },
        {
            translationKeyId: '51a2d4c2-f1df-42b8-b620-9c74f02fdf7c',
            flagISOCode: 'nl',
            i18n: 'nl-NL',
            callingCode: '+31'
        },
        {
            translationKeyId: '0a71c5ea-7a9e-47bd-951a-9510d888af0f',
            flagISOCode: 'pl',
            i18n: 'pl-PL',
            callingCode: '+48'
        },
        {
            translationKeyId: '4ffdf06a-9fbf-41f2-8c49-1cdd8df3dd3c',
            flagISOCode: 'pt',
            i18n: 'pt-PT',
            callingCode: '+351'
        },
        {
            translationKeyId: 'd62ea438-c093-4f4f-b642-476fd1879973',
            flagISOCode: 'ro',
            i18n: 'ro-RO',
            callingCode: '+40'
        },
        {
            translationKeyId: '22f9f3ce-b723-49d3-999f-ed28d0704b24',
            flagISOCode: 'ru',
            i18n: 'ru-RU',
            callingCode: '+7'
        },
        {
            translationKeyId: 'aa82d031-d583-42e7-8595-a049483df412',
            flagISOCode: 'si',
            i18n: 'sl-SI',
            callingCode: '+386'
        },
        {
            translationKeyId: '0a891f67-dad1-4960-a306-b2a8c75aceed',
            flagISOCode: 'se',
            i18n: 'sv-SE',
            callingCode: '+46'
        },
        {
            translationKeyId: 'b9aff136-a0f5-4288-a92f-9befc8bcfc68',
            flagISOCode: 'sk',
            i18n: 'sk-SK',
            callingCode: '+421'
        },
        {
            translationKeyId: 'ff93d69a-466a-421a-ae19-23470be98427',
            flagISOCode: 'cy',
            i18n: 'tr-CY',
            callingCode: '+357'
        },
        {
            translationKeyId: 'ddf1105b-e460-4cbc-a004-3349b2d86daa',
            flagISOCode: 'tr',
            i18n: 'tr-TR',
            callingCode: '+90'
        },
    ]
    const fallbackJurisdiction = find(jurisdictionList, ['i18n', 'en-US'])

    function getJurisdictionFromLocalStorage() {
        if(localStorage)
            return JSON.parse(localStorage.getItem('jurisdiction'))
    }

    function getJurisdictionByLocale(locale) {
        return find(jurisdictionList, ['i18n', locale])
    }

    const isRTL = computed(() => {
        return get(getJurisdictionFromLocalStorage, 'rtl', false)
    })

    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    function editDocumentLanguage(jurisdiction) {
        document.documentElement.setAttribute('lang', get(jurisdiction, 'i18n', 'en'));
    }
    function editCSSClassesList(jurisdiction) {
        let bodyClassList = document.getElementsByTagName('body')[0].classList;
        if (get(jurisdiction, 'rtl', false)) {
            bodyClassList.add('direction-rtl');
        } else {
            bodyClassList.remove('direction-rtl');
        }
        if (get(jurisdiction, 'largeFont', false)) {
            bodyClassList.add('large-font')
        } else {
            bodyClassList.remove('large-font');
        }
        if (get(jurisdiction, 'tallLineHeight', false)) {
            bodyClassList.add('tall-line-height')
        } else {
            bodyClassList.remove('tall-line-height');
        }
    }

    function setJurisdiction(locale, jurisdiction) {
        locale.value = jurisdiction.i18n;
        if(localStorage)
            localStorage.setItem('jurisdiction', JSON.stringify(jurisdiction));
        editDocumentLanguage(jurisdiction);
        editCSSClassesList(jurisdiction);
    }

    function loadLocale() {
        let urlHash = window.location.hash;
        let urlParams = fromPairs(compact(map(urlHash.slice(1).split('&'), function(item) {if (item) return item.split('=');})));
        let jurisdictionFromUrl = (!isEmpty(urlParams))
            ? find(jurisdictionList, function(item) {
                return item.i18n == decodeURIComponent(urlParams.i18n);
            })
            : undefined;
        let jurisdictionFromLocalStorage = localStorage.getItem('jurisdiction');
        let jurisdiction = {};
        if (jurisdictionFromUrl) {
            jurisdiction = getJurisdictionByLocale(jurisdictionFromUrl.i18n)
        } else {
            if(isJsonString(jurisdictionFromLocalStorage) && !isNull(jurisdictionFromLocalStorage)){
                jurisdiction = JSON.parse(jurisdictionFromLocalStorage)
            } else {
                jurisdiction = fallbackJurisdiction
            }
        }
        editDocumentLanguage(jurisdiction);
        editCSSClassesList(jurisdiction);
        return jurisdiction.i18n;
    }

    return {
        jurisdictionList,
        fallbackJurisdiction,
        getJurisdictionFromLocalStorage,
        getJurisdictionByLocale,
        isRTL,
        setJurisdiction,
        loadLocale
    }
}