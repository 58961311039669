<script setup>
import { inject } from 'vue';
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const composables = inject('composables');
const { isRTL } = composables.useJurisdictionSwitch()
</script>
<script>
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import {mapState} from 'pinia';
  import {useEnvStore} from '@dutypay/store-modules'

export default {
  name: "DpFormDeletionConfirmationUserInputAndConsent",
  inheritAttrs: true,
  props: {
    reference: {type: String, required: true},
    action: {required: true},
    confirmationPhrase: {required: true, type: String},
    notificationSuccess: {type: Object},
    notificationError: {type: Object},
    notificationAsyncError: {type: Object},
    disableNotification: [Boolean, Array],
    deleteButtonLabel: {type: String},
    keepButtonLabel: {type: String},
    deleteButtonParameter: {type: Object},
    keepButtonParameter: {type: Object},
    inputParameter: {type: Object},
    consentTextBlock: {type: String},
    triggerAction: {type: Boolean},
    labelPosition: {type: String, default: 'top'},
    isFormActive: {type: Boolean, default: false},
    fullWidth: {type: Boolean, default: false}
  },
  data() {
    return {
      confirmationForm: {
        confirmationPhrase: '',
        consent: false
      },
      isLoading: false,
      isPhraseValidated: false,
      isFormActiveLocal: this.isFormActive
    };
  },
  computed: {
    ...mapState(useEnvStore, ['debug']),
    notification() {
      return {
        success: (this.notificationSuccess !== undefined) ? this.notificationSuccess : {
          title: this.$t('notification.success.title'),
          message: this.$t('notification.success.message')
        },
        error: (this.notificationError !== undefined) ? this.notificationError : {
          title: this.$t('notification.error.title'),
          message: this.$t('notification.error.message')
        },
        asyncError: (this.notificationAsyncError !== undefined) ? this.notificationAsyncError : {
          title: this.$t('notification.asyncError.title'),
          message: this.$t('notification.asyncError.message')
        }
      }
    },
    computedButtonLabel() {
      return {
        deleteButtonLabel: (isEmpty(this.deleteButtonLabel)) ? this.$t('delete.buttonLabel') : this.deleteButtonLabel,
        keepButtonLabel: (isEmpty(this.keepButtonLabel)) ? this.$t('keep.buttonLabel') : this.keepButtonLabel,
      };
    },
    computedConsentTextBlock() {
      return (isEmpty(this.consentTextBlock)) ? this.$t('consent.textBlock.content') : get(this.consentTextBlock, 'content', this.consentTextBlock);
    }
  },
  watch: {
    triggerAction: function (val) {
      if (val) this.validateAndExecuteDeletion();
    },
    isFormActive() {
      this.isFormActiveLocal = this.isFormActive;
    }
  },
  methods: {
    validateConfirmationPhrase(rule, value, callback) {
      if (value !== this.confirmationPhrase) {
        callback(new Error(this.$t('inputValidation.incorrectPhrase.message')));
      } else {
        callback();
        this.isPhraseValidated = true;
      }
    },
    validateConsent(rule, value, callback) {
      if (!value) {
        callback(new Error(this.$t('inputValidation.consent.message')));
      } else {
        callback();
      }
    },
    cancelForm() {
      this.deactivateForm();
      this.$emit('cancel-form');
    },
    submitForm() {
      if (this.isFormActiveLocal) {
        this.validateAndExecuteDeletion();
      } else {
        this.activateForm();
      }
    },
    activateForm() {
      this.$emit('activate-form');
      this.$nextTick(() => {
        this.$refs[`${this.reference}__keepButton`].focus();
      });
      this.isFormActiveLocal = true;
    },
    deactivateForm() {
      this.isFormActiveLocal = false;
      this.$nextTick(() => {
        this.$refs[this.reference].clearValidate();
      })
      this.$emit('deactivate-form');
    },
    async validateAndExecuteDeletion() {
      let self = this;
      this.$refs[this.reference].validate(
          async (valid) => {
            if (valid) {
              try {
                self.isLoading = true;
                let res = await self.action();
                self.deactivateForm();
                self.isLoading = false;
                if (res.status >= 400 && res.status <= 600) {
                  self.notifyAsyncError(self.notification.asyncError);
                  self.$emit('asyncError');
                  return;
                }
                self.notifySuccess(self.notification.success);
                self.$emit('success');
              } catch (e) {
                self.notifyAsyncError(self.notification.asyncError);
                self.$emit('asyncError');
                if (this.debug)
                  return e;
              }
            } else {
              self.notifyError(self.notification.error);
              self.$emit('error');
              return false;
            }
          }
      );
    },
    containsKey(key) {
      return find(this.disableNotification, (iteratee) => {return (iteratee === key)}) !== undefined;
    },
    notifyError(message) {
      if (this.disableNotification === true || this.containsKey('error'))
        return;
      this.$notify.error(message);
    },
    notifyAsyncError(message) {
      if (this.disableNotification === true || this.containsKey('asyncError'))
        return;
      this.$notify.error(message);
    },
    notifySuccess(message) {
      if (this.disableNotification === true || this.containsKey('success'))
        return;
      this.$notify.success(message);
    }
  }

}
</script>

<template>
  <el-form
      v-bind="$attrs"
      v-loading="isLoading"
      :model="confirmationForm"
      :ref="reference"
      :label-position="labelPosition"
      :class="{'dp-form__deletion': isFormActiveLocal, 'dp-form__deletion__user-input-and-consent': !fullWidth && isFormActiveLocal}"
      @submit.prevent
      @submit.native.prevent
      @keydown.enter.prevent
      @keydown.enter.native.prevent>

    <slot></slot>

    <div v-if="isFormActiveLocal">
      <slot name="keepButton">
        <dp-button v-bind="keepButtonParameter"
                   purpose="secondaryAction"
                   :ref="`${reference}__keepButton`"
                   @click="cancelForm">
          {{computedButtonLabel.keepButtonLabel}}
        </dp-button>
      </slot>

      <p><slot name="instructions">{{ t('instructions.label') }}</slot></p>
      <p><span class="dp-form__deletion__confirmation-phrase">{{ confirmationPhrase }}</span></p>

      <el-form-item :rules="[{ validator: this.validateConfirmationPhrase, trigger: ['blur'] }]"
                    prop="confirmationPhrase" :class="{'right-to-left': isRTL}">
        <el-input v-bind="inputParameter" v-model="confirmationForm.confirmationPhrase" :placeholder="confirmationPhrase"></el-input>
      </el-form-item>

      <DpCollapse v-show="isPhraseValidated">
        <slot name="caution-warning"></slot>
        <el-form-item  :rules="[{ validator: this.validateConsent, trigger: ['change'] }]" prop="consent" required>
          <el-checkbox v-model="confirmationForm.consent">{{ computedConsentTextBlock }}</el-checkbox>
        </el-form-item>
      </DpCollapse>
    </div>

    <el-form-item>
      <dp-button purpose="destructiveAction" v-bind="deleteButtonParameter" @click="submitForm">
        {{computedButtonLabel.deleteButtonLabel}}
      </dp-button>
    </el-form-item>

  </el-form>
</template>

<style scoped lang="scss">
</style>

<i18n>
{
  "de-DE": {
    "pleaseConfirm": {
      "label": "Zur Bestätigung die untenstehende E-Mail-Adresse im Eingabefeld eintragen."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Der Eintrag im Eingabefeld ist nicht identisch mit der oben vorgegebenen E-Mail-Adresse."
      }
    }
  },
  "de-AT": {
    "pleaseConfirm": {
      "label": "Zur Bestätigung die untenstehende E-Mail-Adresse im Eingabefeld eintragen."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Der Eintrag im Eingabefeld ist nicht identisch mit der oben vorgegebenen E-Mail-Adresse."
      }
    }
  },
  "de-CH": {
    "pleaseConfirm": {
      "label": "Zur Bestätigung die untenstehende E-Mail-Adresse im Eingabefeld eintragen."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Der Eintrag im Eingabefeld ist nicht identisch mit der oben vorgegebenen E-Mail-Adresse."
      }
    }
  },
  "en-US": {
    "pleaseConfirm": {
      "label": "To confirm, enter the email address below in the input field."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "The entry in the input field is not identical to the email address given above."
      }
    }
  },
  "en-GB": {
    "pleaseConfirm": {
      "label": "To confirm, enter the email address below in the input field."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "The entry in the input field is not identical to the email address given above."
      }
    }
  },
  "it-IT": {
    "pleaseConfirm": {
      "label": "Per confermare, inserisci l'indirizzo email sottostante nel campo di inserimento."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "La voce nel campo di input non è identica all'indirizzo e-mail sopra indicato."
      }
    }
  },
  "fr-FR": {
    "pleaseConfirm": {
      "label": "Pour confirmer, entrez l'adresse e-mail ci-dessous dans le champ de saisie."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "L'entrée dans le champ de saisie n'est pas identique à l'adresse e-mail indiquée ci-dessus."
      }
    }
  },
  "es-ES": {
    "pleaseConfirm": {
      "label": "Para confirmar, ingrese la dirección de correo electrónico a continuación en el campo de entrada."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "La entrada en el campo de entrada no es idéntica a la dirección de correo electrónico proporcionada anteriormente."
      }
    }
  },
  "pl-PL": {
    "pleaseConfirm": {
      "label": "Aby potwierdzić, wprowadź adres e-mail poniżej w polu wejściowym."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Wpis w polu wprowadzania nie jest identyczny z adresem e-mail podanym powyżej."
      }
    }
  },
  "nl-NL": {
    "pleaseConfirm": {
      "label": "Om te bevestigen vult u het onderstaande e-mailadres in het invoerveld in."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "De invoer in het invoerveld is niet identiek aan het hierboven vermelde e-mailadres."
      }
    }
  },
  "bg-BG": {
    "pleaseConfirm": {
      "label": "За да потвърдите, въведете имейл адреса по-долу в полето за въвеждане."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Записът в полето за въвеждане не е идентичен с посочения по-горе имейл адрес."
      }
    }
  },
  "bs-BA": {
    "pleaseConfirm": {
      "label": "Za potvrdu unesite adresu e-pošte ispod u polje za unos."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Unos u polju za unos nije identičan gore navedenoj adresi e-pošte."
      }
    }
  },
  "zh-CN": {
    "pleaseConfirm": {
      "label": "要确认，请在输入字段中输入下面的电子邮件地址。"
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "输入字段中的条目与上面给出的电子邮件地址不同。"
      }
    }
  },
  "da-DK": {
    "pleaseConfirm": {
      "label": "For at bekræfte skal du indtaste e-mailadressen nedenfor i indtastningsfeltet."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Indtastningen i indtastningsfeltet er ikke identisk med den ovenfor angivne e-mailadresse."
      }
    }
  },
  "et-EE": {
    "pleaseConfirm": {
      "label": "Kinnitamiseks sisestage allolevale sisestusväljale e-posti aadress."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Sisestusvälja kirje ei ole identne ülaltoodud e-posti aadressiga."
      }
    }
  },
  "fi-FI": {
    "pleaseConfirm": {
      "label": "Vahvista kirjoittamalla alla oleva sähköpostiosoite syöttökenttään."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Syöttökentässä oleva merkintä ei ole identtinen yllä olevan sähköpostiosoitteen kanssa."
      }
    }
  },
  "el-GR": {
    "pleaseConfirm": {
      "label": "Για επιβεβαίωση, εισαγάγετε τη διεύθυνση email παρακάτω στο πεδίο εισαγωγής."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Η καταχώρηση στο πεδίο εισαγωγής δεν είναι πανομοιότυπη με τη διεύθυνση email που δίνεται παραπάνω."
      }
    }
  },
  "he-IL": {
    "pleaseConfirm": {
      "label": "כדי לאשר, הזן את כתובת הדוא＂ל למטה בשדה הקלט."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "הערך בשדה הקלט אינו זהה לכתובת המייל שצוינה לעיל."
      }
    }
  },
  "ga-IE": {
    "pleaseConfirm": {
      "label": "Chun a dhearbhú, iontráil an seoladh ríomhphoist thíos sa réimse ionchuir."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Níl an iontráil sa réimse ionchuir comhionann leis an seoladh ríomhphoist a thugtar thuas."
      }
    }
  },
  "ja-JP": {
    "pleaseConfirm": {
      "label": "確認するには、以下のメールアドレスを入力フィールドに入力してください。"
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "入力フィールドの入力は、上記の電子メールアドレスと同じではありません。"
      }
    }
  },
  "hr-HR": {
    "pleaseConfirm": {
      "label": "Za potvrdu unesite adresu e-pošte ispod u polje za unos."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Unos u polju za unos nije identičan gore navedenoj adresi e-pošte."
      }
    }
  },
  "lv-LV": {
    "pleaseConfirm": {
      "label": "Lai apstiprinātu, ievadiet tālāk norādīto e-pasta adresi ievades laukā."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Ievades laukā esošais ieraksts nav identisks iepriekš norādītajai e-pasta adresei."
      }
    }
  },
  "lt-LT": {
    "pleaseConfirm": {
      "label": "Norėdami patvirtinti, toliau esančiame laukelyje įveskite el. pašto adresą."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Įvesties lauke esantis įrašas nėra identiškas aukščiau pateiktam el. pašto adresui."
      }
    }
  },
  "pt-PT": {
    "pleaseConfirm": {
      "label": "Para confirmar, insira o endereço de e-mail abaixo no campo de entrada."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "A entrada no campo de entrada não é idêntica ao endereço de e-mail fornecido acima."
      }
    }
  },
  "ro-RO": {
    "pleaseConfirm": {
      "label": "Pentru a confirma, introduceți adresa de e-mail de mai jos în câmpul de introducere."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Intrarea în câmpul de introducere nu este identică cu adresa de e-mail dată mai sus."
      }
    }
  },
  "ru-RU": {
    "pleaseConfirm": {
      "label": "Для подтверждения введите адрес электронной почты ниже в поле ввода."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Запись в поле ввода не идентична указанному выше адресу электронной почты."
      }
    }
  },
  "sv-SE": {
    "pleaseConfirm": {
      "label": "För att bekräfta, ange e-postadressen nedan i inmatningsfältet."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Inmatningen i inmatningsfältet är inte identisk med e-postadressen ovan."
      }
    }
  },
  "sk-SK": {
    "pleaseConfirm": {
      "label": "Pre potvrdenie zadajte e-mailovú adresu nižšie do vstupného poľa."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Záznam vo vstupnom poli nie je identický s e-mailovou adresou uvedenou vyššie."
      }
    }
  },
  "cs-CZ": {
    "pleaseConfirm": {
      "label": "Pro potvrzení zadejte e-mailovou adresu níže do vstupního pole."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Záznam ve vstupním poli není shodný s výše uvedenou e-mailovou adresou."
      }
    }
  },
  "tr-TR": {
    "pleaseConfirm": {
      "label": "Onaylamak için aşağıdaki giriş alanına e-posta adresini girin."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Giriş alanındaki giriş, yukarıda verilen e-posta adresiyle aynı değil."
      }
    }
  },
  "hu-HU": {
    "pleaseConfirm": {
      "label": "A megerősítéshez írja be az alábbi e-mail címet a beviteli mezőbe."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "A beviteli mezőben szereplő bejegyzés nem egyezik meg a fent megadott e-mail címmel."
      }
    }
  },
  "ar-AE": {
    "pleaseConfirm": {
      "label": "للتأكيد ، أدخل عنوان البريد الإلكتروني أدناه في حقل الإدخال."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "الإدخال في حقل الإدخال غير مطابق لعنوان البريد الإلكتروني الوارد أعلاه."
      }
    }
  },
  "fr-BE": {
    "pleaseConfirm": {
      "label": "Pour confirmer, entrez l'adresse e-mail ci-dessous dans le champ de saisie."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "L'entrée dans le champ de saisie n'est pas identique à l'adresse e-mail indiquée ci-dessus."
      }
    }
  },
  "fr-LU": {
    "pleaseConfirm": {
      "label": "Pour confirmer, entrez l'adresse e-mail ci-dessous dans le champ de saisie."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "L'entrée dans le champ de saisie n'est pas identique à l'adresse e-mail indiquée ci-dessus."
      }
    }
  },
  "mt-MT": {
    "pleaseConfirm": {
      "label": "Biex tikkonferma, daħħal l-indirizz tal-email hawn taħt fil-qasam tal-input."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Id-dħul fil-qasam tad-dħul mhuwiex identiku għall-indirizz elettroniku mogħti hawn fuq."
      }
    }
  },
  "sl-SI": {
    "pleaseConfirm": {
      "label": "Za potrditev vnesite spodnji e-poštni naslov v vnosno polje."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Vnos v vnosno polje ni enak zgoraj navedenemu e-poštnemu naslovu."
      }
    }
  },
  "el-CY": {
    "pleaseConfirm": {
      "label": "Για επιβεβαίωση, εισαγάγετε τη διεύθυνση email παρακάτω στο πεδίο εισαγωγής."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Η καταχώρηση στο πεδίο εισαγωγής δεν είναι πανομοιότυπη με τη διεύθυνση email που δίνεται παραπάνω."
      }
    }
  },
  "tr-CY": {
    "pleaseConfirm": {
      "label": "Onaylamak için aşağıdaki giriş alanına e-posta adresini girin."
    },
    "inputValidation": {
      "incorrectPhrase": {
        "message": "Giriş alanındaki giriş, yukarıda verilen e-posta adresiyle aynı değil."
      }
    }
  }
}
</i18n>
