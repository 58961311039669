import dayjs from "dayjs";
import 'dayjs/locale/de';
import 'dayjs/locale/de-at';
import 'dayjs/locale/de-ch';
import 'dayjs/locale/en';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/it';
import 'dayjs/locale/fr';
import 'dayjs/locale/fr';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/pl';
import 'dayjs/locale/nl';
import 'dayjs/locale/bg';
import 'dayjs/locale/bs';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/da';
import 'dayjs/locale/et';
import 'dayjs/locale/fi';
import 'dayjs/locale/el';
import 'dayjs/locale/el';
import 'dayjs/locale/he';
import 'dayjs/locale/ga';
import 'dayjs/locale/ja';
import 'dayjs/locale/hr';
import 'dayjs/locale/lv';
import 'dayjs/locale/lt';
import 'dayjs/locale/mt';
import 'dayjs/locale/pt';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import 'dayjs/locale/sl';
import 'dayjs/locale/sv';
import 'dayjs/locale/sk';
import 'dayjs/locale/cs';
import 'dayjs/locale/tr';
import 'dayjs/locale/tr';
import 'dayjs/locale/hu';
import 'dayjs/locale/ar';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
const LocalizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(LocalizedFormat);
export default dayjs;
